import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import Copyright from './Componentes/Copyright/Copyright';
import MenuSuperior from './Componentes/MenuSuperior/MenuSuperior';
import Auditoria from './Componentes/Paginas/Admin/Auditoria/Auditoria';
import Configuracoes from './Componentes/Paginas/Admin/Configuracoes/Configuracoes';
import Equipamentos from './Componentes/Paginas/Admin/Equipamentos/Equipamentos';
import EquipamentosAtivos from './Componentes/Paginas/Admin/EquipamentosAtivos/EquipamentosAtivos';
import Historico from './Componentes/Paginas/Admin/Historico/Historico';
import Inicial from './Componentes/Paginas/Admin/Inicial/Inicial';
import Perguntas from './Componentes/Paginas/Admin/Perguntas/Perguntas';
import Setores from './Componentes/Paginas/Admin/Setores/Setores';
import Usuarios from './Componentes/Paginas/Admin/Usuarios/Usuarios';
import Clientes from './Componentes/Paginas/Clientes/Clientes';
import PosVendaAtendimento from './Componentes/Paginas/PosVenda/PosVendaAtendimento';
import PosVendaProducao from './Componentes/Paginas/PosVenda/PosVendaProducao';
import Setor from './Componentes/Paginas/Setor/Setor';
import InicialSetorTercerizado from './Componentes/Paginas/SetorTerceirizado/Inicial';
import SetorTercerizado from './Componentes/Paginas/SetorTerceirizado/SetorTerceirizado';
import SetorSkeleton from './Componentes/Skeleton/SetorSkeleton';
import './global.css';
import { useAuth } from './Hooks/Auth';
import { useSetores } from './Hooks/Sistema/Setores';
import { retornarCookie } from './Uteis/Funcoes';
import { paramCookieMaster } from './Uteis/Parametros';
import { socket } from './Uteis/Socket';

/**
 * @param {number} categoria
 * @return {Node}
 */
function PaginaInicialPorCategoriaDoSetor({ categoria }) {
  if (categoria === 1) {
    return <PosVendaProducao />;
  } else if (categoria === 2) {
    return <Inicial />;
  } else if (categoria === 3) {
    return <InicialSetorTercerizado />;
  } else if (categoria === 4) {
    return <Auditoria />
  }
}

PaginaInicialPorCategoriaDoSetor.propTypes = {
  categoria: PropTypes.number.isRequired,
};

/**
 * Componente para página inicial.
 * @return {void} retorna a página de acordo com a autenticação.
 */
function PaginaRouter() {
  const [usuario] = useAuth();
  const [setores] = useSetores();

  useEffect(() => {
    socket.connect();

    return () => socket.disconnect();
  }, []);

  return (
    <Routes>
      <Route
        index
        element={
          JSON.parse(usuario.setor)[0] === 1 ? (
            <Inicial />
          ) : (
            <PaginaInicialPorCategoriaDoSetor
              categoria={usuario.setorObjeto[0].categoria}
            />
          )
        }
      />
      {JSON.parse(usuario.setor)[0] !== 1 &&
        usuario.setorObjeto[0].categoria !== 1 && (
          <Route
            path='setor/:setorLink'
            element={setores ? <Setor /> : <SetorSkeleton />}
          />
        )}
      {JSON.parse(usuario.setor)[0] !== 1 &&
        usuario.setorObjeto[0].categoria === 1 && (
          <>
            <Route path='pos-venda' element={<Outlet />}>
              <Route path='producao' element={<PosVendaProducao />} />
              <Route path='atendimentos' element={<PosVendaAtendimento />} />
            </Route>
            <Route path='perguntas' element={<Perguntas />} />
          </>
        )}
      {JSON.parse(usuario.setor)[0] === 1 && (
        <>
          <Route
            path='setor/:setorLink'
            element={setores ? <Setor /> : <SetorSkeleton />}
          />
          <Route
            path='tercerizado/:setorLink'
            element={setores ? <SetorTercerizado /> : <SetorSkeleton />}
          />
          <Route path='pos-venda' element={<Outlet />}>
            <Route path='producao' element={<PosVendaProducao />} />
            <Route path='atendimentos' element={<PosVendaAtendimento />} />
          </Route>
          <Route path='equipamentos' element={<Equipamentos />} />
          <Route path='equipamentos-ativos' element={<EquipamentosAtivos />} />
          <Route path='perguntas' element={<Perguntas />} />
          <Route path='setores' element={<Setores />} />
          <Route path='usuarios' element={<Usuarios />} />
          <Route path='clientes' element={<Clientes />} />
          <Route path='historico' element={<Historico />} />
          <Route path='configuracoes' element={<Configuracoes />} />
          <Route path='auditoria' element={<Auditoria />} />
        </>
      )}
    </Routes>
  );
}

/**
 * Componente para página inicial.
 * @return {void} retorna a página de acordo com a autenticação.
 */
export default function App() {
  const [, , , setMaster] = useAuth();

  useEffect(() => {
    if (Boolean(retornarCookie(paramCookieMaster))) setMaster(true);
  }, []);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <MenuSuperior />
      <Box
        component='main'
        sx={{
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
          background: '#F2F2F7',
        }}
        id='qt-main'
      >
        <Toolbar />
        <Container maxWidth='xl' sx={{ mb: 4 }} className='qt-container'>
          <PaginaRouter />
          <Copyright sx={{ pt: 4 }} />
        </Container>
      </Box>
    </Box>
  );
}

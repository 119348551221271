/* eslint-disable no-unused-vars */
import CloseIcon from '@mui/icons-material/Close';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@mui/lab';
import {
  Accordion,
  AccordionSummary,
  Backdrop,
  Button,
  CircularProgress,
  DialogActions,
  DialogTitle,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  Link,
  Paper,
  Slide,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import CaixaSelecao from '../../../Componentes/Campo/CaixaSelecao';
import MultiplaEscolha from '../../../Componentes/Campo/MultiplaEscolha';
import Texto from '../../../Componentes/Campo/Texto';
import { formatarDataHora } from '../../../Uteis/Funcoes';
import { useApi } from '../../Api';
import { useFuncoes } from '../../FuncoesGerais';
import { useClientes } from '../../Sistema/Clientes';
import { useEquipamentos } from '../../Sistema/Equipamentos';
import { useMovimentacoes } from '../../Sistema/MovimentacoesChamado';
import { usePerguntas } from '../../Sistema/Perguntas';
import { useSetores } from '../../Sistema/Setores';
import { useUsuarios } from '../../Usuarios';
import { VisualizarCliente } from './VisualizarCliente';
import { paramApiUrl } from '../../../Uteis/Parametros';

export const Ctx = createContext();

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

/**
 * @param {object} props
 * @return {object}
 */
function ElementoPergunta(props) {
  // eslint-disable-next-line react/prop-types
  const { tipo } = props;

  switch (tipo) {
    case 1:
      return <MultiplaEscolha {...props} />;
    case 2:
      return <CaixaSelecao {...props} />;
    case 3:
      return <Texto {...props} />;
  }
}

/**
 * @param {object} dados
 * @return {object}
 */
function DialogoPerguntas({ dados, fechar }) {
  const [perguntas] = usePerguntas();

  return (
    <Dialog open={dados != null} onClose={fechar} fullWidth maxWidth='sm'>
      <DialogTitle id='scroll-dialog-title'>Questionário</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {perguntas &&
            dados &&
            perguntas
              .sort((a, b) => a.ordem - b.ordem)
              .map((props, index) => {
                // eslint-disable-next-line react/prop-types
                if (
                  // eslint-disable-next-line react/prop-types
                  JSON.parse(dados).filter((item) => item.pergunta == props.id)
                    .length <= 0
                ) {
                  return;
                } else {
                  return (
                    <Grid
                      className='qt-questionario-item'
                      key={`item-map-pergunta-${index}`}
                      item
                      xs={12}
                      sm={12}
                    >
                      <ElementoPergunta
                        {...props}
                        respostas={JSON.parse(dados)}
                      />
                    </Grid>
                  );
                }
              })}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={fechar}>Fechar</Button>
      </DialogActions>
    </Dialog>
  );
}

DialogoPerguntas.propTypes = {
  dados: PropTypes.string,
  fechar: PropTypes.func.isRequired,
};

/**
 * Hooks manager
 * @param {any} children
 * @return {children} Hooks.
 */
export function MovimentacoesProvider({ children }) {
  const [alvo, setMovimentacaoAlvo] = useState();
  const [clientes] = useClientes();
  const [equipamentos] = useEquipamentos();
  const [setores] = useSetores();
  const [movimentacoes, setMovimentacoes] = useMovimentacoes();
  const [, equipamentosFetch, , , , , usuariosFetch, perguntasFetch] =
    useFuncoes();
  const [questionario, setQuestionario] = useState();
  const ApiController = useApi();
  const api = new ApiController();
  const [vCliente, setVCliente] = useState();
  const [usuarios] = useUsuarios();

  /**
   * @param {object} c
   */
  function handleVisualizarCliente(c) {
    setVCliente(c);
  }

  /**
   */
  function handleFechar() {
    setMovimentacaoAlvo();
  }

  /**
   * @param {string} dados
   */
  function handleQuestionario(dados) {
    setQuestionario(dados);
  }

  /**
   * @param {number} ms
   * @return {string}
   */
  function converterMilissegundos(ms) {
    let segundos = Math.floor(ms / 1000);
    let minutos = Math.floor(segundos / 60);
    let horas = Math.floor(minutos / 60);
    const dias = Math.floor(horas / 24);

    horas = horas % 24;
    minutos = minutos % 60;
    segundos = segundos % 60;

    const diasAtrasado =
      dias === 0 ? '' : dias === 1 ? `${dias} dia ` : `${dias} dias `;
    const horasAtrasado =
      horas === 0 ? '' : horas === 1 ? `${horas} hora ` : `${horas} horas `;
    const minutosAtrasado =
      minutos === 0
        ? ''
        : minutos === 1
          ? `${minutos} minuto `
          : `${minutos} minutos `;
    const segundosAtrasado =
      segundos === 0
        ? ''
        : segundos === 1
          ? `${segundos} segundo `
          : `${segundos} segundos `;

    return `${diasAtrasado}${horasAtrasado}${minutosAtrasado}${segundosAtrasado}`;
  }

  /**
   */
  function movimentacoesFetch() {
    api
      .post('chamados/movimentacoes', { id: alvo.id })
      .then((r) => {
        const prevMovi = structuredClone(movimentacoes);

        prevMovi[alvo.id] = r;

        setMovimentacoes(prevMovi);
      })
      .catch((e) => {
        setMovimentacaoAlvo();
        console.error(e);
      });
  }

  useEffect(() => {
    if (alvo) {
      movimentacoesFetch();
    }
    equipamentosFetch();
  }, [alvo]);

  useEffect(() => {
    perguntasFetch();
    usuariosFetch();
  }, []);

  return (
    <Ctx.Provider value={[setMovimentacaoAlvo]}>
      {alvo &&
        clientes &&
        usuarios &&
        equipamentos &&
        setores &&
        (movimentacoes[alvo.id] ? (
          <>
            {vCliente && (
              <VisualizarCliente
                vCliente={vCliente}
                handleFechar={() => {
                  setVCliente();
                }}
              />
            )}
            <DialogoPerguntas
              dados={questionario}
              fechar={() => {
                handleQuestionario();
              }}
            />
            <Dialog
              open={alvo !== undefined}
              onClose={handleFechar}
              aria-labelledby='alert-dialog-title'
              aria-describedby='alert-dialog-description'
              fullScreen
              TransitionComponent={Transition}
            >
              <Toolbar>
                <Typography
                  sx={{ ml: 2, flex: 1 }}
                  variant='h6'
                  component='div'
                >
                  {alvo?.pedido?.pedido
                    ? `Histórico do pedido ${alvo.pedido.pedido}`
                    : `Histórico do chamado ${alvo.id}`}
                </Typography>
                <IconButton
                  edge='start'
                  color='inherit'
                  onClick={handleFechar}
                  aria-label='fechar'
                >
                  <CloseIcon />
                </IconButton>
              </Toolbar>
              <DialogContent dividers>
                <Stack direction={'row'} width={'100%'} gap={2}>
                  <Stack width={'100%'}>
                    <Accordion expanded={false}>
                      <AccordionSummary aria-controls='panel1bh-content'>
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                          Cliente
                        </Typography>
                        {clientes.find((item) => item.id == alvo.cliente) ? (
                          <Link
                            fontWeight={500}
                            onClick={() => {
                              handleVisualizarCliente(
                                clientes.find((item) => item.id == alvo.cliente)
                              );
                            }}
                          >
                            {
                              clientes.find((item) => item.id == alvo.cliente)
                                .nomeFantasia
                            }
                          </Link>
                        ) : (
                          <Typography
                            sx={{ color: 'text.secondary' }}
                            fontWeight={500}
                          >
                            Não encontrado
                          </Typography>
                        )}
                      </AccordionSummary>
                    </Accordion>
                    {alvo?.pedido && (
                      <Accordion expanded={false}>
                        <AccordionSummary aria-controls='panel1bh-content'>
                          <Typography sx={{ width: '33%', flexShrink: 0 }}>
                            Equipamento
                          </Typography>
                          <Typography
                            sx={{ color: 'text.secondary' }}
                            fontWeight={500}
                          >
                            {
                              equipamentos.find(
                                (item) => item.id == alvo.pedido.equipamento
                              ).titulo
                            }
                          </Typography>
                        </AccordionSummary>
                      </Accordion>
                    )}
                    <Accordion expanded={false}>
                      <AccordionSummary aria-controls='panel1bh-content'>
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                          Data de cadastro
                        </Typography>
                        <Typography
                          sx={{ color: 'text.secondary' }}
                          fontWeight={500}
                        >
                          {formatarDataHora(alvo.criadoEm)}
                        </Typography>
                      </AccordionSummary>
                    </Accordion>
                  </Stack>
                  <Stack width={'100%'}>
                    <Accordion expanded={false}>
                      <AccordionSummary aria-controls='panel1bh-content'>
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                          Tempo de produção
                        </Typography>
                        <Typography
                          sx={{ color: 'text.secondary' }}
                          fontWeight={500}
                        >
                          {converterMilissegundos(
                            movimentacoes[alvo.id]
                              .filter(
                                (item) =>
                                  setores.find(
                                    (pItem) => pItem.id == item?.setor
                                  )?.categoria == 2
                              )
                              .reduce((soma, item) => soma + item.duracao, 0)
                          )}
                        </Typography>
                      </AccordionSummary>
                    </Accordion>
                    {alvo.posVenda && (
                      <Accordion expanded={false}>
                        <AccordionSummary aria-controls='panel1bh-content'>
                          <Typography sx={{ width: '33%', flexShrink: 0 }}>
                            Tempo de pós venda
                          </Typography>
                          <Typography
                            sx={{ color: 'text.secondary' }}
                            fontWeight={500}
                          >
                            {converterMilissegundos(
                              movimentacoes[alvo.id]
                                .filter(
                                  (item) =>
                                    setores.find(
                                      (pItem) => pItem.id == item?.setor
                                    )?.categoria != 2
                                )
                                .reduce((soma, item) => soma + item.duracao, 0)
                            )}
                          </Typography>
                        </AccordionSummary>
                      </Accordion>
                    )}
                    {movimentacoes[alvo.id].reduce(
                      (soma, item) =>
                        soma + Number(JSON.parse(item.atraso)?.duracao ?? 0),
                      null
                    ) > 0 && (
                      <Accordion expanded={false}>
                        <AccordionSummary aria-controls='panel1bh-content'>
                          <Typography sx={{ width: '33%', flexShrink: 0 }}>
                            Tempo de atraso
                          </Typography>
                          <Typography
                            sx={{ color: 'text.secondary' }}
                            fontWeight={500}
                          >
                            {converterMilissegundos(
                              movimentacoes[alvo.id].reduce(
                                (soma, item) =>
                                  soma +
                                  Number(JSON.parse(item.atraso)?.duracao ?? 0),
                                null
                              )
                            )}
                          </Typography>
                        </AccordionSummary>
                      </Accordion>
                    )}
                    {movimentacoes[alvo.id].reduce(
                      (maxIndex, item, index, array) => {
                        return Number(JSON.parse(item.atraso)?.duracao ?? 0) >
                          array[maxIndex]?.duracao
                          ? index
                          : maxIndex;
                      },
                      0
                    ) > 0 && (
                      <Accordion expanded={false}>
                        <AccordionSummary aria-controls='panel1bh-content'>
                          <Typography sx={{ width: '33%', flexShrink: 0 }}>
                            Setor com maior atraso
                          </Typography>
                          <Typography
                            sx={{ color: 'text.secondary' }}
                            fontWeight={500}
                          >
                            {
                              setores[
                                movimentacoes[alvo.id].reduce(
                                  (maxIndex, item, index, array) => {
                                    return Number(
                                      JSON.parse(item.atraso)?.duracao ?? 0
                                    ) > array[maxIndex].duracao
                                      ? index
                                      : maxIndex;
                                  },
                                  0
                                )
                              ].nome
                            }
                          </Typography>
                        </AccordionSummary>
                      </Accordion>
                    )}
                  </Stack>
                </Stack>
                <Timeline>
                  {movimentacoes[alvo.id].map(
                    (
                      {
                        setor,
                        usuario,
                        atividade,
                        duracao,
                        observacao,
                        atraso,
                        anexos,
                        dados,
                        criadoEm,
                      },
                      index
                    ) => (
                      <TimelineItem key={`item-map-movimentacao-${index}`}>
                        <TimelineOppositeContent
                          color='text.secondary'
                          fontSize={12}
                          width={100}
                        >
                          {formatarDataHora(criadoEm)}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          {(atraso || atividade == 4) && (
                            <TimelineDot color={'error'} />
                          )}
                          {(atividade == 1 ||
                            atividade == 3 ||
                            atividade == 6 ||
                            atividade == 9) && (
                            <TimelineDot color={'success'} />
                          )}
                          {atividade == 2 && !atraso && <TimelineDot />}
                          {atividade == 6 ||
                            (atividade == 8 && (
                              <TimelineDot
                                color={
                                  JSON.parse(dados)?.atrasado === 2
                                    ? 'error'
                                    : 'grey'
                                }
                              />
                            ))}
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2, pt: 0 }}>
                          {atividade === 1 && (
                            <>
                              <Typography variant='h6' component='span'>
                                Venda
                              </Typography>
                              {usuario && (
                                <Typography
                                  variant='subtitle'
                                  component='span'
                                  ml={1}
                                >
                                  {
                                    usuarios.find((item) => item.id == usuario)
                                      .nome
                                  }
                                </Typography>
                              )}
                              {observacao && (
                                <Paper
                                  elevation={0}
                                  variant='outlined'
                                  sx={{
                                    minWidth: '50%',
                                    width: 'fit-content',
                                    mb: 1,
                                  }}
                                >
                                  <List
                                    sx={{ p: 1, pb: 0 }}
                                    subheader={
                                      <ListSubheader
                                        disableSticky
                                        component='div'
                                        sx={{
                                          p: 0,
                                          lineHeight: '24px',
                                          pb: 0.5,
                                        }}
                                      >
                                        Anotações sobre o processo
                                      </ListSubheader>
                                    }
                                  >
                                    <ListItem sx={{ pl: 0, pt: 0 }}>
                                      <ListItemText
                                        gutterBottom
                                        sx={{
                                          whiteSpace: 'break-spaces',
                                          margin: 0,
                                          p: 0,
                                        }}
                                      >
                                        {observacao}
                                      </ListItemText>
                                    </ListItem>
                                  </List>
                                </Paper>
                              )}
                            </>
                          )}
                          {atividade === 2 && (
                            <>
                              <Typography
                                variant='h6'
                                component='span'
                                sx={{ mb: 1 }}
                              >
                                {setores?.find((item) => item.id == setor).nome}
                              </Typography>
                              {usuario && (
                                <Typography
                                  variant='subtitle'
                                  component='span'
                                  ml={1}
                                >
                                  {
                                    usuarios.find((item) => item.id == usuario)
                                      .nome
                                  }
                                </Typography>
                              )}
                              {duracao && (
                                <Paper
                                  elevation={0}
                                  variant='outlined'
                                  sx={{
                                    minWidth: '50%',
                                    width: 'fit-content',
                                    mb: 1,
                                  }}
                                >
                                  <List
                                    sx={{ p: 1, pb: 0 }}
                                    subheader={
                                      <ListSubheader
                                        disableSticky
                                        component='div'
                                        sx={{
                                          p: 0,
                                          lineHeight: '24px',
                                          pb: 0.5,
                                        }}
                                      >
                                        Tempo no setor
                                      </ListSubheader>
                                    }
                                  >
                                    <ListItem sx={{ pl: 0, pt: 0 }}>
                                      <ListItemText
                                        gutterBottom
                                        sx={{
                                          whiteSpace: 'break-spaces',
                                          margin: 0,
                                          p: 0,
                                        }}
                                      >
                                        {converterMilissegundos(
                                          Number(duracao)
                                        )}
                                      </ListItemText>
                                    </ListItem>
                                  </List>
                                  {observacao && (
                                    <>
                                      <Divider component={'li'} />
                                      <List
                                        sx={{ p: 1, pb: 0 }}
                                        subheader={
                                          <ListSubheader
                                            disableSticky
                                            component='div'
                                            sx={{
                                              p: 0,
                                              lineHeight: '24px',
                                              pb: 0.5,
                                            }}
                                          >
                                            Anotações sobre o processo
                                          </ListSubheader>
                                        }
                                      >
                                        <ListItem sx={{ pl: 0, pt: 0 }}>
                                          <ListItemText
                                            gutterBottom
                                            sx={{
                                              whiteSpace: 'break-spaces',
                                              margin: 0,
                                              p: 0,
                                            }}
                                          >
                                            {observacao}
                                          </ListItemText>
                                        </ListItem>
                                      </List>
                                    </>
                                  )}
                                  {anexos && (
                                    <>
                                      <Divider component={'li'} />
                                      <List
                                        sx={{ p: 1, pb: 0 }}
                                        subheader={
                                          <ListSubheader
                                            disableSticky
                                            component='div'
                                            sx={{
                                              p: 0,
                                              lineHeight: '24px',
                                              pb: 0.5,
                                            }}
                                          >
                                            Anexos
                                          </ListSubheader>
                                        }
                                      >
                                        <ListItem sx={{ pl: 0, pt: 0 }}>
                                          <ImageList
                                            cols={2}
                                            sx={{ width: 290, margin: 'auto' }}
                                            rowHeight={143}
                                          >
                                            {JSON.parse(anexos).map(
                                              (item, index) => (
                                                <a
                                                  key={`item-imagem-${setor}-${index}`}
                                                  href={`${
                                                    process.env.NODE_ENV ===
                                                    'development'
                                                      ? 'http://localhost:3333'
                                                      : paramApiUrl
                                                  }/sistema/anexo/${item}`}
                                                  target='_blank'
                                                  rel='noreferrer'
                                                >
                                                  <ImageListItem>
                                                    <img
                                                      src={`${
                                                        process.env.NODE_ENV ===
                                                        'development'
                                                          ? 'http://localhost:3333'
                                                          : paramApiUrl
                                                      }/sistema/anexo/${item}`}
                                                      loading='lazy'
                                                    />
                                                  </ImageListItem>
                                                </a>
                                              )
                                            )}
                                          </ImageList>
                                        </ListItem>
                                      </List>
                                    </>
                                  )}
                                </Paper>
                              )}
                              {atraso && (
                                <Paper
                                  elevation={0}
                                  variant='outlined'
                                  sx={{
                                    minWidth: '50%',
                                    width: 'fit-content',
                                    mb: 1,
                                  }}
                                >
                                  {JSON.parse(atraso).duracao && (
                                    <List
                                      sx={{ p: 1, pb: 0 }}
                                      subheader={
                                        <ListSubheader
                                          disableSticky
                                          component='div'
                                          sx={{
                                            p: 0,
                                            lineHeight: '24px',
                                            pb: 0.5,
                                          }}
                                        >
                                          Tempo de atraso
                                        </ListSubheader>
                                      }
                                    >
                                      <ListItem sx={{ pl: 0, pt: 0 }}>
                                        <ListItemText
                                          gutterBottom
                                          sx={{
                                            whiteSpace: 'break-spaces',
                                            margin: 0,
                                            p: 0,
                                          }}
                                        >
                                          {converterMilissegundos(
                                            Number(JSON.parse(atraso).duracao)
                                          )}
                                        </ListItemText>
                                      </ListItem>
                                    </List>
                                  )}
                                  <Divider component={'li'} />
                                  <List
                                    sx={{ p: 1, pb: 0 }}
                                    subheader={
                                      <ListSubheader
                                        disableSticky
                                        component='div'
                                        sx={{
                                          p: 0,
                                          lineHeight: '24px',
                                          pb: 0.5,
                                        }}
                                      >
                                        Motivo
                                      </ListSubheader>
                                    }
                                  >
                                    <ListItem sx={{ pl: 0, pt: 0 }}>
                                      <ListItemText
                                        gutterBottom
                                        sx={{
                                          whiteSpace: 'break-spaces',
                                          margin: 0,
                                          p: 0,
                                        }}
                                      >
                                        {JSON.parse(atraso).motivo}
                                      </ListItemText>
                                    </ListItem>
                                  </List>
                                </Paper>
                              )}
                            </>
                          )}
                          {atividade === 3 && (
                            <>
                              <Typography variant='h6' component='span'>
                                Produção finalizada
                              </Typography>
                            </>
                          )}
                          {atividade === 4 && (
                            <>
                              <Typography variant='h6' component='span'>
                                Excluido
                              </Typography>
                              {usuario && (
                                <Typography
                                  variant='subtitle'
                                  component='span'
                                  ml={1}
                                >
                                  {
                                    usuarios.find((item) => item.id == usuario)
                                      .nome
                                  }
                                </Typography>
                              )}
                              <Paper
                                elevation={0}
                                variant='outlined'
                                sx={{
                                  minWidth: '50%',
                                  width: 'fit-content',
                                  mb: 1,
                                }}
                              >
                                <List
                                  sx={{ p: 1, pb: 0 }}
                                  subheader={
                                    <ListSubheader
                                      disableSticky
                                      component='div'
                                      sx={{ p: 0, lineHeight: '24px', pb: 0.5 }}
                                    >
                                      Motivo
                                    </ListSubheader>
                                  }
                                >
                                  <ListItem sx={{ pl: 0, pt: 0 }}>
                                    <ListItemText
                                      gutterBottom
                                      sx={{
                                        whiteSpace: 'break-spaces',
                                        margin: 0,
                                        p: 0,
                                      }}
                                    >
                                      {observacao}
                                    </ListItemText>
                                  </ListItem>
                                </List>
                              </Paper>
                            </>
                          )}
                          {atividade === 6 && (
                            <>
                              <Typography
                                variant='h6'
                                component='span'
                                sx={{ mb: 1 }}
                              >
                                Novo Pós Venda
                              </Typography>
                              {dados && (
                                <Paper
                                  elevation={0}
                                  variant='outlined'
                                  sx={{
                                    minWidth: '50%',
                                    width: 'fit-content',
                                    mb: 1,
                                  }}
                                >
                                  <Button
                                    onClick={() => {
                                      handleQuestionario(dados);
                                    }}
                                  >
                                    Ver Questionário
                                  </Button>
                                  {observacao && (
                                    <>
                                      <Divider component={'li'} />
                                      <List
                                        sx={{ p: 1, pb: 0 }}
                                        subheader={
                                          <ListSubheader
                                            disableSticky
                                            component='div'
                                            sx={{
                                              p: 0,
                                              lineHeight: '24px',
                                              pb: 0.5,
                                            }}
                                          >
                                            Anotações sobre o processo
                                          </ListSubheader>
                                        }
                                      >
                                        <ListItem sx={{ pl: 0, pt: 0 }}>
                                          <ListItemText
                                            gutterBottom
                                            sx={{
                                              whiteSpace: 'break-spaces',
                                              margin: 0,
                                              p: 0,
                                            }}
                                          >
                                            {observacao}
                                          </ListItemText>
                                        </ListItem>
                                      </List>
                                    </>
                                  )}
                                </Paper>
                              )}
                            </>
                          )}
                          {atividade === 8 && (
                            <>
                              <Typography
                                variant='h6'
                                component='span'
                                sx={{ mb: 1 }}
                              >
                                {setores?.find((item) => item.id == setor).nome}
                              </Typography>
                              {usuario && (
                                <Typography
                                  variant='subtitle'
                                  component='span'
                                  ml={1}
                                >
                                  {
                                    usuarios.find((item) => item.id == usuario)
                                      .nome
                                  }
                                </Typography>
                              )}
                              {dados &&
                                (JSON.parse(dados).atrasado ? (
                                  JSON.parse(dados).atrasado === 1 ? (
                                    <Paper
                                      elevation={0}
                                      variant='outlined'
                                      sx={{
                                        minWidth: '50%',
                                        width: 'fit-content',
                                        mb: 1,
                                      }}
                                    >
                                      <List
                                        sx={{ p: 1, pb: 0 }}
                                        subheader={
                                          <ListSubheader
                                            disableSticky
                                            component='div'
                                            sx={{
                                              p: 0,
                                              lineHeight: '24px',
                                              pb: 0.5,
                                            }}
                                          >
                                            Primeira tentativa
                                          </ListSubheader>
                                        }
                                      >
                                        <ListItem sx={{ pl: 0, pt: 0 }}>
                                          <ListItemText
                                            gutterBottom
                                            sx={{
                                              whiteSpace: 'break-spaces',
                                              margin: 0,
                                              p: 0,
                                              color: 'red',
                                            }}
                                          >
                                            Não retornou contato
                                          </ListItemText>
                                        </ListItem>
                                      </List>
                                    </Paper>
                                  ) : (
                                    <Paper
                                      elevation={0}
                                      variant='outlined'
                                      sx={{
                                        minWidth: '50%',
                                        width: 'fit-content',
                                        mb: 1,
                                      }}
                                    >
                                      <List
                                        sx={{ p: 1, pb: 0 }}
                                        subheader={
                                          <ListSubheader
                                            disableSticky
                                            component='div'
                                            sx={{
                                              p: 0,
                                              lineHeight: '24px',
                                              pb: 0.5,
                                            }}
                                          >
                                            Primeira tentativa
                                          </ListSubheader>
                                        }
                                      >
                                        <ListItem sx={{ pl: 0, pt: 0 }}>
                                          <ListItemText
                                            gutterBottom
                                            sx={{
                                              whiteSpace: 'break-spaces',
                                              margin: 0,
                                              p: 0,
                                              color: 'red',
                                            }}
                                          >
                                            Não retornou contato
                                          </ListItemText>
                                        </ListItem>
                                      </List>
                                      <Divider component={'li'} />
                                      <List
                                        sx={{ p: 1, pb: 0 }}
                                        subheader={
                                          <ListSubheader
                                            disableSticky
                                            component='div'
                                            sx={{
                                              p: 0,
                                              lineHeight: '24px',
                                              pb: 0.5,
                                            }}
                                          >
                                            Segunda tentativa
                                          </ListSubheader>
                                        }
                                      >
                                        <ListItem sx={{ pl: 0, pt: 0 }}>
                                          <ListItemText
                                            gutterBottom
                                            sx={{
                                              whiteSpace: 'break-spaces',
                                              margin: 0,
                                              p: 0,
                                              color: 'red',
                                            }}
                                          >
                                            Não retornou contato
                                          </ListItemText>
                                        </ListItem>
                                      </List>
                                    </Paper>
                                  )
                                ) : (
                                  <Paper
                                    elevation={0}
                                    variant='outlined'
                                    sx={{
                                      minWidth: '50%',
                                      width: 'fit-content',
                                      mb: 1,
                                    }}
                                  >
                                    <Button
                                      onClick={() => {
                                        handleQuestionario(dados);
                                      }}
                                    >
                                      Ver Questionário
                                    </Button>
                                  </Paper>
                                ))}
                              {duracao && (
                                <Paper
                                  elevation={0}
                                  variant='outlined'
                                  sx={{
                                    minWidth: '50%',
                                    width: 'fit-content',
                                    mb: 1,
                                  }}
                                >
                                  <List
                                    sx={{ p: 1, pb: 0 }}
                                    subheader={
                                      <ListSubheader
                                        disableSticky
                                        component='div'
                                        sx={{
                                          p: 0,
                                          lineHeight: '24px',
                                          pb: 0.5,
                                        }}
                                      >
                                        Tempo no setor
                                      </ListSubheader>
                                    }
                                  >
                                    <ListItem sx={{ pl: 0, pt: 0 }}>
                                      <ListItemText
                                        gutterBottom
                                        sx={{
                                          whiteSpace: 'break-spaces',
                                          margin: 0,
                                          p: 0,
                                        }}
                                      >
                                        {converterMilissegundos(
                                          Number(duracao)
                                        )}
                                      </ListItemText>
                                    </ListItem>
                                  </List>
                                  {observacao && (
                                    <>
                                      <Divider component={'li'} />
                                      <List
                                        sx={{ p: 1, pb: 0 }}
                                        subheader={
                                          <ListSubheader
                                            disableSticky
                                            component='div'
                                            sx={{
                                              p: 0,
                                              lineHeight: '24px',
                                              pb: 0.5,
                                            }}
                                          >
                                            Anotações sobre o processo
                                          </ListSubheader>
                                        }
                                      >
                                        <ListItem sx={{ pl: 0, pt: 0 }}>
                                          <ListItemText
                                            gutterBottom
                                            sx={{
                                              whiteSpace: 'break-spaces',
                                              margin: 0,
                                              p: 0,
                                            }}
                                          >
                                            {observacao}
                                          </ListItemText>
                                        </ListItem>
                                      </List>
                                    </>
                                  )}
                                </Paper>
                              )}
                            </>
                          )}
                          {atividade === 9 && (
                            <>
                              <Typography variant='h6' component='span'>
                                Pós Venda finalizado
                              </Typography>
                            </>
                          )}
                        </TimelineContent>
                      </TimelineItem>
                    )
                  )}
                </Timeline>
              </DialogContent>
            </Dialog>
          </>
        ) : (
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={alvo !== undefined}
          >
            <CircularProgress color='inherit' />
          </Backdrop>
        ))}
      {children}
    </Ctx.Provider>
  );
}

MovimentacoesProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useVisualizarMovimentacoes = () => {
  return useContext(Ctx);
};

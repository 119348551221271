import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

/**
 * @param {string} id
 * @param {string} pergunta
 * @param {string} alternativas
 * @param {string} respostas
 * @return {object}
 */
export function MultiplaEscolha({ id, pergunta, alternativas, respostas }) {
  const respostaItem = respostas?.find(
    (item) => Number(item.pergunta) === Number(id)
  );

  const [resposta, setResposta] = useState(respostaItem?.resposta ?? undefined);

  /**
   * @param {object} e
   */
  function handleResposta(e) {
    setResposta(e.target.value);
  }

  return (
    <FormControl variant='standard'>
      <FormLabel>{pergunta}</FormLabel>
      <input name='perguntas[]' hidden value={id} />
      <input hidden name='respostas[]' value={resposta} />
      <RadioGroup name='' onChange={handleResposta} defaultValue={resposta}>
        {JSON.parse(alternativas).map((item, index) => (
          <FormControlLabel
            key={`item-map-alternativa-${index}`}
            control={<Radio />}
            label={item}
            value={item}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

MultiplaEscolha.propTypes = {
  id: PropTypes.string.isRequired,
  pergunta: PropTypes.string.isRequired,
  alternativas: PropTypes.string,
  respostas: PropTypes.string,
};

export default MultiplaEscolha;

import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { socket } from '../../Uteis/Socket';

export const Ctx = createContext();

/**
 * Hooks manager
 * @param {any} children
 * @return {children} Hooks.
 */
export function ChamadosProvider({ children }) {
  const [chamados, setChamados] = useState();

  /**
   * @param {object} prev
   * @param {object} chamado
   * @return {object}
   */
  function prevHandler(prev, chamado) {
    const indexEncontrado = prev.findIndex((item) => item.id == chamado.id);

    if (indexEncontrado === -1) {
      return [...prev, chamado];
    } else {
      return prev;
    }
  }

  /**
   * @param {object} chamado
   */
  function novoPedidoHandler(chamado) {
    const indexEncontrado = chamados.findIndex((item) => item.id == chamado.id);

    if (indexEncontrado === -1) {
      setChamados((prev) => prevHandler(prev, chamado));
    }
  }

  /**
   * @param {object} chamado
   */
  function aterarPedidoHandler(chamado) {
    const chamadoEncontrado = chamados.findIndex(
      (item) => Number(item.id) === Number(chamado.id)
    );

    if (chamadoEncontrado !== -1) {
      const prevChamados = structuredClone(chamados);

      prevChamados[chamadoEncontrado] = chamado;

      setChamados(prevChamados);
    } else {
      novoPedidoHandler(chamado)
    }
  }

  useEffect(() => {
    socket.on('pedidos/novo', novoPedidoHandler);
    socket.on('pedidos/transferir', aterarPedidoHandler);

    return () => {
      socket.off('pedidos/novo', novoPedidoHandler);
      socket.off('pedidos/transferir', aterarPedidoHandler);
    };
  }, [chamados]);

  return (
    <Ctx.Provider value={[chamados, setChamados]}>{children}</Ctx.Provider>
  );
}

ChamadosProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useChamados = () => {
  return useContext(Ctx);
};

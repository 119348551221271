import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { paramStUsuarios } from '../Uteis/Parametros';
import { useApi } from './Api';
import { useAuth } from './Auth';
import { useClientes } from './Sistema/Clientes';
import { useEquipamentos } from './Sistema/Equipamentos';
import { useSetores } from './Sistema/Setores';
import { useChamados } from './Sistema/Chamados';
import { useConfiguracoes } from './Sistema/Configuracoes';
import { useUsuarios } from './Usuarios';
import { usePerguntas } from './Sistema/Perguntas';

export const Ctx = createContext();

/**
 * Hooks manager
 * @param {any} children
 * @return {children} Hooks.
 */
export function FuncoesProvider({ children }) {
  const [usuario, setUsuario] = useAuth();
  const [equipamentos, setEquipamentos] = useEquipamentos();
  const [setores, setSetores] = useSetores();
  const [clientes, setClientes] = useClientes();
  const [desconectando, setDesconectando] = useState(false);
  const [, setChamados] = useChamados();
  const [configuracoes, setConfiguracoes] = useConfiguracoes();
  const [usuarios, setUsuarios] = useUsuarios();
  const [perguntas, setPerguntas] = usePerguntas();
  const ApiController = useApi();
  const api = new ApiController();
  const navigate = useNavigate();

  /**
   */
  function desconectarUsuario() {
    setDesconectando(true);
    api
      .post('usuario/desconectar')
      .then((r) => {
        setDesconectando(false);
        sessionStorage.removeItem(paramStUsuarios);
        setUsuario(null);
        navigate('/');
      })
      .catch((e) => {
        setDesconectando(false);
      });
  }

  /**
   */
  function equipamentosFetch() {
    if (!equipamentos) {
      if (equipamentos === null) {
        setEquipamentos(undefined);
      }

      api
        .post('equipamentos/lista')
        .then((r) => {
          setEquipamentos(r);
        })
        .catch((e) => {
          setEquipamentos(null);
          console.error(e);
        });
    }
  }

  /**
   */
  function setoresFetch() {
    if (!setores) {
      if (setores === null) {
        setSetores(undefined);
      }

      api
        .post('setores/lista')
        .then((r) => {
          setSetores(r);
        })
        .catch((e) => {
          setSetores(null);
          console.error(e);
        });
    }
  }

  /**
   */
  function clientesFetch() {
    if (!clientes) {
      if (clientes === null) {
        setClientes(undefined);
      }

      api
        .post('clientes/lista')
        .then((r) => {
          setClientes(r);
        })
        .catch((e) => {
          setClientes(null);
          console.error(e);
        });
    }
  }

  /**
   * @param {object} chamado
   * @param {object} prev
   * @return {object}
   */
  function alterarPrevChamados(chamado, prev) {
    const prevChamados = structuredClone(prev);

    const chamadoEncontrado = prevChamados.findIndex(
      (item) => Number(item.id) === Number(chamado.id)
    );

    prevChamados[chamadoEncontrado] = chamado;

    return prevChamados
  }

  /**
   * @param {number} chamado
   */
  function iniciarChamado(chamado) {
    setChamados(prev => alterarPrevChamados(chamado, prev));
  }

  /**
   */
  function configuracoesFetch() {
    if (!configuracoes && JSON.parse(usuario.setor)[0] === 1) {
      if (configuracoes === null) {
        setConfiguracoes(undefined);
      }

      api
        .post('admin/sistema/configuracoes')
        .then((r) => {
          setConfiguracoes(r);
        })
        .catch((e) => {
          setConfiguracoes(null);
          console.error(e);
        });
    }
  }

  /**
   */
  function usuariosFetch() {
    if (!usuarios) {
      if (usuarios === null) {
        setUsuarios(undefined);
      }

      api
        .post('sistema/usuarios')
        .then((r) => {
          setUsuarios(r);
        })
        .catch((e) => {
          setUsuarios(null);
          console.error(e);
        });
    }
  }

  /**
   */
  function perguntasFetch() {
    if (!perguntas) {
      if (perguntas === null) {
        setPerguntas(undefined);
      }

      api
        .post('perguntas/lista')
        .then((r) => {
          setPerguntas(r);
        })
        .catch((e) => {
          setPerguntas(null);
          console.error(e);
        });
    }
  }

  useEffect(() => {
    setoresFetch();
    usuariosFetch();
  }, []);

  return (
    <Ctx.Provider
      value={[
        desconectarUsuario,
        equipamentosFetch,
        setoresFetch,
        clientesFetch,
        iniciarChamado,
        configuracoesFetch,
        usuariosFetch,
        perguntasFetch,
      ]}
    >
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={desconectando}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
      {children}
    </Ctx.Provider>
  );
}

FuncoesProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useFuncoes = () => {
  return useContext(Ctx);
};

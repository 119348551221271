import { LoadingButton } from '@mui/lab';
import {
  Backdrop,
  CircularProgress,
  DialogActions,
  DialogContentText,
  Grid,
  Stack,
  TextField,
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useApi } from '../../../Api';
import { useFuncoes } from '../../../FuncoesGerais';
import { useEquipamentos } from '../../../Sistema/Equipamentos';
import CollectionsIcon from '@mui/icons-material/Collections';

const urlAnotacoes = 'chamados/pedido/anotacoes';
const url = 'chamados/pedido/transferir';
const urlSalvarAnotacao = 'chamados/pedido/salvar-anotacao-atraso';
const titulo = 'Transferir pedido';
const botaoAcaoMensagem = 'Transferir';
const botaoCancelarMensagem = 'Cancelar';

export const Ctx = createContext();

/**
 * Hooks manager
 * @param {any} children
 * @return {children} Hooks.
 */
export function TransferirAtrasadoProvider({ children }) {
  const [alvo, setTransferirAtrasadoAlvo] = useState();
  const [enviando, setEnviando] = useState(false);
  const [, , , , iniciarChamado] = useFuncoes();
  const [equipamentos] = useEquipamentos();
  const [anexos, setAnexos] = useState([]);
  const [carregandoGeral, setCarregandoGeral] = useState(true);
  const [motivo, setMotivo] = useState();
  const [observacao, setObservacao] = useState();

  const motivoInicial = useRef();
  const observacaoInicial = useRef();

  const ApiController = useApi();
  const api = new ApiController();

  /**
   */
  function handleFechar() {
    setTransferirAtrasadoAlvo();
    setMotivo();
    setEnviando(false);
  }

  /**
   * @param {object} e
   */
  function handleObservacao(e) {
    setObservacao(e.target.value);
  }

  /**
   * @param {object} e
   */
  function handleMotivo(e) {
    setMotivo(e.target.value);
  }

  /**
   * @param {object} e
   */
  function handleUpload(e) {
    e.preventDefault();

    setAnexos(Array.from(e.target.files));
  }

  const retornarDuracao = () => {
    const movimentacao = new Date(alvo.pedido.movimentadoEm);

    const agora = new Date();

    const duracao = Math.abs(agora.getTime() - movimentacao.getTime());

    return duracao;
  };

  const retornarTempoDeAtraso = () => {
    if (motivo && motivo != '') {
      const movimentacao = new Date(alvo.pedido.movimentadoEm);

      const equipamento = equipamentos.find(
        (item) => item.id == alvo.pedido.equipamento
      );

      const prazoSetor = JSON.parse(equipamento.prazos).find(
        (item) => item.id == alvo.setor
      ).prazo;

      const entrega = new Date(
        movimentacao.getTime() + prazoSetor * 60 * 60 * 1000
      );

      const dataHoraAtual = new Date();

      const duracao = Math.abs(dataHoraAtual.getTime() - entrega.getTime());

      const atrasoTotal = { duracao, motivo };

      return JSON.stringify(atrasoTotal);
    } else {
      return null;
    }
  };

  /**
   * @param {object} event eventos
   */
  function handleEnviar(event) {
    event.preventDefault();
    setEnviando(true);
    const data = new FormData();

    data.append('duracao', retornarDuracao());
    data.append('atraso', retornarTempoDeAtraso());
    data.append('id', alvo.id);

    anexos.forEach((anexo, index) => {
      data.append(`anexo`, anexo);
    });

    if (observacao) {
      data.append('observacao', observacao);
    }

    api
      .post(url, data)
      .then((r) => {
        iniciarChamado(r);

        toast.success('Pedido transferido com sucesso!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: 'colored',
          transition: Bounce,
        });

        setEnviando(false);
        handleFechar();
      })
      .catch((e) => {
        console.error(e);
        setEnviando(false);
      });
  }

  /**
   */
  function handleAnotacoes() {
    const data = {
      id: alvo.id,
    };

    api
      .post(urlAnotacoes, data)
      .then((r) => {
        setObservacao(r.observacoes);
        setMotivo(r.atraso);

        motivoInicial.current = r.atraso;
        observacaoInicial.current = r.observacoes;

        setCarregandoGeral(false);
      })
      .catch((e) => {
        console.error(e);
        setEnviando(false);
      });
  }

  /**
   * @param {object} event eventos
   */
  function handleSalvarAnotacao(event) {
    event.preventDefault();
    setEnviando(true);
    const data = {
      id: alvo.id,
      motivo,
      observacao
    };

    api
      .post(urlSalvarAnotacao, data)
      .then((r) => {
        toast.success('Anotação salva com sucesso!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: 'colored',
          transition: Bounce,
        });

        motivoInicial.current = motivo;
        observacaoInicial.current = observacao;

        setEnviando(false);
      })
      .catch((e) => {
        console.error(e);
        setEnviando(false);
      });
  }

  useEffect(() => {
    if (alvo) {
      handleAnotacoes();
    } else {
      setCarregandoGeral(true);

      motivoInicial.current = null;
      observacaoInicial.current = null;

      setObservacao();
      setMotivo();
      setAnexos([]);
    }
  }, [alvo]);

  return (
    <Ctx.Provider value={[setTransferirAtrasadoAlvo]}>
      {carregandoGeral || !alvo ? (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={!equipamentos}
        >
          <CircularProgress color='inherit' />
        </Backdrop>
      ) : (
        <Dialog
          open={alvo !== undefined}
          onClose={handleFechar}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
          fullWidth
          maxWidth='sm'
        >
          <DialogTitle id='alert-dialog-title'>{titulo}</DialogTitle>
          <DialogContent sx={{ overflow: 'visible' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <DialogContentText color={'error'}>
                  Este pedido está atrasado
                </DialogContentText>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  multiline
                  name='anotacao'
                  defaultValue={observacao}
                  rows={10}
                  label='Anotações sobre o processo'
                  onChange={handleObservacao}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  multiline
                  rows={2}
                  label='Motivo do atraso'
                  onChange={handleMotivo}
                  defaultValue={motivo}
                />
              </Grid>
              {anexos && (
                <Grid item xs={12}>
                  <Stack sx={{ overflow: 'scroll', width: '100%' }}>
                    <Stack spacing={2} direction={'row'} mb={2}>
                      {anexos.map((item, index) => (
                        <a
                          key={`item-imagem-item-${index}`}
                          href={`${URL.createObjectURL(item)}`}
                          target='_blank'
                          rel='noreferrer'
                          style={{ width: '145px', height: '145px' }}
                        >
                          <img
                            src={`${URL.createObjectURL(item)}`}
                            loading='lazy'
                            style={{
                              width: '145px',
                              height: '145px',
                              objectFit: 'cover',
                            }}
                          />
                        </a>
                      ))}
                    </Stack>
                  </Stack>
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            {alvo.setor == 26 && (
              <Button
                component='label'
                type='button'
                variant='contained'
                color='secondary'
                disabled={enviando}
                startIcon={<CollectionsIcon />}
                sx={{ margin: '0 auto', marginLeft: 0 }}
              >
                Adicionar anexos
                <input
                  hidden
                  type='file'
                  accept='image/png, image/jpg, image/jpeg, image/webp'
                  name='anexo'
                  multiple
                  onChange={handleUpload}
                />
              </Button>
            )}
            <Button
              type='button'
              variant='outlined'
              disabled={enviando}
              onClick={handleFechar}
            >
              {botaoCancelarMensagem}
            </Button>
            <LoadingButton
              variant='contained'
              loading={enviando}
              onClick={handleSalvarAnotacao}
              disabled={
                motivoInicial.current === motivo &&
                observacaoInicial.current === observacao
              }
            >
              Salvar
            </LoadingButton>
            <LoadingButton
              type='submit'
              variant='contained'
              loading={enviando}
              disabled={!motivo || motivoInicial.current !== motivo}
              onClick={handleEnviar}
            >
              {botaoAcaoMensagem}
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}
      {children}
    </Ctx.Provider>
  );
}

TransferirAtrasadoProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useTransferirAtrasado = () => {
  return useContext(Ctx);
};

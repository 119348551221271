import { Button, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { AlterarEquipamentoProvider } from '../../../../Hooks/Funcionalidades/Equipamento/Alterar';
import { ExcluirEquipamentoProvider } from '../../../../Hooks/Funcionalidades/Equipamento/Excluir';
import {
  NovoEquipamentoProvider,
  useNovoEquipamento,
} from '../../../../Hooks/Funcionalidades/Equipamento/Novo';
import { useEquipamentos } from '../../../../Hooks/Sistema/Equipamentos';
import Acoes from './Acoes/Acoes';
import { useFuncoes } from '../../../../Hooks/FuncoesGerais';
import { formatarDataHora } from '../../../../Uteis/Funcoes';

/**
 * @return {object}
 */
function ListaSkeleton() {
  return Array(5)
    .fill()
    .map((item, index) => (
      <TableRow key={`skeleton-usuario-item-${index}`}>
        <TableCell align='center'>
          <Skeleton variant='text' width={'5ch'} />
        </TableCell>
        <TableCell align='center'>
          <Skeleton variant='text' width={'5ch'} />
        </TableCell>
        <TableCell align='center'>
          <Skeleton variant='text' width={'7ch'} />
        </TableCell>
        <TableCell align='center'>
          <Skeleton variant='text' width={'12ch'} />
        </TableCell>
        <TableCell align='center'>
          <Skeleton variant='text' width={'8ch'} />
        </TableCell>
        <TableCell align='center'>
          <Skeleton
            variant='rounded'
            width={'102.36px'}
            height={'36.5px'}
            style={{ float: 'inline-end' }}
          />
        </TableCell>
      </TableRow>
    ));
}

/**
 * @param {array} lista
 * @param {function} tentarNovamente
 * @return {object}
 */
function Lista({ lista, tentarNovamente }) {
  const [, setAberto] = useNovoEquipamento();

  return (
    <React.Fragment>
      <Stack justifyContent='space-between' alignItems='center' direction='row'>
        <Typography component='h2' variant='h6' color='primary' gutterBottom>
          Equipamentos
        </Typography>
        <Button
          variant='outlined'
          onClick={() => {
            setAberto(true);
          }}
        >
          Novo equipamento
        </Button>
      </Stack>
      <Table size='small' aria-label='caption table'>
        <TableHead>
          <TableRow>
            <TableCell align='center'>Id</TableCell>
            <TableCell align='center'>Titulo</TableCell>
            <TableCell align='center'>Cata do cadastro</TableCell>
            <TableCell align='center'></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {lista ? (
            lista.map(({ id, titulo, criadoEm }, index) => (
              <TableRow key={`item-map-equipamento-${index}`}>
                <TableCell align='center'>{id}</TableCell>
                <TableCell align='center'>{titulo}</TableCell>
                <TableCell align='center'>{formatarDataHora(criadoEm)}</TableCell>
                <TableCell className='text-end'>
                  <Acoes id={id} />
                </TableCell>
              </TableRow>
            ))
          ) : lista === null ? (
            <TableRow>
              <TableCell
                className='text-center'
                colSpan={6}
                sx={{ height: '247.5px' }}
              >
                <Typography gutterBottom>
                  Houve um erro ao carregar a listagem!
                </Typography>
                <Button onClick={tentarNovamente}>Tentar novamente</Button>
              </TableCell>
            </TableRow>
          ) : (
            <ListaSkeleton />
          )}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}

Lista.propTypes = {
  lista: PropTypes.array,
  tentarNovamente: PropTypes.func.isRequired,
};

/**
 * @return {void} lista de clientes cadastrados.
 */
export default function Equipamentos() {
  const [equipamentos] = useEquipamentos();
  const [, equipamentosFetch] = useFuncoes();

  useEffect(() => {
    equipamentosFetch()
  }, []);

  return (
    <NovoEquipamentoProvider>
      <AlterarEquipamentoProvider>
        <ExcluirEquipamentoProvider>
          <Grid item xs={12} md={8} lg={9}>
            <Grid item xs={12}>
              <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <Lista lista={equipamentos} tentarNovamente={equipamentosFetch} />
              </Paper>
            </Grid>
          </Grid>
        </ExcluirEquipamentoProvider>
      </AlterarEquipamentoProvider>
    </NovoEquipamentoProvider>
  );
}

import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContentText } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useState } from 'react';
import { Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useApi } from '../../../Api';
import { useChamados } from '../../../Sistema/Chamados';

export const Ctx = createContext();

/**
 * Hooks manager
 * @param {any} children
 * @return {children} Hooks.
 */
export function TransferirNovamenteProvider({ children }) {
  const [alvo, setTransferirNovamenteAlvo] = useState();
  const [enviando, setEnviando] = useState(false);
  const [, setChamados] = useChamados();
  const ApiController = useApi();
  const api = new ApiController();

  /**
   */
  function handleFechar() {
    setTransferirNovamenteAlvo();
  }

  /**
   * @param {object} prev
   * @param {object} r
   * @return {object}
   */
  function alterarChamados(prev, r) {
    const chamadoEncontrado = prev.findIndex((item) => item.id === alvo.id);

    const prevChamado = structuredClone(prev);

    prevChamado[chamadoEncontrado] = r;

    return prevChamado;
  }

  /**
   * @param {object} event eventos
   */
  function handleEnviar(event) {
    event.preventDefault();
    setEnviando(true);
    const data = { id: alvo.id };

    api
      .post('auditoria/transferir', data)
      .then((r) => {
        setChamados((prev) => alterarChamados(prev, r));

        toast.success('Chamado transferido com sucesso!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: 'colored',
          transition: Bounce,
        });

        setEnviando(false);
        handleFechar();
      })
      .catch((e) => {
        console.error(e);
        setEnviando(false);
      });
  }

  return (
    <Ctx.Provider value={[setTransferirNovamenteAlvo]}>
      {alvo && (
        <Dialog
          open={alvo !== undefined}
          onClose={handleFechar}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
          fullWidth
          maxWidth='sm'
        >
          <DialogTitle id='alert-dialog-title'>
            Transferir novamente ao setor
          </DialogTitle>
          <DialogContent>
            <DialogContentText>Você tem certeza disso?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              type='button'
              variant='outlined'
              disabled={enviando}
              onClick={handleFechar}
            >
              {'Cancelar'}
            </Button>
            <LoadingButton
              type='submit'
              variant='contained'
              loading={enviando}
              onClick={handleEnviar}
            >
              {'Transferir'}
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}
      {children}
    </Ctx.Provider>
  );
}

TransferirNovamenteProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useTransferirNovamente = () => {
  return useContext(Ctx);
};

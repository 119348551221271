import { io } from "socket.io-client";
import { paramApiUrl, paramSiteUrl } from "./Parametros";



export const socket = io(process.env.NODE_ENV === 'development' ? "http://localhost:3334" : paramApiUrl, {
  autoConnect: false, 
  cors: {
    origin: process.env.NODE_ENV === 'development' ? "http://localhost:3000" : paramSiteUrl,
    methods: ["GET", "POST"],
    transports: ["websocket"],
    credentials: true,
  },
});

import React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

/**
 * Componente para página inicial.
 * @param {any} props props do elemento
 * @return {void} retorna a página de acordo com a autenticação.
 */
export default function Copyright(props) {
  return (
    <Typography
      variant='body2'
      color='text.secondary'
      align='center'
      {...props}
    >
      {'Copyright © '}
      <Link
        color='inherit'
        target='_blank'
        href='https://estudiovisual.com/'
        rel='noopener'
      >
        Estúdio Visual
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

import { Button, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useApi } from '../../../Hooks/Api';
import { NovoClienteProvider } from '../../../Hooks/Funcionalidades/Cliente/NovoCliente';
import {
  MovimentacoesProvider,
  useVisualizarMovimentacoes,
} from '../../../Hooks/Funcionalidades/Historico/Movimentacoes';
import {
  AlterarAtendimentoProvider,
  useAlterarAtendimento,
} from '../../../Hooks/Funcionalidades/Setor/Atendimento/Alterar';
import {
  NovoAtendimentoProvider,
  useNovoAtendimento,
} from '../../../Hooks/Funcionalidades/Setor/Atendimento/Novo';
import { EntregarChamadoProvider } from '../../../Hooks/Funcionalidades/Setor/Chamado/Entregar';
import { ExcluirPedidoProvider } from '../../../Hooks/Funcionalidades/Setor/Pedido/Excluir';
import { FinalizarPedidoProvider } from '../../../Hooks/Funcionalidades/Setor/Pedido/Finalizar';
import { NovoPedidoProvider } from '../../../Hooks/Funcionalidades/Setor/Pedido/Novo';
import { TransferirPedidoProvider } from '../../../Hooks/Funcionalidades/Setor/Pedido/Transferir';
import { TransferirAtrasadoProvider } from '../../../Hooks/Funcionalidades/Setor/Pedido/TransferirAtrasado';
import { useFuncoes } from '../../../Hooks/FuncoesGerais';
import { useChamados } from '../../../Hooks/Sistema/Chamados';
import { useClientes } from '../../../Hooks/Sistema/Clientes';
import { useEquipamentos } from '../../../Hooks/Sistema/Equipamentos';
import { useSetores } from '../../../Hooks/Sistema/Setores';
import Status from '../../Chamado/Status';
import ChamadoPrazo from './Prazo';
import {
  TransferirNovamenteProvider,
  useTransferirNovamente,
} from '../../../Hooks/Funcionalidades/Setor/Atendimento/TransferirNovamente';

/**
 * @param {function} chamado
 * @param {number} atrasado
 * @return {node}
 */
function AcoesChamado({ chamado, atrasado }) {
  const [, setAtendimentoAlvo] = useNovoAtendimento();
  const [setAlterarAtendimentoAlvo] = useAlterarAtendimento();
  const [setTransferirNovamenteAlvo] = useTransferirNovamente();

  if (atrasado === 0) {
    if (chamado.status == 4) {
      return (
        <Button
          onClick={() => {
            setAtendimentoAlvo(chamado);
          }}
        >
          Iniciar Atendimento
        </Button>
      );
    } else if (chamado.status == 6) {
      return (
        <Button
          onClick={() => {
            setAlterarAtendimentoAlvo(chamado);
          }}
        >
          Retornar Atendimento
        </Button>
      );
    }
  } else {
    if (atrasado === 1) {
      return (
        <Button
          onClick={() => {
            setTransferirNovamenteAlvo(chamado);
          }}
        >
          Transferir novamente
        </Button>
      );
    } else if (atrasado === 2) {
      return (
        <Button
          onClick={() => {
            setTransferirNovamenteAlvo(chamado);
          }}
        >
          Enviar para Auditoria
        </Button>
      );
    }
  }
}

AcoesChamado.propTypes = {
  chamado: PropTypes.object.isRequired,
  atrasado: PropTypes.number.isRequired,
};

/**
 * @return {object}
 */
function ListaSkeleton() {
  return Array(5)
    .fill()
    .map((_, index) => (
      <TableRow key={`skeleton-usuario-item-${index}`}>
        <TableCell align='center'>
          <Skeleton variant='text' width={'7ch'} />
        </TableCell>
        <TableCell align='center'>
          <Skeleton variant='text' width={'5ch'} />
        </TableCell>
        <TableCell align='center'>
          <Skeleton variant='text' width={'7ch'} />
        </TableCell>
        <TableCell align='center'>
          <Skeleton variant='text' width={'12ch'} />
        </TableCell>
        <TableCell align='center'>
          <Skeleton variant='text' width={'8ch'} />
        </TableCell>
        <TableCell align='center'>
          <Skeleton variant='text' width={'8ch'} />
        </TableCell>
        <TableCell align='center'>
          <Skeleton
            variant='rounded'
            width={'190.36px'}
            height={'36.5px'}
            style={{ float: 'inline-end' }}
          />
        </TableCell>
      </TableRow>
    ));
}

/**
 * @param {object} setor
 * @return {object}
 */
function Lista({ setor }) {
  const [clientes] = useClientes();
  const [equipamentos] = useEquipamentos();
  const [, equipamentosFetch, , clientesFetch] = useFuncoes();
  const [chamados, setChamados] = useChamados();
  const [setMovimentacaoAlvo] = useVisualizarMovimentacoes();
  const [, setAtendimentoAlvo] = useNovoAtendimento();
  const [setores] = useSetores();

  /**
   * @param {object} ch
   */
  function handleMovimentacao(ch) {
    setMovimentacaoAlvo(ch);
  }

  /**
   * @param {object} prev
   * @param {number} index
   * @return {object}
   */
  function substituirChamado(prev, index) {
    const prevClone = structuredClone(prev);

    prevClone[index].atrasado = true;

    return prevClone;
  }

  /**
   *  @param {string} index
   */
  function atrasarChamado(index) {
    if (!chamados[index].atrasado) {
      setChamados((prev) => substituirChamado(prev, index));
    }
  }

  useEffect(() => {
    clientesFetch();
    equipamentosFetch();
  }, []);

  return (
    <>
      <Stack justifyContent='space-between' alignItems='center' direction='row'>
        <Typography component='h2' variant='h6' color='primary' gutterBottom>
          Pós venda de prospecção
        </Typography>
        <Button
          variant='outlined'
          onClick={() => {
            setAtendimentoAlvo(true);
          }}
        >
          Novo atendimento
        </Button>
      </Stack>
      <Table size='small' aria-label='caption table'>
        <TableHead>
          {setor?.id != 14 && (
            <TableRow>
              <TableCell align='center'>Pedido</TableCell>
              <TableCell align='center'>Setor</TableCell>
              <TableCell align='center'>Status</TableCell>
              <TableCell align='center'>Cliente</TableCell>
              <TableCell align='center'>Prazo</TableCell>
              <TableCell align='center'></TableCell>
            </TableRow>
          )}
        </TableHead>
        <TableBody>
          {chamados && clientes && setores && equipamentos ? (
            chamados
              .filter(
                (item) =>
                  setores
                    .filter((nItem) => [1, 3].includes(nItem.categoria))
                    .map((nItem) => nItem.id)
                    .includes(item.setor) &&
                  [2, 4, 6].includes(item.status) &&
                  item.excluido == 0 &&
                  (item?.posVenda?.tipo === 2 || !item.posVenda)
              )
              .filter((item) => {
                const agora = Number(new Date());

                if (item.status === 2) {
                  return true;
                } else if (item.status === 4) {
                  const ultimaMovimentacao = Number(
                    new Date(
                      item?.pedido?.movimentadoEm
                        ? item.pedido.movimentadoEm
                        : item.posVenda.entregaEm
                    )
                  );

                  const diferenca =
                    Math.abs(agora - ultimaMovimentacao) /
                    (1000 * 60 * 60 * 24);

                  return diferenca > 30;
                } else if (item.status === 6) {
                  const dataDeRetorno = Number(
                    new Date(item.posVenda.entregaEm)
                  );

                  const diferenca =
                    Math.abs(agora - dataDeRetorno) / (1000 * 60 * 60 * 24);

                  return diferenca > 30;
                }
              })
              .map((chamado, index) => {
                const { id, setor, status, cliente } = chamado;

                let atrasou = 0;

                if (chamado.posVenda) {
                  const agora = Number(new Date());

                  const dataDeRetorno = Number(
                    new Date(chamado.posVenda.entregaEm)
                  );

                  const diferenca =
                    (agora - dataDeRetorno) / (1000 * 60 * 60 * 24);

                  if (diferenca > 0) {
                    atrasou = 1;
                  }
                }

                if (atrasou === 1 && chamado.posVenda.auditoria === 1) {
                  atrasou = 2;
                }

                return (
                  <TableRow key={`item-map-cliente-${index}`}>
                    <TableCell align='center'>
                      <Button
                        onClick={() => {
                          handleMovimentacao(chamado);
                        }}
                      >
                        {chamado?.pedido ? chamado?.pedido?.pedido : id}
                      </Button>
                    </TableCell>
                    <TableCell align='center'>
                      {setores.find((item) => item.id == setor).nome}
                    </TableCell>
                    <Status status={status} />
                    <TableCell align='center'>
                      {
                        clientes.find((item) => item.id === cliente)
                          .nomeFantasia
                      }
                    </TableCell>
                    {setor === 2 ? (
                      <ChamadoPrazo
                        chamado={chamado}
                        acao={() => {
                          atrasarChamado(index);
                        }}
                      />
                    ) : (
                      <TableCell align='center'>--</TableCell>
                    )}
                    <TableCell className='text-end'>
                      <AcoesChamado chamado={chamado} atrasado={atrasou} />
                    </TableCell>
                  </TableRow>
                );
              })
          ) : (
            <ListaSkeleton />
          )}
        </TableBody>
      </Table>
    </>
  );
}

Lista.propTypes = {
  setor: PropTypes.object,
};

/**
 * @return {void} lista de clientes cadastrados.
 */
export default function PosVendaAtendimento() {
  const [chamados, setChamados] = useChamados();
  const ApiController = useApi();
  const api = new ApiController();
  const [setores] = useSetores();
  const [setor, setSetor] = useState(
    setores?.find((item) => item.id == 2) ?? null
  );
  const [carregou, setCarregou] = useState(false);

  useEffect(() => {
    if (!carregou) {
      setCarregou(true);
      api
        .post('setores/dados', { id: 2 })
        .then((r) => {
          if (chamados) {
            const prevChamados = structuredClone(chamados).filter((item) =>
              setores
                .filter((nItem) => [2].includes(nItem.categoria))
                .map((nItem) => nItem.id)
                .includes(item.setor)
            );

            setChamados([...prevChamados, ...r]);
          } else {
            setChamados(r);
          }
        })
        .catch((e) => {});
    }
  }, [setor]);

  useEffect(() => {
    if (setores) {
      setSetor(setores.find((item) => item.id == 2));
    }
  }, [setores]);

  return (
    <NovoClienteProvider>
      <NovoAtendimentoProvider>
        <AlterarAtendimentoProvider>
          <EntregarChamadoProvider>
            <NovoPedidoProvider>
              <TransferirPedidoProvider>
                <TransferirAtrasadoProvider>
                  <FinalizarPedidoProvider>
                    <ExcluirPedidoProvider>
                      <TransferirNovamenteProvider>
                        <MovimentacoesProvider>
                          <Grid item xs={12} md={8} lg={9}>
                            <Grid item xs={12}>
                              <Paper
                                sx={{
                                  p: 2,
                                  display: 'flex',
                                  flexDirection: 'column',
                                }}
                              >
                                <Lista setor={setor} />
                              </Paper>
                            </Grid>
                          </Grid>
                        </MovimentacoesProvider>
                      </TransferirNovamenteProvider>
                    </ExcluirPedidoProvider>
                  </FinalizarPedidoProvider>
                </TransferirAtrasadoProvider>
              </TransferirPedidoProvider>
            </NovoPedidoProvider>
          </EntregarChamadoProvider>
        </AlterarAtendimentoProvider>
      </NovoAtendimentoProvider>
    </NovoClienteProvider>
  );
}

import { LoadingButton } from '@mui/lab';
import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Tooltip,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useApi } from '../../../Hooks/Api';
import { useFuncoes } from '../../../Hooks/FuncoesGerais';

const urlAnotacoes = 'chamados/pedido/anotacoes';
const urlSalvarAnotacao = 'chamados/pedido/salvar-anotacao';

/**
 * @param {object} chamado
 * @return {object}
 */
function AcoesChamado({ chamado }) {
  const [enviando, setEnviando] = useState(false);
  const [, , , , iniciarChamado] = useFuncoes();
  const [aberto, setAberto] = useState(false);
  const ApiController = useApi();
  const api = new ApiController();

  const [observacao, setObservacao] = useState();
  const [carregandoGeral, setCarregandoGeral] = useState(true);
  const observacaoInicial = useRef();

  /**
   * @param {object} e
   */
  function handleObservacao(e) {
    setObservacao(e.target.value);
  }

  /**
   */
  function iniciarChamadoSetor() {
    const data = { id: chamado.id };
    setEnviando(true);
    api
      .post('chamados/pos-venda/iniciar', data)
      .then((r) => {
        iniciarChamado(r);

        setEnviando(false);

        toast.success('Chamado iniciado com sucesso', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: 'colored',
          transition: Bounce,
        });

        setEnviando(false);
      })
      .catch((e) => {
        console.error(e);
        setEnviando(false);
      });
  }

  /**
   */
  function handleClose() {
    setAberto(false);
  }

  /**
   */
  function handleOpen() {
    setAberto(true);
  }

  const retornarDuracao = () => {
    const pedidoMov = chamado?.pedido
      ? chamado.pedido.movimentadoEm
      : chamado.criadoEm;

    const movimentacao = new Date(pedidoMov);

    const agora = new Date();

    const duracao = Math.abs(agora.getTime() - movimentacao.getTime());

    return duracao;
  };

  /**
   */
  function finalizarChamado() {
    const data = { id: chamado.id, duracao: retornarDuracao() };
    setEnviando(true);
    api
      .post('chamados/pos-venda/finalizar', data)
      .then((r) => {
        iniciarChamado(r);

        setEnviando(false);

        toast.success('Chamado iniciado com sucesso', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: 'colored',
          transition: Bounce,
        });

        setEnviando(false);
        handleClose();
      })
      .catch((e) => {
        console.error(e);
        setEnviando(false);
      });
  }

  /**
   */
  function handleAnotacoes() {
    const data = {
      id: chamado.id,
    };

    api
      .post(urlAnotacoes, data)
      .then((r) => {
        setObservacao(r.observacoes);
        observacaoInicial.current = r.observacoes;
        setCarregandoGeral(false);
      })
      .catch((e) => {
        console.error(e);
        setEnviando(false);
      });
  }

  /**
   * @param {object} event eventos
   */
  function handleSalvarAnotacao(event) {
    event.preventDefault();
    setEnviando(true);
    const data = {
      id: chamado.id,
      observacao,
    };

    api
      .post(urlSalvarAnotacao, data)
      .then((r) => {
        toast.success('Anotação salva com sucesso!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: 'colored',
          transition: Bounce,
        });

        observacaoInicial.current = observacao;
        setEnviando(false);
      })
      .catch((e) => {
        console.error(e);
        setEnviando(false);
      });
  }

  useEffect(() => {
    if (chamado) {
      handleAnotacoes();
    } else {
      setCarregandoGeral(true);
      observacaoInicial.current = null;
      setObservacao();
    }
  }, [chamado]);

  if (chamado.status === 2) {
    return (
      <Tooltip title='Transferir'>
        <LoadingButton
          variant='contained'
          loading={enviando}
          onClick={iniciarChamadoSetor}
        >
          Iniciar
        </LoadingButton>
      </Tooltip>
    );
  } else if (chamado.status === 3) {
    return (
      <>
        <Tooltip title='Transferir'>
          <Button variant='contained' onClick={handleOpen}>
            Finalizar
          </Button>
        </Tooltip>
        {carregandoGeral || !chamado ? (
          <Backdrop open={!carregandoGeral}>
            <CircularProgress />
          </Backdrop>
        ) : (
          <Dialog
            open={aberto}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
            fullWidth
            className='qt-dialog'
          >
            <DialogTitle id='alert-dialog-title'>Finalizar serviço</DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    multiline
                    name='anotacao'
                    defaultValue={observacao}
                    rows={4}
                    label='Anotações sobre o processo'
                    onChange={handleObservacao}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                type='button'
                variant='outlined'
                disabled={enviando}
                onClick={handleClose}
              >
                Cancelar
              </Button>
              <LoadingButton
                variant='contained'
                loading={enviando}
                onClick={handleSalvarAnotacao}
                disabled={observacaoInicial.current === observacao}
              >
                Salvar
              </LoadingButton>
              <LoadingButton
                type='submit'
                variant='contained'
                loading={enviando}
                onClick={finalizarChamado}
              >
                Finalizar
              </LoadingButton>
            </DialogActions>
          </Dialog>
        )}
      </>
    );
  }
}

AcoesChamado.propTypes = {
  chamado: PropTypes.object.isRequired,
};

export default AcoesChamado;

import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export const ErrorHandlerContext = createContext();

/**
 * Provider para a Autenticação
 * @param {children} children Componente children
 * @return {children} Context para errors.
 */
export function ErrorHandlerProvider({ children }) {
  const [erroVisivel, setErroVisivel] = useState(false);
  const [erroDados, setErroDados] = useState(null);

  /**
   * Define os dados de erro
   * @param {object} e Erro
   * @return {void}
   */
  function mostrarErro(e) {
    setErroVisivel(true);
    setErroDados(e);
  }

  const handleFechar = () => {
    setErroVisivel(false);
  };

  return (
    <ErrorHandlerContext.Provider value={[mostrarErro, handleFechar]}>
      {children}
      <Dialog
        open={erroVisivel}
        onClose={handleFechar}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        {erroDados && (
          <>
            <DialogTitle id='alert-dialog-title'>{erroDados[0]}</DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-description'>
                {erroDados[1].conteudo.map((item, index) => (
                  <React.Fragment key={'conteudo-dialog-' + index}>
                    <span>{item}</span> <br />
                  </React.Fragment>
                ))}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              {erroDados[1].acoes.map((item, index) => (
                <Button
                  key={'botao-dialog-' + index}
                  onClick={() => {
                    handleFechar();
                    erroDados[1].funcoes[index]();
                  }}
                  autoFocus
                >
                  {item}
                </Button>
              ))}
            </DialogActions>
          </>
        )}
      </Dialog>
    </ErrorHandlerContext.Provider>
  );
}

ErrorHandlerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useErrorHandler = () => {
  return useContext(ErrorHandlerContext);
};

import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import { Chip, TableCell } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDiferencaEntreDatas } from '../../../Uteis/Funcoes';

/**
 * @param {Date} data
 * @param {function} acao
 * @return {object}
 */
function Contador({ data, acao }) {
  const [dataHoraAtual, setDataHoraAtual] = useState(new Date());

  const { completado, horas, minutos, segundos } = useDiferencaEntreDatas(
    dataHoraAtual,
    data
  );

  const classeAtual = completado ? 'qt-prazo-atrasado' : 'qt-prazo-dentro';

  useEffect(() => {
    if (completado) {
      acao();
    }
  }, [completado]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDataHoraAtual(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <TableCell align='center'>
      <Chip
        icon={completado ? <AccessAlarmsIcon color='#ffffff' /> : <></>}
        className={classeAtual}
        label={`${horas}:${minutos}:${segundos}`}
      />
    </TableCell>
  );
}

Contador.propTypes = {
  data: PropTypes.instanceOf(Date).isRequired,
  acao: PropTypes.func.isRequired,
};

/**
 * @param {object} chamado
 * @param {object} acao
 * @return {object}
 */
function ChamadoPrazo({ chamado, acao }) {
  const pedidoMov = chamado?.pedido
    ? chamado.pedido.movimentadoEm
    : chamado.criadoEm;

  const ultimaMovimentacao = new Date(pedidoMov);

  const trintaDias = 31 * 24 * 60 * 60 * 1000;

  const retornoEm30Dias = new Date(ultimaMovimentacao.getTime() + trintaDias);

  return <Contador data={retornoEm30Dias} acao={acao} />;
}

ChamadoPrazo.propTypes = {
  chamado: PropTypes.object.isRequired,
  acao: PropTypes.func.isRequired,
};

export default ChamadoPrazo;

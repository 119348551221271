import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContentText } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useState } from 'react';
import { IMaskInput } from 'react-imask';
import 'react-toastify/dist/ReactToastify.css';
import { useApi } from '../../Api';
import { useClientes } from '../../Sistema/Clientes';
import { Bounce, toast } from 'react-toastify';

export const Ctx = createContext();

const CampoCpf = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask='000.000.000-00'
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

const CampoCnpj = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask='00.000.000/0000-00'
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

const CampoCep = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask='00000-000'
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

CampoCpf.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

CampoCnpj.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

CampoCep.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

/**
 * Hooks manager
 * @param {any} children
 * @return {children} Hooks.
 */
export function ExcluirClienteProvider({ children }) {
  const [cliente, setClienteExcluir] = useState();
  const [enviando, setEnviando] = useState(false);
  const [clientes, setClientes] = useClientes();
  const ApiController = useApi();
  const api = new ApiController();

  /**
   * @param {object} event eventos
   */
  function handleEnviar(event) {
    event.preventDefault();
    setEnviando(true);
    const data = { id: cliente.id };

    api
      .post('clientes/excluir', data)
      .then((r) => {
        const clienteEncontrado = clientes.findIndex(
          (item) => Number(item.id) === Number(cliente.id)
        );

        const prevClientes = structuredClone(clientes);

        delete prevClientes[clienteEncontrado];

        setClientes(prevClientes);
        setEnviando(false);
        handleFechar();
        toast.success('Cliente excluido com sucesso!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: 'colored',
          transition: Bounce,
        });
      })
      .catch((e) => {
        console.error(e);
        setEnviando(false);
      });
  }

  /**
   */
  function handleFechar() {
    setClienteExcluir();
  }

  return (
    <Ctx.Provider value={[cliente, setClienteExcluir]}>
      {cliente && (
        <Dialog
          open={cliente !== undefined}
          onClose={handleFechar}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
          fullWidth={false}
        >
          <DialogTitle id='alert-dialog-title'>Excluir cliente</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Você tem certeza que deseja excluir {cliente.nomeFantasia}? Esta
              ação é irreversível.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              type='button'
              variant='outlined'
              disabled={enviando}
              onClick={handleFechar}
            >
              {'Cancelar'}
            </Button>
            <LoadingButton
              type='submit'
              variant='contained'
              loading={enviando}
              onClick={handleEnviar}
            >
              {'Excluir'}
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}
      {children}
    </Ctx.Provider>
  );
}

ExcluirClienteProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useExcluirCliente = () => {
  return useContext(Ctx);
};

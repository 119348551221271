import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContentText } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useState } from 'react';
import { Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useApi } from '../../../Api';
import { useFuncoes } from '../../../FuncoesGerais';

export const Ctx = createContext();

/**
 * Hooks manager
 * @param {any} children
 * @return {children} Hooks.
 */
export function EntregarChamadoProvider({ children }) {
  const [alvo, setEntregarAlvo] = useState();
  const [enviando, setEnviando] = useState(false);
  const [, , , , iniciarChamado] = useFuncoes();
  const ApiController = useApi();
  const api = new ApiController();

  /**
   */
  function handleFechar() {
    setEntregarAlvo();
  }

  /**
   * @param {object} event eventos
   */
  function handleEnviar(event) {
    event.preventDefault();
    setEnviando(true);
    const data = { id: alvo.id };

    api
      .post('chamados/pos-venda/entregar', data)
      .then((r) => {
        iniciarChamado(r);

        toast.success('Chamado finalizado com sucesso!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: 'colored',
          transition: Bounce,
        });

        setEnviando(false);
        handleFechar();
      })
      .catch((e) => {
        console.error(e);
        setEnviando(false);
      });
  }

  return (
    <Ctx.Provider value={[setEntregarAlvo]}>
      {alvo && (
        <Dialog
          open={alvo !== undefined}
          onClose={handleFechar}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
          fullWidth
          maxWidth='sm'
        >
          <DialogTitle id='alert-dialog-title'>Entregar chamado</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Você tem certeza disso? Após entregue, você não poderá mais
              acessar este chamado. Esta ação é irreversível.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              type='button'
              variant='outlined'
              disabled={enviando}
              onClick={handleFechar}
            >
              {'Cancelar'}
            </Button>
            <LoadingButton
              type='submit'
              variant='contained'
              loading={enviando}
              onClick={handleEnviar}
            >
              {'Entregar'}
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}
      {children}
    </Ctx.Provider>
  );
}

EntregarChamadoProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useEntregarChamado = () => {
  return useContext(Ctx);
};

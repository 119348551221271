import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { PropTypes } from 'prop-types';
import * as React from 'react';
import { useClienteContato } from '../../../../Hooks/Funcionalidades/Cliente/ClienteContato';
import { useEditarCliente } from '../../../../Hooks/Funcionalidades/Cliente/EditarCliente';
import { useExcluirCliente } from '../../../../Hooks/Funcionalidades/Cliente/ExcluirCliente';
import { useClientes } from '../../../../Hooks/Sistema/Clientes';
import StyledMenu from '../../../../Uteis/StyledMenu';

/**
 * @param {object} id
 * @return {void} Ações para o cliente.
 */
export default function Acoes({ id }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [clientes] = useClientes();
  const [, setCliente] = useClienteContato();
  const [, setClienteAlvo] = useEditarCliente();
  const [, setClienteExcluir] = useExcluirCliente();
  const open = Boolean(anchorEl);

  /**
   * @param {object} event
   */
  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  /**
   */
  function handleClose() {
    setAnchorEl(null);
  }

  /**
   */
  function handleAlterar() {
    setClienteAlvo(clientes.find((item) => item.id === id));
    handleClose();
  }

  /**
   */
  function handleContato() {
    setCliente(clientes.find((item) => item.id === id));
    handleClose();
  }

  /**
   */
  function handleExcluir() {
    setClienteExcluir(clientes.find((item) => item.id === id));
    handleClose();
  }

  return (
    <div>
      <Button
        id='demo-customized-button'
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        variant='contained'
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Ações
      </Button>
      <StyledMenu
        id='demo-customized-menu'
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleAlterar} disableRipple>
          <EditIcon />
          Alterar
        </MenuItem>
        <MenuItem onClick={handleContato} disableRipple>
          <ContactPhoneIcon />
          Contatos
        </MenuItem>
        <MenuItem onClick={handleExcluir} disableRipple>
          <DeleteIcon />
          Excluir
        </MenuItem>
      </StyledMenu>
    </div>
  );
}

Acoes.propTypes = {
  id: PropTypes.number.isRequired,
};

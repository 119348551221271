import PropTypes from 'prop-types';
import React, { createContext, useContext, useState } from 'react';
import { retornarCookie, retornarCookieCodificado } from '../Uteis/Funcoes';
import {
  paramCookieAutenticacao,
  paramCookieMaster,
} from '../Uteis/Parametros';

export const AuthContext = createContext();

/**
 * Provider para a Autenticação
 * @param {any} children componente children
 * @return {children} Autenticação.
 */
export function AuthProvider({ children }) {
  const [usuario, setUsuario] = useState(
    retornarCookieCodificado(paramCookieAutenticacao) || null
  );
  const [master, setMaster] = useState(
    Boolean(retornarCookie(paramCookieMaster)) || null
  );

  return (
    <AuthContext.Provider value={[usuario, setUsuario, master, setMaster]}>
      {children}
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useAuth = () => {
  return useContext(AuthContext);
};

import { TextField } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * @param {string} id
 * @param {string} pergunta
 * @param {string} respostas
 * @return {object}
 */
function Texto({ id, pergunta, respostas }) {
  const respostaItem = respostas?.find(
    (item) => Number(item.pergunta) === Number(id)
  );

  const resposta = respostaItem?.resposta ?? undefined;

  return (
    <>
      <input name='perguntas[]' hidden value={id} />
      <TextField
        required
        fullWidth
        multiline
        name={'respostas[]'}
        label={pergunta}
        defaultValue={resposta}
      />
    </>
  );
}

Texto.propTypes = {
  id: PropTypes.string.isRequired,
  pergunta: PropTypes.string.isRequired,
  respostas: PropTypes.string,
};

export default Texto;

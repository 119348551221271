import ClearIcon from '@mui/icons-material/Clear';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  Select,
  TextField,
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useApi } from '../../Api';
import { usePerguntas } from '../../Sistema/Perguntas';

const url = 'perguntas/alterar';
const titulo = 'Alterar pergunta';
const botaoCadastrarMensagem = 'Alterar pergunta';
const botaoCancelarMensagem = 'Cancelar';

export const Ctx = createContext();

/**
 * Hooks manager
 * @param {any} children
 * @return {children} Hooks.
 */
export function AlterarPerguntaProvider({ children }) {
  const [alvo, setAlvo] = useState();
  const [enviando, setEnviando] = useState(false);
  const [tipoPergunta, setTipoPergunta] = useState();
  const [alternativas, setAlternativas] = useState(['Opção 1']);
  const [perguntas, setPerguntas] = usePerguntas();
  const ApiController = useApi();
  const api = new ApiController();

  /**
   * @param {object} e
   */
  function handleTipoPergunta(e) {
    setTipoPergunta(e.target.value);
  }

  /**
   */
  function handleFechar() {
    setAlvo();
  }

  /**
   */
  function handleAdicionarAlternativa() {
    if (alternativas) {
      const seq = alternativas.length + 1;

      setAlternativas((prev) => [...prev, `Opção ${seq}`]);
    }
  }

  /**
   * @param {number} index
   */
  function handleRemoverAlternativa(index) {
    if (alternativas.length > 1) {
      const prevAlternativas = structuredClone(alternativas);

      prevAlternativas.splice(index, 1);

      setAlternativas(prevAlternativas);
    }
  }

  /**
   * @param {object} e
   */
  function handleAlterarAlternativa(e) {
    const prevAlternativas = structuredClone(alternativas);

    prevAlternativas[e.target.getAttribute('index')] = e.target.value;

    setAlternativas(prevAlternativas);
  }

  /**
   * @param {object} e eventos
   */
  function handleEnviar(e) {
    e.preventDefault();
    setEnviando(true);
    const data = new FormData(e.currentTarget);
    data.append('id', alvo.id);

    api
      .post(url, data)
      .then((r) => {
        const perguntaEncontrada = perguntas.findIndex(
          (item) => Number(item.id) === Number(r.id)
        );

        const prevPerguntas = structuredClone(perguntas);

        prevPerguntas[perguntaEncontrada] = r;

        setPerguntas(prevPerguntas);

        toast.success('Pergunta alterada com sucesso', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: 'colored',
          transition: Bounce,
        });

        setEnviando(false);
        handleFechar();
      })
      .catch((e) => {
        console.error(e);
        setEnviando(false);
      });
  }

  useEffect(() => {
    if (alvo) {
      setTipoPergunta(alvo.tipo);
      setAlternativas(JSON.parse(alvo.alternativas));
    } else {
      setTipoPergunta();
      setAlternativas();
    }
  }, [alvo]);

  return (
    <Ctx.Provider value={[setAlvo]}>
      {alvo && (
        <Dialog
          open={true}
          onClose={handleFechar}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
          fullWidth
          maxWidth={'sm'}
        >
          <DialogTitle id='alert-dialog-title'>{titulo}</DialogTitle>
          <DialogContent>
            <Box
              component='form'
              autoComplete='off'
              noValidate
              sx={{ mt: 3 }}
              onSubmit={handleEnviar}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    required
                    fullWidth
                    name='pergunta'
                    label='Pergunta'
                    id='pergunta'
                    autoComplete='off'
                    defaultValue={alvo.pergunta}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel>Tipo</InputLabel>
                    <Select
                      label='Tipo'
                      name='tipo'
                      required
                      onChange={handleTipoPergunta}
                      defaultValue={alvo.tipo}
                    >
                      <MenuItem value={1}>Múltipla escolha</MenuItem>
                      <MenuItem value={2}>Caixa de seleção</MenuItem>
                      <MenuItem value={3}>Resposta</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel>Categoria</InputLabel>
                    <Select
                      label='Categoria'
                      name='categoria'
                      required
                    >
                      <MenuItem value={1}>Padrão</MenuItem>
                      <MenuItem value={2}>Pós venda</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {tipoPergunta === 1 && (
                  <>
                    {alternativas &&
                      alternativas.map((item, index) => (
                        <Grid
                          key={`item-map-alternativa-${index}`}
                          item
                          sx={12}
                          sm={12}
                          display={'flex'}
                          alignItems={'center'}
                        >
                          <FormControlLabel
                            control={<Radio checked={false} disableRipple />}
                            sx={{ width: '100%' }}
                            label={
                              <TextField
                                variant='standard'
                                type='text'
                                name='alternativas[]'
                                value={item}
                                autoFocus
                                inputProps={{ index }}
                                onChange={handleAlterarAlternativa}
                              />
                            }
                          />
                          {alternativas.length > 1 && (
                            <IconButton
                              aria-label='delete'
                              onClick={() => {
                                handleRemoverAlternativa(index);
                              }}
                            >
                              <ClearIcon />
                            </IconButton>
                          )}
                        </Grid>
                      ))}
                    <Grid
                      item
                      sx={12}
                      sm={12}
                      display={'flex'}
                      alignItems={'center'}
                    >
                      <FormControlLabel
                        control={<Radio checked={false} disableRipple />}
                        label={
                          <TextField
                            variant='standard'
                            placeholder='Adicionar opção'
                            type='text'
                            onFocus={handleAdicionarAlternativa}
                          />
                        }
                      />
                    </Grid>
                  </>
                )}
                {tipoPergunta === 2 && (
                  <>
                    {alternativas &&
                      alternativas.map((item, index) => (
                        <Grid
                          key={`item-map-alternativa-${index}`}
                          item
                          sx={12}
                          sm={12}
                          display={'flex'}
                          alignItems={'center'}
                        >
                          <FormControlLabel
                            control={<Checkbox checked={false} disableRipple />}
                            sx={{ width: '100%' }}
                            label={
                              <TextField
                                variant='standard'
                                type='text'
                                name='alternativas[]'
                                value={item}
                                autoFocus
                                inputProps={{ index }}
                                onChange={handleAlterarAlternativa}
                              />
                            }
                          />
                          {alternativas.length > 1 && (
                            <IconButton
                              aria-label='delete'
                              onClick={() => {
                                handleRemoverAlternativa(index);
                              }}
                            >
                              <ClearIcon />
                            </IconButton>
                          )}
                        </Grid>
                      ))}
                    <Grid
                      item
                      sx={12}
                      sm={12}
                      display={'flex'}
                      alignItems={'center'}
                    >
                      <FormControlLabel
                        control={<Checkbox checked={false} disableRipple />}
                        sx={{ width: '100%' }}
                        label={
                          <TextField
                            variant='standard'
                            placeholder='Adicionar opção'
                            type='text'
                            onFocus={handleAdicionarAlternativa}
                          />
                        }
                      />
                    </Grid>
                  </>
                )}
              </Grid>
              <LoadingButton
                type='submit'
                fullWidth
                variant='contained'
                sx={{ mt: 3 }}
                loading={enviando}
              >
                {botaoCadastrarMensagem}
              </LoadingButton>
              <Button
                type='button'
                fullWidth
                variant='outlined'
                sx={{ mt: 2, mb: 2 }}
                onClick={handleFechar}
                disabled={enviando}
              >
                {botaoCancelarMensagem}
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      )}
      {children}
    </Ctx.Provider>
  );
}

export const useAlterarPergunta = () => {
  return useContext(Ctx);
};

AlterarPerguntaProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

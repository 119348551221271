import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContentText } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useState } from 'react';
import { Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useApi } from '../../../Api';
import { useFuncoes } from '../../../FuncoesGerais';

const url = 'chamados/pedido/finalizar';
const titulo = 'Finalizar pedido';
const mensagem = 'Você tem certeza disso?';
const botaoAcaoMensagem = 'Finalizar';
const botaoCancelarMensagem = 'Cancelar';

export const Ctx = createContext();

/**
 * Hooks manager
 * @param {any} children
 * @return {children} Hooks.
 */
export function FinalizarPedidoProvider({ children }) {
  const [alvo, setIniciarPedidoAlvo] = useState();
  const [enviando, setEnviando] = useState(false);
  const [, , , , iniciarChamado] = useFuncoes();
  const ApiController = useApi();
  const api = new ApiController();

  /**
   */
  function handleFechar() {
    setIniciarPedidoAlvo();
  }

  /**
   * @param {object} event eventos
   */
  function handleEnviar(event) {
    event.preventDefault();
    setEnviando(true);
    const data = { id: alvo.id };

    api
      .post(url, data)
      .then((r) => {
        iniciarChamado(r);

        toast.success('Pedido finalizado com sucesso!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: 'colored',
          transition: Bounce,
        });

        setEnviando(false);
        handleFechar();
      })
      .catch((e) => {
        console.error(e);
        setEnviando(false);
      });
  }

  return (
    <Ctx.Provider value={[setIniciarPedidoAlvo]}>
      {alvo && (
        <Dialog
          open={alvo !== undefined}
          onClose={handleFechar}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
          fullWidth={false}
        >
          <DialogTitle id='alert-dialog-title'>{titulo}</DialogTitle>
          <DialogContent>
            <DialogContentText>{mensagem}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              type='button'
              variant='outlined'
              disabled={enviando}
              onClick={handleFechar}
            >
              {botaoCancelarMensagem}
            </Button>
            <LoadingButton
              type='submit'
              variant='contained'
              loading={enviando}
              onClick={handleEnviar}
            >
              {botaoAcaoMensagem}
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}
      {children}
    </Ctx.Provider>
  );
}

FinalizarPedidoProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useFinalizarPedido = () => {
  return useContext(Ctx);
};

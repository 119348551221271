import { ExpandLess, ExpandMore } from '@mui/icons-material';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AppsIcon from '@mui/icons-material/Apps';
import AppsOutageIcon from '@mui/icons-material/AppsOutage';
import HistoryIcon from '@mui/icons-material/History';
import HomeIcon from '@mui/icons-material/Home';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import PhoneIcon from '@mui/icons-material/Phone';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import SettingsIcon from '@mui/icons-material/Settings';
import SettingsRemoteIcon from '@mui/icons-material/SettingsRemote';
import ViewHeadlineIcon from '@mui/icons-material/ViewHeadline';
import { Collapse, List, ListItemText, Skeleton } from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../Hooks/Auth';
import { useSetores } from '../../Hooks/Sistema/Setores';

/**
 * @return {object} retorna o menu lateral
 */
export function ItemsMenu() {
  const [usuario] = useAuth();
  const [setores] = useSetores();
  const [openFluxo, setOpenFluxo] = useState(true);
  const [openVendas, setOpenVendas] = useState(false);
  const [openTerceiros, setOpenTerceiros] = useState(false);

  if (JSON.parse(usuario.setor)[0] === 1) {
    return (
      <Fragment>
        <Link to={'/'} style={{ textDecoration: 'none', color: 'inherit' }}>
          <ListItemButton>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary={'Painel Geral'} />
          </ListItemButton>
        </Link>
        <ListItemButton
          onClick={() => {
            setOpenFluxo((prev) => !prev);
          }}
        >
          <ListItemIcon>
            <SettingsRemoteIcon />
          </ListItemIcon>
          <ListItemText primary='Produção' />
          {openFluxo ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openFluxo} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            {setores ? (
              setores
                .filter((item) => item.categoria === 2)
                .map(({ nome, link }, index) => (
                  <Link
                    key={`item-map-setor-${index}`}
                    to={`/setor/${link}`}
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemText primary={nome} />
                    </ListItemButton>
                  </Link>
                ))
            ) : (
              <>
                <ListItemButton>
                  <Skeleton variant='text' width={'8ch'} />
                </ListItemButton>
                <ListItemButton>
                  <Skeleton variant='text' width={'12ch'} />
                </ListItemButton>
                <ListItemButton>
                  <Skeleton variant='text' width={'9ch'} />
                </ListItemButton>
                <ListItemButton>
                  <Skeleton variant='text' width={'14ch'} />
                </ListItemButton>
                <ListItemButton>
                  <Skeleton variant='text' width={'12ch'} />
                </ListItemButton>
              </>
            )}
          </List>
        </Collapse>
        <ListItemButton
          onClick={() => {
            setOpenVendas((prev) => !prev);
          }}
        >
          <ListItemIcon>
            <PhoneIcon />
          </ListItemIcon>
          <ListItemText primary='Pós venda' />
          {openVendas ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openVendas} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            <Link
              to={`/pos-venda/producao`}
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemText primary={'De produção'} />
              </ListItemButton>
            </Link>
            <Link
              to={`/pos-venda/atendimentos`}
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemText primary={'De prospecção'} />
              </ListItemButton>
            </Link>
          </List>
        </Collapse>
        <ListItemButton
          onClick={() => {
            setOpenTerceiros((prev) => !prev);
          }}
        >
          <ListItemIcon>
            <OpenInNewIcon />
          </ListItemIcon>
          <ListItemText primary='Tercerizados' />
          {openTerceiros ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openTerceiros} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            {setores ? (
              setores
                .filter((item) => item.categoria == 3)
                .map(({ nome, link }, index) => (
                  <Link
                    key={`item-map-setor-terceiros-${index}`}
                    to={`/tercerizado/${link}`}
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemText primary={nome} />
                    </ListItemButton>
                  </Link>
                ))
            ) : (
              <>
                <ListItemButton>
                  <Skeleton variant='text' width={'8ch'} />
                </ListItemButton>
                <ListItemButton>
                  <Skeleton variant='text' width={'12ch'} />
                </ListItemButton>
                <ListItemButton>
                  <Skeleton variant='text' width={'9ch'} />
                </ListItemButton>
                <ListItemButton>
                  <Skeleton variant='text' width={'14ch'} />
                </ListItemButton>
                <ListItemButton>
                  <Skeleton variant='text' width={'12ch'} />
                </ListItemButton>
              </>
            )}
          </List>
        </Collapse>
        <Link
          to={'auditoria'}
          style={{ textDecoration: 'none', color: 'inherit' }}
        >
          <ListItemButton>
            <ListItemIcon>
              <AppsOutageIcon />
            </ListItemIcon>
            <ListItemText primary={'Auditoria'} />
          </ListItemButton>
        </Link>
        <Link
          to={'clientes'}
          style={{ textDecoration: 'none', color: 'inherit' }}
        >
          <ListItemButton>
            <ListItemIcon>
              <PeopleAltIcon />
            </ListItemIcon>
            <ListItemText primary={'Clientes'} />
          </ListItemButton>
        </Link>
        <Link
          to={'equipamentos'}
          style={{ textDecoration: 'none', color: 'inherit' }}
        >
          <ListItemButton>
            <ListItemIcon>
              <AccountTreeIcon />
            </ListItemIcon>
            <ListItemText primary={'Equipamentos'} />
          </ListItemButton>
        </Link>
        <Link
          to={'perguntas'}
          style={{ textDecoration: 'none', color: 'inherit' }}
        >
          <ListItemButton>
            <ListItemIcon>
              <QuestionAnswerIcon />
            </ListItemIcon>
            <ListItemText primary={'Perguntas'} />
          </ListItemButton>
        </Link>
        <Link
          to={'setores'}
          style={{ textDecoration: 'none', color: 'inherit' }}
        >
          <ListItemButton>
            <ListItemIcon>
              <AppsIcon />
            </ListItemIcon>
            <ListItemText primary={'Setores'} />
          </ListItemButton>
        </Link>
        <Link
          to={'usuarios'}
          style={{ textDecoration: 'none', color: 'inherit' }}
        >
          <ListItemButton>
            <ListItemIcon>
              <PeopleOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={'Usuarios'} />
          </ListItemButton>
        </Link>
        <Link
          to={'historico'}
          style={{ textDecoration: 'none', color: 'inherit' }}
        >
          <ListItemButton>
            <ListItemIcon>
              <HistoryIcon />
            </ListItemIcon>
            <ListItemText primary={'Histórico'} />
          </ListItemButton>
        </Link>
        <Link
          to={'configuracoes'}
          style={{ textDecoration: 'none', color: 'inherit' }}
        >
          <ListItemButton>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary={'Configurações'} />
          </ListItemButton>
        </Link>
      </Fragment>
    );
  } else if (usuario.setorObjeto.find((item) => item.categoria === 2)) {
    return (
      <Fragment>
        <Link to={'/'} style={{ textDecoration: 'none', color: 'inherit' }}>
          <ListItemButton>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary={'Todos os pedidos'} />
          </ListItemButton>
        </Link>
        {setores &&
          setores
            .filter(
              (item) =>
                JSON.parse(usuario.setor).findIndex(
                  (itemIndex) => itemIndex == item.id
                ) !== -1
            )
            .map((item) => (
              <Link
                key={`item-map-setor-0`}
                to={`/setor/${item.link}`}
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <ViewHeadlineIcon />
                  </ListItemIcon>
                  <ListItemText primary={item.nome} />
                </ListItemButton>
              </Link>
            ))}
      </Fragment>
    );
  } else if (usuario.setorObjeto.find((item) => item.categoria === 1)) {
    return (
      <Fragment>
        <ListItemButton
          onClick={() => {
            setOpenVendas((prev) => !prev);
          }}
        >
          <ListItemIcon>
            <PhoneIcon />
          </ListItemIcon>
          <ListItemText primary='Pós venda' />
          {!openVendas ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={!openVendas} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            <Link
              to={`/pos-venda/producao`}
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemText primary={'De produção'} />
              </ListItemButton>
            </Link>
            <Link
              to={`/pos-venda/atendimentos`}
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemText primary={'De prospecção'} />
              </ListItemButton>
            </Link>
            <Link
              to={'perguntas'}
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <ListItemButton>
                <ListItemIcon>
                  <QuestionAnswerIcon />
                </ListItemIcon>
                <ListItemText primary={'Perguntas'} />
              </ListItemButton>
            </Link>
          </List>
        </Collapse>
      </Fragment>
    );
  } else if (usuario.setorObjeto.find((item) => item.categoria === 3)) {
    return (
      <Fragment>
        <Link to={'/'} style={{ textDecoration: 'none', color: 'inherit' }}>
          <ListItemButton>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary={'Chamados'} />
          </ListItemButton>
        </Link>
      </Fragment>
    );
  }
}

export default ItemsMenu;

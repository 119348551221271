import CollectionsIcon from '@mui/icons-material/Collections';
import { LoadingButton } from '@mui/lab';
import {
  Backdrop,
  CircularProgress,
  DialogActions,
  DialogContentText,
  Grid,
  Stack,
  TextField,
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useApi } from '../../../Api';
import { useFuncoes } from '../../../FuncoesGerais';

const url = 'chamados/pedido/transferir';
const titulo = 'Transferir pedido';
const mensagem = 'Você tem certeza disso?';
const botaoAcaoMensagem = 'Transferir';
const botaoCancelarMensagem = 'Cancelar';
const urlAnotacoes = 'chamados/pedido/anotacoes';
const urlSalvarAnotacao = 'chamados/pedido/salvar-anotacao';

export const Ctx = createContext();

/**
 * Hooks manager
 * @param {any} children
 * @return {children} Hooks.
 */
export function TransferirPedidoProvider({ children }) {
  const [alvo, setTransferirPedidoAlvo] = useState();
  const [enviando, setEnviando] = useState(false);
  const [, , , , iniciarChamado] = useFuncoes();
  const [anexos, setAnexos] = useState([]);
  const ApiController = useApi();
  const api = new ApiController();
  const [carregandoGeral, setCarregandoGeral] = useState(true);
  const [observacao, setObservacao] = useState();

  const observacaoInicial = useRef();

  /**
   */
  function handleFechar() {
    setTransferirPedidoAlvo();
  }

  /**
   * @param {object} e
   */
  function handleObservacao(e) {
    setObservacao(e.target.value);
  }

  const retornarDuracao = () => {
    const movimentacao = new Date(alvo.pedido.movimentadoEm);

    const agora = new Date();

    const duracao = Math.abs(agora.getTime() - movimentacao.getTime());

    return duracao;
  };

  /**
   * @param {object} e
   */
  function handleUpload(e) {
    e.preventDefault();

    setAnexos(Array.from(e.target.files));
  }

  /**
   * @param {object} event eventos
   */
  function handleEnviar(event) {
    event.preventDefault();
    setEnviando(true);

    const data = new FormData();
    data.append('id', alvo.id);
    if (observacao) {
      data.append('observacao', observacao);
    }

    data.append('duracao', retornarDuracao());

    anexos.forEach((anexo, index) => {
      data.append(`anexo`, anexo);
    });

    api
      .post(url, data)
      .then((r) => {
        iniciarChamado(r);

        toast.success('Pedido transferido com sucesso!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: 'colored',
          transition: Bounce,
        });

        setEnviando(false);
        handleFechar();
      })
      .catch((e) => {
        console.error(e);
        setEnviando(false);
      });
  }

  /**
   */
  function handleAnotacoes() {
    const data = {
      id: alvo.id,
    };

    api
      .post(urlAnotacoes, data)
      .then((r) => {
        setObservacao(r.observacoes);

        observacaoInicial.current = r.observacoes;

        setCarregandoGeral(false);
      })
      .catch((e) => {
        console.error(e);
        setEnviando(false);
      });
  }

  /**
   * @param {object} event eventos
   */
  function handleSalvarAnotacao(event) {
    event.preventDefault();
    setEnviando(true);

    const data = {
      id: alvo.id,
      observacao
    }

    api
      .post(urlSalvarAnotacao, data)
      .then((r) => {
        toast.success('Anotação salva com sucesso!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: 'colored',
          transition: Bounce,
        });

        observacaoInicial.current = observacao;

        setEnviando(false);
      })
      .catch((e) => {
        console.error(e);
        setEnviando(false);
      });
  }

  useEffect(() => {
    if (alvo) {
      handleAnotacoes();
    } else {
      setCarregandoGeral(true);

      observacaoInicial.current = null;

      setObservacao();
      setAnexos([])
    }
  }, [alvo]);

  return (
    <Ctx.Provider value={[setTransferirPedidoAlvo]}>
      {carregandoGeral || !alvo ? (
        <Backdrop open={!carregandoGeral}>
          <CircularProgress />
        </Backdrop>
      ) : (
        <Dialog
          open={true}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
          fullWidth
          className='qt-dialog'
          onClose={handleFechar}
        >
          <DialogTitle id='alert-dialog-title'>{titulo}</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <DialogContentText>{mensagem}</DialogContentText>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  multiline
                  name='anotacao'
                  defaultValue={observacao}
                  rows={10}
                  label='Anotações sobre o processo'
                  onChange={handleObservacao}
                />
              </Grid>
              {anexos && (
                <Grid item xs={12}>
                  <Stack sx={{ overflow: 'scroll', width: '100%' }}>
                    <Stack spacing={2} direction={'row'} mb={2}>
                      {anexos.map((item, index) => (
                        <a
                          key={`item-imagem-item-${index}`}
                          href={`${URL.createObjectURL(item)}`}
                          target='_blank'
                          rel='noreferrer'
                          style={{ width: '145px', height: '145px' }}
                        >
                          <img
                            src={`${URL.createObjectURL(item)}`}
                            loading='lazy'
                            style={{
                              width: '145px',
                              height: '145px',
                              objectFit: 'cover',
                            }}
                          />
                        </a>
                      ))}
                    </Stack>
                  </Stack>
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            {alvo.setor == 26 && (
              <Button
                component='label'
                type='button'
                size='small'
                variant='contained'
                color='secondary'
                disabled={enviando}
                startIcon={<CollectionsIcon />}
                sx={{ margin: '0 auto', marginLeft: 0 }}
              >
                Adicionar anexos
                <input
                  hidden
                  type='file'
                  accept='image/png, image/jpg, image/jpeg, image/webp'
                  name='anexo'
                  multiple
                  onChange={handleUpload}
                />
              </Button>
            )}
            <Button
              type='button'
              variant='outlined'
              disabled={enviando}
              onClick={handleFechar}
            >
              {botaoCancelarMensagem}
            </Button>
            <LoadingButton
              variant='contained'
              loading={enviando}
              onClick={handleSalvarAnotacao}
              disabled={observacaoInicial.current === observacao}
            >
              Salvar anotações
            </LoadingButton>
            <LoadingButton
              type='submit'
              variant='contained'
              loading={enviando}
              onClick={handleEnviar}
            >
              {botaoAcaoMensagem}
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}
      {children}
    </Ctx.Provider>
  );
}

TransferirPedidoProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useTransferirPedido = () => {
  return useContext(Ctx);
};

import AccountTreeIcon from '@mui/icons-material/AccountTree';
import Button from '@mui/material/Button';
import { PropTypes } from 'prop-types';
import * as React from 'react';
import { useVisualizarMovimentacoes } from '../../../../../Hooks/Funcionalidades/Historico/Movimentacoes';

/**
 * @param {object} chamado
 * @return {void} Ações para o cliente.
 */
export default function Acoes({ chamado }) {
  const [setMovimentacaoAlvo] = useVisualizarMovimentacoes()
  /**
   */
  function handleClick() {
    setMovimentacaoAlvo(chamado)
  }

  return (
    <Button
        variant='contained'
        disableElevation
        onClick={handleClick}
        endIcon={<AccountTreeIcon />}
      >
        Movimentações
      </Button>
  );
}

Acoes.propTypes = {
  chamado: PropTypes.object.isRequired,
};

import PropTypes from 'prop-types';
import React, { createContext } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ApiProvider } from './Api';
import { AuthProvider } from './Auth';
import { ErrorHandlerProvider } from './ErrorHandler';
import { FuncoesProvider } from './FuncoesGerais';
import { ChamadosProvider } from './Sistema/Chamados';
import { ClientesProvider } from './Sistema/Clientes';
import { EquipamentosProvider } from './Sistema/Equipamentos';
import { EquipamentosAtivosProvider } from './Sistema/EquipamentosAtivos';
import { SetoresProvider } from './Sistema/Setores';
import TemaProvider from './Tema';
import { UsuariosProvider } from './Usuarios';
import { ConfiguracoesProvider } from './Sistema/Configuracoes';
import { MovimentacoesProvider } from './Sistema/MovimentacoesChamado';
import { AtrasosProvider } from './Sistema/AtrasosChamado';
import { PerguntasProvider } from './Sistema/Perguntas';
import { SocketManagerProvider } from './SocketManager';

export const HooksContext = createContext();

/**
 * Hooks manager
 * @param {any} children
 * @return {children} Hooks.
 */
export function HooksProvider({ children }) {
  return (
    <HooksContext.Provider value={null}>
      <TemaProvider>
        <BrowserRouter>
          <SocketManagerProvider>
            <ErrorHandlerProvider>
              <AuthProvider>
                <ApiProvider>
                  <UsuariosProvider>
                    <SetoresProvider>
                      <EquipamentosProvider>
                        <EquipamentosAtivosProvider>
                          <ClientesProvider>
                            <ChamadosProvider>
                              <ConfiguracoesProvider>
                                <MovimentacoesProvider>
                                  <AtrasosProvider>
                                    <PerguntasProvider>
                                      <FuncoesProvider>
                                        {children}
                                      </FuncoesProvider>
                                    </PerguntasProvider>
                                  </AtrasosProvider>
                                </MovimentacoesProvider>
                              </ConfiguracoesProvider>
                            </ChamadosProvider>
                          </ClientesProvider>
                        </EquipamentosAtivosProvider>
                      </EquipamentosProvider>
                    </SetoresProvider>
                  </UsuariosProvider>
                </ApiProvider>
              </AuthProvider>
            </ErrorHandlerProvider>
          </SocketManagerProvider>
        </BrowserRouter>
        <ToastContainer />
      </TemaProvider>
    </HooksContext.Provider>
  );
}

HooksProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

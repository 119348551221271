import { Button, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useApi } from '../../../Hooks/Api';
import { NovoClienteProvider } from '../../../Hooks/Funcionalidades/Cliente/NovoCliente';
import {
  MovimentacoesProvider,
  useVisualizarMovimentacoes,
} from '../../../Hooks/Funcionalidades/Historico/Movimentacoes';
import { AlterarAtendimentoProvider } from '../../../Hooks/Funcionalidades/Setor/Atendimento/Alterar';
import {
  NovoAtendimentoProvider,
  useNovoAtendimento,
} from '../../../Hooks/Funcionalidades/Setor/Atendimento/Novo';
import { EntregarChamadoProvider } from '../../../Hooks/Funcionalidades/Setor/Chamado/Entregar';
import { NovaAnotacaoChamadoProvider } from '../../../Hooks/Funcionalidades/Setor/Chamado/NovaAnotacao';
import { NovoChamadoProvider } from '../../../Hooks/Funcionalidades/Setor/Chamado/Novo';
import { ExcluirPedidoProvider } from '../../../Hooks/Funcionalidades/Setor/Pedido/Excluir';
import { FinalizarPedidoProvider } from '../../../Hooks/Funcionalidades/Setor/Pedido/Finalizar';
import {
  NovoPedidoProvider,
  useNovoPedido,
} from '../../../Hooks/Funcionalidades/Setor/Pedido/Novo';
import { TransferirPedidoProvider } from '../../../Hooks/Funcionalidades/Setor/Pedido/Transferir';
import { TransferirAtrasadoProvider } from '../../../Hooks/Funcionalidades/Setor/Pedido/TransferirAtrasado';
import { useFuncoes } from '../../../Hooks/FuncoesGerais';
import { useChamados } from '../../../Hooks/Sistema/Chamados';
import { useClientes } from '../../../Hooks/Sistema/Clientes';
import { useEquipamentos } from '../../../Hooks/Sistema/Equipamentos';
import { useSetores } from '../../../Hooks/Sistema/Setores';
import Status from '../../Chamado/Status';
import AcoesChamado from './Acoes';
import ChamadoPrazo from './Prazo';
import { useAuth } from '../../../Hooks/Auth';

/**
 * @return {object}
 */
function ListaSkeleton() {
  return Array(5)
    .fill()
    .map((item, index) => (
      <TableRow key={`skeleton-usuario-item-${index}`}>
        <TableCell align='center'>
          <Skeleton sx={{ margin: 'auto' }} variant='text' width={'5ch'} />
        </TableCell>
        <TableCell align='center'>
          <Skeleton sx={{ margin: 'auto' }} variant='text' width={'5ch'} />
        </TableCell>
        <TableCell align='center'>
          <Skeleton sx={{ margin: 'auto' }} variant='text' width={'7ch'} />
        </TableCell>
        <TableCell align='center'>
          <Skeleton sx={{ margin: 'auto' }} variant='text' width={'12ch'} />
        </TableCell>
        <TableCell align='center'>
          <Skeleton sx={{ margin: 'auto' }} variant='text' width={'8ch'} />
        </TableCell>
        <TableCell align='center'>
          <Skeleton sx={{ margin: 'auto' }} variant='text' width={'8ch'} />
        </TableCell>
        <TableCell align='center'>
          <Skeleton
            variant='rounded'
            width={'190.36px'}
            height={'36.5px'}
            sx={{ opacity: 0 }}
          />
        </TableCell>
      </TableRow>
    ));
}

/**
 * @param {object} setor
 * @return {object}
 */
function Lista({ setor }) {
  const [, setAtendimentoAlvo] = useNovoAtendimento();
  const [setNovoPedidoAberto] = useNovoPedido();
  const [clientes] = useClientes();
  const [equipamentos] = useEquipamentos();
  const [, equipamentosFetch, , clientesFetch] = useFuncoes();
  const [chamados, setChamados] = useChamados();
  const [setMovimentacaoAlvo] = useVisualizarMovimentacoes();

  /**
   * @return {object}
   */
  function BotaoChamado() {
    if (setor?.id == 2) {
      return (
        <Button
          variant='outlined'
          onClick={() => {
            setAtendimentoAlvo(setor);
          }}
        >
          Novo atendimento
        </Button>
      );
    } else if (setor?.id == 14) {
      return (
        <Button
          variant='outlined'
          onClick={() => {
            setNovoPedidoAberto(true);
          }}
        >
          Novo pedido
        </Button>
      );
    }

    return <> </>;
  }

  /**
   * @param {object} prev
   * @param {number} index
   * @return {object}
   */
  function substituirChamado(prev, index) {
    const prevClone = structuredClone(prev);

    prevClone[index].atrasado = true;

    return prevClone;
  }

  /**
   *  @param {string} index
   */
  function atrasarChamado(index) {
    if (!chamados[index].atrasado) {
      setChamados((prev) => substituirChamado(prev, index));
    }
  }

  /**
   * @param {object} ch
   */
  function handleMovimentacao(ch) {
    setMovimentacaoAlvo(ch);
  }

  useEffect(() => {
    clientesFetch();
    equipamentosFetch();
  }, []);

  useEffect(() => {
    console.log(chamados)
  }, [chamados])

  return (
    <>
      <Stack justifyContent='space-between' alignItems='center' direction='row'>
        <Typography component='h2' variant='h6' color='primary' gutterBottom>
          Pedidos
        </Typography>
        {clientes && equipamentos && <BotaoChamado />}
      </Stack>
      <Table size='small' aria-label='caption table'>
        <TableHead>
          {setor?.id != 14 && (
            <TableRow>
              <TableCell align='center'>Pedido</TableCell>
              <TableCell align='center'>Status</TableCell>
              <TableCell align='center'>Cliente</TableCell>
              {setor?.categoria === 2 && (
                <TableCell align='center'>Equipamento</TableCell>
              )}
              <TableCell align='center'>Anotações</TableCell>
              <TableCell align='center'>Prazo de resposta</TableCell>
              <TableCell align='center'></TableCell>
            </TableRow>
          )}
        </TableHead>
        <TableBody>
          {chamados && clientes && equipamentos ? (
            chamados
              .filter(
                (item) =>
                  item.setor === setor?.id &&
                  item.status != 4 &&
                  item.excluido != 1
              )
              .map((chamado, index) => {
                const { id, status, cliente, posVenda } = chamado;
                return (
                  <TableRow key={`item-map-cliente-${index}`}>
                    <TableCell align='center'>
                      <Button
                        onClick={() => {
                          handleMovimentacao(chamado);
                        }}
                      >
                        {chamado?.pedido ? chamado?.pedido?.pedido : id}
                      </Button>
                    </TableCell>
                    <Status status={status} />
                    <TableCell align='center'>
                      {
                        clientes.find((item) => item.id === cliente)
                          .nomeFantasia
                      }
                    </TableCell>
                    {setor?.categoria === 2 && (
                      <TableCell align='center'>
                        {
                          equipamentos.find(
                            (item) => item.id === chamado.pedido.equipamento
                          )?.titulo
                        }
                      </TableCell>
                    )}
                    <TableCell align='center'>{posVenda.observacao}</TableCell>
                    <ChamadoPrazo
                      setor={setor}
                      chamado={chamado}
                      acao={() => {
                        atrasarChamado(index);
                      }}
                    />
                    <TableCell align='right'>
                      <AcoesChamado chamado={chamado} />
                    </TableCell>
                  </TableRow>
                );
              })
          ) : (
            <ListaSkeleton />
          )}
        </TableBody>
      </Table>
    </>
  );
}

Lista.propTypes = {
  setor: PropTypes.object.isRequired,
};

/**
 * @return {void} lista de clientes cadastrados.
 */
export default function InicialSetorTercerizado() {
  const [usuario] = useAuth();
  const [chamados, setChamados] = useChamados();
  const ApiController = useApi();
  const api = new ApiController();
  const [setores] = useSetores();
  const [setor, setSetor] = useState(
    setores?.find((item) => item.id === JSON.parse(usuario.setor)[0]) ?? null
  );

  useEffect(() => {
    api
      .post('setores/dados', { id: JSON.parse(usuario.setor)[0] })
      .then((r) => {
        if (chamados) {
          const prevChamados = structuredClone(chamados).filter(
            (item) => item.setor !== setor.id
          );

          setChamados([...prevChamados, ...r]);
        } else {
          setChamados(r);
        }
      })
      .catch((e) => {});
  }, [setor]);

  useEffect(() => {
    if (setores) {
      setSetor(
        setores.find((item) => item.id === JSON.parse(usuario.setor)[0])
      );
    }
  }, [setores]);

  return (
    <NovoClienteProvider>
      <NovoChamadoProvider>
        <NovoAtendimentoProvider>
          <AlterarAtendimentoProvider>
            <EntregarChamadoProvider>
              <NovoPedidoProvider>
                <TransferirPedidoProvider>
                  <TransferirAtrasadoProvider>
                    <FinalizarPedidoProvider>
                      <ExcluirPedidoProvider>
                        <MovimentacoesProvider>
                          <NovaAnotacaoChamadoProvider>
                            <Grid item xs={12} md={8} lg={9}>
                              <Grid item xs={12}>
                                <Paper
                                  sx={{
                                    p: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                  }}
                                >
                                  <Lista setor={setor} />
                                </Paper>
                              </Grid>
                            </Grid>
                          </NovaAnotacaoChamadoProvider>
                        </MovimentacoesProvider>
                      </ExcluirPedidoProvider>
                    </FinalizarPedidoProvider>
                  </TransferirAtrasadoProvider>
                </TransferirPedidoProvider>
              </NovoPedidoProvider>
            </EntregarChamadoProvider>
          </AlterarAtendimentoProvider>
        </NovoAtendimentoProvider>
      </NovoChamadoProvider>
    </NovoClienteProvider>
  );
}

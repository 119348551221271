/* eslint-disable no-unused-vars */
import DeleteIcon from '@mui/icons-material/Delete';
import { LoadingButton } from '@mui/lab';
import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import {
  Backdrop,
  Box,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useApi } from '../../Api';
import { useConfiguracoes } from '../../Sistema/Configuracoes';
import { useSetores } from '../../Sistema/Setores';

const url = 'admin/sistema/fluxo';
const titulo = 'Fluxo de produção';
const botaoCadastrarMensagem = 'Alterar';
const botaoCancelarMensagem = 'Cancelar';

export const Ctx = createContext();

/**
 * Hooks manager
 * @param {any} children
 * @param {node} setFluxoAberto
 * @return {children} Hooks.
 */
export function FluxoProducao({ children, setFluxoAberto }) {
  const [enviando, setEnviando] = useState(false);
  const [setores, setSetores] = useSetores();
  const [configuracoes, setConfiguracoes] = useConfiguracoes();
  const [fluxo, setFluxo] = useState(
    JSON.parse(
      configuracoes?.find((item) => item.id === 1).dados ??
        '[]'
    )
  );
  const [novoSetor, setNovoSetor] = useState();
  const ApiController = useApi();
  const api = new ApiController();

  /**
   */
  function handleFechar() {
    setFluxoAberto(false);
  }

  /**
   * @param {object} e
   */
  function handleSetorChange(e) {
    setNovoSetor(e.target.value);
  }

  /**
   */
  function handleNovoFluxo() {
    setFluxo((prev) => [...prev, novoSetor]);
  }

  /**
   * @param {number} index
   */
  function handleRemoverSetor(index) {
    const prevFluxo = structuredClone(fluxo);

    prevFluxo.splice(index, 1);

    setFluxo(prevFluxo);
  }

  /**
   * @param {object} event eventos
   */
  function handleEnviar(event) {
    event.preventDefault();
    setEnviando(true);
    const data = new FormData(event.currentTarget);
    if (fluxo && fluxo.length > 0) {
      data.append('fluxo', JSON.stringify(fluxo));
    }

    api
      .post(url, data)
      .then((r) => {
        const prevConfiguracoes = structuredClone(configuracoes)

        const fluxoEncontrado = prevConfiguracoes.findIndex((item) => Number(item.id) === 1)

        prevConfiguracoes[fluxoEncontrado] = r[0]

        setConfiguracoes(prevConfiguracoes)

        setSetores(r[1])


        toast.success('Fluxo alterado com sucesso', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: 'colored',
          transition: Bounce,
        });

        setEnviando(false);
        handleFechar();
      })
      .catch((e) => {
        console.error(e);
        setEnviando(false);
      });
  }

  return setores ? (
    <Dialog
      open={true}
      onClose={handleFechar}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      fullWidth
      maxWidth={'md'}
    >
      <DialogTitle id='alert-dialog-title'>{titulo}</DialogTitle>
      <DialogContent>
        <Box
          component='form'
          autoComplete='off'
          noValidate
          sx={{ mt: 3 }}
          onSubmit={handleEnviar}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Setor</InputLabel>
                <Select label='Setor' onChange={handleSetorChange}>
                  {setores.filter((item) => item.id != 14 && item.categoria == 2).map((setor, index) => (
                    <MenuItem key={`item-map-setor-${index}`} value={setor.id}>
                      {setor.nome}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} display={'flex'} alignItems={'center'}>
              <Button
                fullWidth
                variant='contained'
                disabled={!novoSetor}
                onClick={handleNovoFluxo}
              >
                Adicionar
              </Button>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Timeline position='alternate'>
                {fluxo.map((item, index) => (
                  <TimelineItem key={`item-map-fluxo-${index}`}>
                    <TimelineOppositeContent color='text.secondary'>
                      {index}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      {setores.find((prev) => prev.id === item).nome}
                      <IconButton
                        aria-label='delete'
                        size='small'
                        onClick={() => {
                          handleRemoverSetor(index);
                        }}
                      >
                        <DeleteIcon fontSize='inherit' />
                      </IconButton>
                    </TimelineContent>
                  </TimelineItem>
                ))}
              </Timeline>
            </Grid>
          </Grid>
          <LoadingButton
            type='submit'
            fullWidth
            variant='contained'
            sx={{ mt: 3 }}
            loading={enviando}
          >
            {botaoCadastrarMensagem}
          </LoadingButton>
          <Button
            type='button'
            fullWidth
            variant='outlined'
            sx={{ mt: 2, mb: 2 }}
            onClick={handleFechar}
            disabled={enviando}
          >
            {botaoCancelarMensagem}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  ) : (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
    >
      <CircularProgress color='inherit' />
    </Backdrop>
  );
}

FluxoProducao.propTypes = {
  setFluxoAberto: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

import PropTypes from 'prop-types';
import React, { createContext, useContext, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';

export const Ctx = createContext();

/**
 * Hooks manager
 * @param {any} children
 * @return {children} Hooks.
 */
export function UsuariosProvider({ children }) {
  const [usuarios, setUsuarios] = useState();

  return (
    <Ctx.Provider value={[usuarios, setUsuarios]}>{children}</Ctx.Provider>
  );
}

UsuariosProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useUsuarios = () => {
  return useContext(Ctx);
};

import { LoadingButton } from '@mui/lab';
import {
    Box,
    DialogActions,
    Grid,
    TextField
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useState } from 'react';
import { Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useApi } from '../../../Api';

export const Ctx = createContext();

/**
 * Hooks manager
 * @param {any} children
 * @return {children} Hooks.
 */
export function NovaAnotacaoChamadoProvider({ children }) {
  const [alvo, setAnotacaoAlvo] = useState();
  const [enviando, setEnviando] = useState(false);
  const ApiController = useApi();
  const api = new ApiController();

  /**
   */
  function handleFechar() {
    setAnotacaoAlvo();
  }

  /**
   * @param {object} event eventos
   */
  function handleEnviar(event) {
    event.preventDefault();
    setEnviando(true);
    const data = new FormData(event.currentTarget);
    data.append('id', alvo.id);

    api
      .post('chamados/venda/nova-anotacao', data)
      .then((r) => {
        toast.success('Anotação adicionada com sucesso!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: 'colored',
          transition: Bounce,
        });

        setEnviando(false);
        handleFechar();
      })
      .catch((e) => {
        console.error(e);
        setEnviando(false);
      });
  }

  return (
    <Ctx.Provider value={[setAnotacaoAlvo]}>
      {alvo && (
        <Dialog
          open={alvo !== undefined}
          onClose={handleFechar}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
          fullWidth
          maxWidth='sm'
        >
          <DialogTitle id='alert-dialog-title'>Adicionar Anotação</DialogTitle>
          <DialogContent>
            <Box
              component='form'
              autoComplete='off'
              noValidate
              sx={{ mt: 3 }}
              onSubmit={handleEnviar}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    required
                    fullWidth
                    multiline
                    rows={4}
                    name='motivo'
                    label='Anotação'
                    id='motivo'
                  />
                </Grid>
              </Grid>
              <DialogActions sx={{ padding: 0, marginTop: 2 }}>
                <Button
                  type='button'
                  variant='outlined'
                  disabled={enviando}
                  onClick={handleFechar}
                >
                  {'Cancelar'}
                </Button>
                <LoadingButton
                  type='submit'
                  variant='contained'
                  loading={enviando}
                >
                  {'Salvar'}
                </LoadingButton>
              </DialogActions>
            </Box>
          </DialogContent>
        </Dialog>
      )}
      {children}
    </Ctx.Provider>
  );
}

NovaAnotacaoChamadoProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useNovaAnotacaoChamado = () => {
  return useContext(Ctx);
};

/* eslint-disable react/prop-types */
import { Button, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useApi } from '../../../../Hooks/Api';
import {
  MovimentacoesProvider,
  useVisualizarMovimentacoes,
} from '../../../../Hooks/Funcionalidades/Historico/Movimentacoes';
import {
  TransferirNovamenteProvider,
  useTransferirNovamente,
} from '../../../../Hooks/Funcionalidades/Setor/Atendimento/TransferirNovamente';
import { useFuncoes } from '../../../../Hooks/FuncoesGerais';
import { useChamados } from '../../../../Hooks/Sistema/Chamados';
import { useClientes } from '../../../../Hooks/Sistema/Clientes';
import { useEquipamentos } from '../../../../Hooks/Sistema/Equipamentos';
import { useSetores } from '../../../../Hooks/Sistema/Setores';

/**
 * @param {function} chamado
 * @return {node}
 */
function AcoesChamado({ chamado }) {
  const [setTransferirNovamenteAlvo] = useTransferirNovamente();

  return (
    <Button
      onClick={() => {
        setTransferirNovamenteAlvo(chamado);
      }}
      variant='contained'
    >
      Transferir para o setor
    </Button>
  );
}

/**
 * @return {object}
 */
function ListaSkeleton() {
  return Array(5)
    .fill()
    .map((item, index) => (
      <TableRow key={`skeleton-usuario-item-${index}`}>
        <TableCell align='center'>
          <Skeleton variant='text' width={'7ch'} />
        </TableCell>
        <TableCell align='center'>
          <Skeleton variant='text' width={'5ch'} />
        </TableCell>
        <TableCell align='center'>
          <Skeleton variant='text' width={'7ch'} />
        </TableCell>
        <TableCell align='center'>
          <Skeleton variant='text' width={'12ch'} />
        </TableCell>
        <TableCell align='center'>
          <Skeleton variant='text' width={'8ch'} />
        </TableCell>
        <TableCell align='center'>
          <Skeleton variant='text' width={'8ch'} />
        </TableCell>
        <TableCell align='center'>
          <Skeleton
            variant='rounded'
            width={'190.36px'}
            height={'36.5px'}
            style={{ float: 'inline-end' }}
          />
        </TableCell>
      </TableRow>
    ));
}

/**
 * @param {object} setor
 * @return {object}
 */
function Lista({ setor }) {
  const [clientes] = useClientes();
  const [equipamentos] = useEquipamentos();
  const [, equipamentosFetch, , clientesFetch] = useFuncoes();
  const [chamados] = useChamados();
  const [setMovimentacaoAlvo] = useVisualizarMovimentacoes();
  const [pesquisa, setPesquisa] = useState();
  const [setores] = useSetores();

  /**
   * @param {object} e
   */
  function handlePesquisa(e) {
    if (e.target.value) {
      setPesquisa(e.target.value);
    } else {
      setPesquisa();
    }
  }

  /**
   * @param {object} ch
   */
  function handleMovimentacao(ch) {
    setMovimentacaoAlvo(ch);
  }

  useEffect(() => {
    clientesFetch();
    equipamentosFetch();
  }, []);

  return (
    <>
      <Stack justifyContent='space-between' alignItems='center' direction='row'>
        <Typography component='h2' variant='h6' color='primary' gutterBottom>
          {setor?.categoria === 1
            ? 'Chamados'
            : setor?.id == 14
              ? 'Cadastrar pedido'
              : 'Pedidos'}
        </Typography>
      </Stack>
      <Table size='small' aria-label='caption table'>
        <TableHead>
          {setor?.id != 14 && (
            <TableRow>
              <TableCell align='center' className='qt-celula-pesquisavel'>
                <input
                  onChange={handlePesquisa}
                  type='text'
                  placeholder='Pedido'
                />
              </TableCell>
              <TableCell align='center'>Setor</TableCell>
              <TableCell align='center'>Cliente</TableCell>
              {setor?.categoria === 2 && (
                <TableCell align='center'>Equipamento</TableCell>
              )}
              <TableCell align='center'></TableCell>
            </TableRow>
          )}
        </TableHead>
        <TableBody>
          {chamados && clientes && equipamentos && setores ? (
            chamados
              .filter(
                (item) =>
                  item.setor === setor?.id &&
                  item.status !== 4 &&
                  item.excluido != 1
              )
              .filter((item) => {
                if (pesquisa) {
                  if (item?.pedido?.pedido) {
                    return String(item.pedido.pedido).includes(
                      String(pesquisa)
                    );
                  } else {
                    return String(item.id).includes(String(pesquisa));
                  }
                } else {
                  return true;
                }
              })
              .map((chamado, index) => {
                const { id, cliente, setor } = chamado;
                return (
                  <TableRow key={`item-map-cliente-${index}`}>
                    <TableCell align='center'>
                      <Button
                        onClick={() => {
                          handleMovimentacao(chamado);
                        }}
                      >
                        {chamado.tipo === 1 ? id : chamado.pedido.pedido}
                      </Button>
                    </TableCell>
                    <TableCell align='center'>
                      {setores.find((item) => item.id === setor).nome}
                    </TableCell>
                    <TableCell align='center'>
                      {
                        clientes.find((item) => item.id === cliente)
                          .nomeFantasia
                      }
                    </TableCell>
                    <TableCell align='right'>
                      <AcoesChamado chamado={chamado} />
                    </TableCell>
                  </TableRow>
                );
              })
          ) : (
            <ListaSkeleton />
          )}
        </TableBody>
      </Table>
    </>
  );
}

Lista.propTypes = {
  setor: PropTypes.object,
};

/**
 * @return {void} lista de clientes cadastrados.
 */
export default function Auditoria() {
  const [chamados, setChamados] = useChamados();
  const ApiController = useApi();
  const api = new ApiController();
  const [setores] = useSetores();
  const [setor, setSetor] = useState(
    setores?.find((item) => item.id === 28) ?? null
  );

  useEffect(() => {
    api
      .post('setores/dados', { id: 28 })
      .then((r) => {
        if (chamados) {
          const prevChamados = structuredClone(chamados).filter(
            (item) => item.setor !== setor.id
          );

          setChamados([...prevChamados, ...r]);
        } else {
          setChamados(r);
        }
      })
      .catch((e) => {});
  }, [setor]);

  useEffect(() => {
    if (setores) {
      setSetor(setores.find((item) => item.id === 28));
    }
  }, [setores]);

  return (
    <TransferirNovamenteProvider>
      <MovimentacoesProvider>
        <Grid item xs={12} md={8} lg={9}>
          <Grid item xs={12}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Lista setor={setor} />
            </Paper>
          </Grid>
        </Grid>
      </MovimentacoesProvider>
    </TransferirNovamenteProvider>
  );
}

import { LoadingButton } from '@mui/lab';
import {
  DialogActions,
  DialogContentText,
  Grid,
  TextField,
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useState } from 'react';
import { Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useApi } from '../../../Api';
import { useChamados } from '../../../Sistema/Chamados';

const url = 'chamados/pedido/excluir';
const titulo = 'Excluir pedido';
const botaoAcaoMensagem = 'Excluir';
const botaoCancelarMensagem = 'Cancelar';

export const Ctx = createContext();

/**
 * Hooks manager
 * @param {any} children
 * @return {children} Hooks.
 */
export function ExcluirPedidoProvider({ children }) {
  const [alvo, setExcluirPedidoAlvo] = useState();
  const [enviando, setEnviando] = useState(false);
  const [motivo, setMotivo] = useState();
  const [chamados, setChamados] = useChamados();
  const ApiController = useApi();
  const api = new ApiController();

  /**
   */
  function handleFechar() {
    setExcluirPedidoAlvo();
    setMotivo();
    setEnviando(false);
  }

  /**
   * @param {object} e
   */
  function handleMotivo(e) {
    setMotivo(e.target.value);
  }

  /**
   * @param {object} prev
   * @param {number} index
   * @return {object}
   */
  function excluirChamado(prev, index) {
    if (index === -1) {
      return perv;
    }

    const prevChamados = structuredClone(prev);

    prevChamados.splice(index, 1);

    return prevChamados;
  }

  /**
   * @param {object} event eventos
   */
  function handleEnviar(event) {
    event.preventDefault();
    setEnviando(true);
    const data = { id: alvo.id, motivo };

    api
      .post(url, data)
      .then((r) => {
        const index = chamados.findIndex((item) => item.id == alvo.id);

        console.log(index)

        setChamados((prev) => excluirChamado(prev, index));

        toast.success('Pedido excluido com sucesso!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: 'colored',
          transition: Bounce,
        });

        setEnviando(false);
        handleFechar();
      })
      .catch((e) => {
        console.error(e);
        setEnviando(false);
      });
  }

  return (
    <Ctx.Provider value={[setExcluirPedidoAlvo]}>
      {alvo && (
        <Dialog
          open={alvo !== undefined}
          onClose={handleFechar}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
          fullWidth
          maxWidth='sm'
        >
          <DialogTitle id='alert-dialog-title'>{titulo}</DialogTitle>
          <DialogContent sx={{ overflow: 'visible' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <DialogContentText color={'error'}>
                  Você tem certeza? Esta ação é irreversível.
                </DialogContentText>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  multiline
                  rows={4}
                  label='Motivo da exclusão'
                  onChange={handleMotivo}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              type='button'
              variant='outlined'
              disabled={enviando}
              onClick={handleFechar}
            >
              {botaoCancelarMensagem}
            </Button>
            <LoadingButton
              color='error'
              type='submit'
              variant='contained'
              loading={enviando}
              onClick={handleEnviar}
            >
              {botaoAcaoMensagem}
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}
      {children}
    </Ctx.Provider>
  );
}

ExcluirPedidoProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useExcluirPedido = () => {
  return useContext(Ctx);
};

import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContentText } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useState } from 'react';
import { Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useApi } from '../../Api';
import { usePerguntas } from '../../Sistema/Perguntas';

const url = 'perguntas/excluir';
const titulo = 'Excluir pergunta?';
const mensagem = 'Esta ação é irreversível';
const botaoAcaoMensagem = 'Excluir pergunta';
const botaoCancelarMensagem = 'Cancelar';

export const Ctx = createContext();

/**
 * Hooks manager
 * @param {any} children
 * @return {children} Hooks.
 */
export function ExcluirPerguntaProvider({ children }) {
  const [alvo, setExcluirPerguntaAlvo] = useState();
  const [enviando, setEnviando] = useState(false);
  const [perguntas, setPerguntas] = usePerguntas();
  const ApiController = useApi();
  const api = new ApiController();

  /**
   */
  function handleFechar() {
    setExcluirPerguntaAlvo();
  }

  /**
   * @param {object} event eventos
   */
  function handleEnviar(event) {
    event.preventDefault();
    setEnviando(true);
    const data = { id: alvo.id };

    api
      .post(url, data)
      .then((r) => {
        const prevPerguntas = structuredClone(perguntas);

        const perguntaEncontrada = perguntas.findIndex(
          (item) => item.id === alvo.id
        );

        prevPerguntas.splice(perguntaEncontrada, 1);

        setPerguntas(prevPerguntas);

        toast.success('A pergunta foi excluída com sucesso!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: 'colored',
          transition: Bounce,
        });

        setEnviando(false);
        handleFechar();
      })
      .catch((e) => {
        console.error(e);
        setEnviando(false);
      });
  }

  return (
    <Ctx.Provider value={[setExcluirPerguntaAlvo]}>
      {alvo && (
        <Dialog
          open={alvo !== undefined}
          onClose={handleFechar}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
          fullWidth
          maxWidth='sm'
        >
          <DialogTitle id='alert-dialog-title'>{titulo}</DialogTitle>
          <DialogContent>
            <DialogContentText>{mensagem}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              type='button'
              variant='outlined'
              disabled={enviando}
              onClick={handleFechar}
            >
              {botaoCancelarMensagem}
            </Button>
            <LoadingButton
              type='submit'
              variant='contained'
              color='error'
              loading={enviando}
              onClick={handleEnviar}
            >
              {botaoAcaoMensagem}
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}
      {children}
    </Ctx.Provider>
  );
}

ExcluirPerguntaProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useExcluirPergunta = () => {
  return useContext(Ctx);
};

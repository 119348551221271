import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useState } from 'react';
import { Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { filtrarPorNomeFantasia } from '../../../../Uteis/Funcoes';
import { useApi } from '../../../Api';
import { useChamados } from '../../../Sistema/Chamados';
import { useClientes } from '../../../Sistema/Clientes';
import { useEquipamentos } from '../../../Sistema/Equipamentos';
import { useNovoCliente } from '../../Cliente/NovoCliente';
import { LoadingButton } from '@mui/lab';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { ptBR } from '@mui/x-date-pickers/locales';

const url = 'chamados/pedido/novo';
const titulo = 'Novo pedido';
const botaoCadastrarMensagem = 'Cadastrar pedido';
const botaoCancelarMensagem = 'Cancelar';

export const Ctx = createContext();

/**
 * Hooks manager
 * @param {any} children
 * @return {children} Hooks.
 */
export function NovoPedidoProvider({ children }) {
  const [aberto, setNovoPedidoAberto] = useState(false);
  const [enviando, setEnviando] = useState(false);

  const [clientes] = useClientes();
  const [equipamentos] = useEquipamentos();
  const [, setChamados] = useChamados();

  const [, setAberto] = useNovoCliente();
  const ApiController = useApi();
  const api = new ApiController();

  /**
   */
  function handleFechar() {
    setNovoPedidoAberto(false);
  }

  /**
   */
  function handleNovoCliente() {
    setAberto(true);
  }

  /**
   * @param {object} event eventos
   */
  function handleEnviar(event) {
    event.preventDefault();
    setEnviando(true);
    const data = new FormData(event.currentTarget);

    api
      .post(url, data)
      .then((r) => {
        setChamados((prev) => [...prev, r]);

        toast.success('Pedido cadastrado com sucesso', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: 'colored',
          transition: Bounce,
        });

        setEnviando(false);
        handleFechar();
      })
      .catch((e) => {
        console.error(e);
        setEnviando(false);
      });
  }

  return (
    <Ctx.Provider value={[setNovoPedidoAberto]}>
      {clientes && equipamentos && aberto && (
        <Dialog
          open={aberto}
          onClose={handleFechar}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
          fullWidth
          maxWidth='sm'
        >
          <DialogTitle id='alert-dialog-title'>{titulo}</DialogTitle>
          <DialogContent>
            <Box
              component='form'
              autoComplete='off'
              noValidate
              sx={{ mt: 3 }}
              onSubmit={handleEnviar}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={10}>
                  <FormControl fullWidth required>
                    <InputLabel>Cliente</InputLabel>
                    <Select label='Cliente' name='cliente' defaultValue={''}>
                      {structuredClone(clientes)
                        .sort(filtrarPorNomeFantasia)
                        .map(({ id, nomeFantasia }, index) => (
                          <MenuItem key={`item-map-nome-${index}`} value={id}>
                            {nomeFantasia}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={2}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Button variant='contained' onClick={handleNovoCliente}>
                    Novo
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    required
                    fullWidth
                    name='pedido'
                    label='Id do pedido'
                    id='pedido'
                    type='number'
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth required>
                    <InputLabel>Equipamento</InputLabel>
                    <Select
                      label='Equipamento'
                      name='equipamento'
                      defaultValue={''}
                    >
                      {equipamentos.map(({ id, titulo }, index) => (
                        <MenuItem
                          key={`item-map-equipamento-${index}`}
                          value={id}
                        >
                          {titulo}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    name='observacao'
                    label='Observação'
                    id='observacao'
                    autoComplete='off'
                    multiline
                    rows={8}
                    inputProps={{ maxLength: 500 }}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText}
                  >
                    <DemoContainer
                      components={['DatePicker']}
                      sx={{ paddingTop: 0, overflow: 'visible' }}
                    >
                      <DatePicker
                        label='Previsão de entrega'
                        name='entregaEm'
                        sx={{ width: '100%' }}
                        format={'D/M/YYYY'}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>
              </Grid>
              <LoadingButton
                type='submit'
                fullWidth
                variant='contained'
                sx={{ mt: 3 }}
                loading={enviando}
              >
                {botaoCadastrarMensagem}
              </LoadingButton>
              <Button
                type='button'
                fullWidth
                variant='outlined'
                sx={{ mt: 2, mb: 2 }}
                onClick={handleFechar}
                disabled={enviando}
              >
                {botaoCancelarMensagem}
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      )}
      {children}
    </Ctx.Provider>
  );
}

NovoPedidoProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useNovoPedido = () => {
  return useContext(Ctx);
};

import { LoadingButton } from '@mui/lab';
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useState } from 'react';
import { Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useApi } from '../../Api';
import { useSetores } from '../../Sistema/Setores';
import { stringParaUrl } from '../../../Uteis/Funcoes';
import { setoresCategoriasLista } from '../../../Uteis/Parametros';

export const Ctx = createContext();

const url = 'admin/setores/novo';
const titulo = 'Novo setor';
const botaoCadastrar = 'Cadastrar setor';
const botaoCancelar = 'Cancelar';

/**
 * Hooks manager
 * @param {any} children
 * @return {children} Hooks.
 */
export function NovoSetorProvider({ children }) {
  const [, setSetores] = useSetores();
  const [aberto, setAberto] = useState(false);
  const [enviando, setEnviando] = useState(false);
  const [link, setLink] = useState();
  const ApiController = useApi();
  const api = new ApiController();

  /**
   */
  function handleFechar() {
    setAberto(false);
  }

  /**
   * @param {object} e
   */
  function handleNovoLink(e) {
    setLink(stringParaUrl(e.target.value));
  }

  /**
   * @param {object} event eventos
   */
  function handleEnviar(event) {
    event.preventDefault();
    setEnviando(true);
    const data = new FormData(event.currentTarget);
    api
      .post(url, data)
      .then((r) => {
        setSetores((prev) => [...prev, r]);

        toast.success('Setor cadastrado com sucesso', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: 'colored',
          transition: Bounce,
        });

        setEnviando(false);
        handleFechar();
      })
      .catch((e) => {
        console.error(e);
        setEnviando(false);
      });
  }

  return (
    <Ctx.Provider value={[aberto, setAberto]}>
      <Dialog
        open={aberto}
        onClose={handleFechar}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        fullWidth
        maxWidth='sm'
      >
        <DialogTitle id='alert-dialog-title'>{titulo}</DialogTitle>
        <DialogContent>
          <Box
            component='form'
            autoComplete='off'
            noValidate
            sx={{ mt: 3 }}
            onSubmit={handleEnviar}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  name='nome'
                  label='Nome'
                  id='nome'
                  autoComplete='off'
                  onChange={handleNovoLink}
                />
              </Grid>
              <input hidden name='link' value={link} />
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Categoria</InputLabel>
                  <Select label='Categora' name='categoria' required>
                    {setoresCategoriasLista.map(({ id, valor }, index) => (
                      <MenuItem key={`item-map-categoria-${index}`} value={id}>
                        {valor}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <LoadingButton
              type='submit'
              fullWidth
              variant='contained'
              sx={{ mt: 3 }}
              loading={enviando}
            >
              {botaoCadastrar}
            </LoadingButton>
            <Button
              type='button'
              fullWidth
              variant='outlined'
              sx={{ mt: 2, mb: 2 }}
              onClick={handleFechar}
              disabled={enviando}
            >
              {botaoCancelar}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      {children}
    </Ctx.Provider>
  );
}

NovoSetorProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useNovoSetor = () => {
  return useContext(Ctx);
};

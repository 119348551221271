import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import { Button, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useAuth } from '../../Hooks/Auth';
import { useAlterarAtendimento } from '../../Hooks/Funcionalidades/Setor/Atendimento/Alterar';
import { useEntregarChamado } from '../../Hooks/Funcionalidades/Setor/Chamado/Entregar';
import { useExcluirPedido } from '../../Hooks/Funcionalidades/Setor/Pedido/Excluir';
import { useTransferirPedido } from '../../Hooks/Funcionalidades/Setor/Pedido/Transferir';
import { useTransferirAtrasado } from '../../Hooks/Funcionalidades/Setor/Pedido/TransferirAtrasado';
import { useEquipamentos } from '../../Hooks/Sistema/Equipamentos';
/**
 * @param {object} chamado
 * @return {object}
 */
function AcoesChamado({ chamado }) {
  const [usuario] = useAuth();
  const [setChamadoAlvo] = useAlterarAtendimento();
  const [setEntregarAlvo] = useEntregarChamado();
  const [setTransferirPedidoAlvo] = useTransferirPedido();
  const [setTransferirAtrasadoAlvo] = useTransferirAtrasado();
  /* const [setAnotacaoAlvo] = useNovaAnotacaoChamado(); */
  const [setExcluirPedidoAlvo] = useExcluirPedido();
  const [equipamentos] = useEquipamentos();

  const movimentacao = new Date(chamado.pedido.movimentadoEm);

  const equipamento = equipamentos.find(
    (item) => item.id == chamado.pedido.equipamento
  );

  const prazoSetor = JSON.parse(equipamento.prazos).find(
    (item) => item.id == chamado.setor
  ).prazo;

  const entrega = new Date(
    movimentacao.getTime() + prazoSetor * 60 * 60 * 1000
  );

  const agora = new Date();

  // Pedidos

  /**
   */
  function handleTransferirPedido() {
    setTransferirPedidoAlvo(chamado);
  }

  /**
   */
  function handleTransferirAtrasado() {
    setTransferirAtrasadoAlvo(chamado);
  }

  /**
   */
  function handleExcluirPedido() {
    setExcluirPedidoAlvo(chamado);
  }

  // Pós venda

  /**
   */
  function handleRetornarContato() {
    setChamadoAlvo(chamado);
  }

  /**
   */
  /* function handleNovaAnotacao() {
    setAnotacaoAlvo(chamado);
  } */

  /**
   */
  function handleEntregarChamado() {
    setEntregarAlvo(chamado);
  }

  if (chamado.tipo === 1) {
    if (chamado.status === 1) {
      return (
        <Button variant='contained' onClick={handleRetornarContato}>
          Retornar contato
        </Button>
      );
    } else if (chamado.status === 3) {
      return (
        <Button variant='contained' onClick={handleEntregarChamado}>
          Finalizar
        </Button>
      );
    }
  } else {
    if (chamado.status === 3) {
      return (
        <>
          {JSON.parse(usuario.setor)[0] === 1 && (
            <Tooltip title='Excluir'>
              <Button
                variant='contained'
                color='error'
                onClick={handleExcluirPedido}
                sx={{ marginRight: 1 }}
              >
                <DeleteIcon />
              </Button>
            </Tooltip>
          )}
          {/* {chamado.atraso || entrega - agora <= 0 && (
            <Tooltip title='Adicionar anotação'>
              <Button
                variant='contained'
                color='secondary'
                onClick={handleNovaAnotacao}
                sx={{ marginRight: 1 }}
              >
                <PostAddIcon />
              </Button>
            </Tooltip>
          )} */}
          <Tooltip title='Transferir'>
            <Button
              variant='contained'
              onClick={
                chamado.atraso || entrega - agora <= 0
                  ? handleTransferirAtrasado
                  : handleTransferirPedido
              }
            >
              <SendIcon />
            </Button>
          </Tooltip>
        </>
      );
    }
  }
}

AcoesChamado.propTypes = {
  chamado: PropTypes.object.isRequired,
};

export default AcoesChamado;

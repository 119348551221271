import { LoadingButton } from '@mui/lab';
import {
  Box,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { useApi } from '../../Api';
import { useSetores } from '../../Sistema/Setores';
import { useUsuarios } from '../../Usuarios';
import { filtrarPorNome } from '../../../Uteis/Funcoes';

export const Ctx = createContext();

/**
 * Hooks manager
 * @param {any} children
 * @return {children} Hooks.
 */
export function AlterarUsuarioProvider({ children }) {
  const [alvo, setUsuarioAlterar] = useState();
  const [enviando, setEnviando] = useState(false);
  const [setores] = useSetores();
  const [usuarios, setUsuarios] = useUsuarios();
  const [setoresArr, setSetoresArr] = useState([]);
  const ApiController = useApi();
  const api = new ApiController();

  /**
   * @param {object} event
   */
  function handleSetorChange(event) {
    setSetoresArr(event.target.value);
  }
  /**
   */
  function handleFechar() {
    setUsuarioAlterar(false);
  }

  /**
   * @param {object} event eventos
   */
  function handleEnviar(event) {
    event.preventDefault();
    setEnviando(true);
    const data = new FormData(event.currentTarget);
    data.append('id', alvo.id);

    if (setoresArr.length > 0) {
      data.append('setor', JSON.stringify(setoresArr));
    }

    if (alvo.setor == 1) {
      data.append('setor', Number(alvo.setor));
    }

    api
      .post('admin/alterar-usuario', data)
      .then((r) => {
        const usuarioFound = usuarios.findIndex((item) => item.id === r.id);
        const prevUsuarios = structuredClone(usuarios);

        prevUsuarios[usuarioFound] = r;

        setUsuarios(prevUsuarios);

        handleFechar();
        setEnviando(false);
      })
      .catch((e) => {
        console.error(e);
        setEnviando(false);
      });
  }

  useEffect(() => {
    if (alvo) {
      setSetoresArr(JSON.parse(alvo.setor));
    } else {
      setSetoresArr([])
    }
  }, [alvo]);

  return (
    <Ctx.Provider value={[setUsuarioAlterar]}>
      {setores && alvo && (
        <Dialog
          open={alvo}
          onClose={handleFechar}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>
            {'Alterar usuário'}
          </DialogTitle>
          <DialogContent>
            <Box
              component='form'
              autoComplete='off'
              noValidate
              sx={{ mt: 3 }}
              onSubmit={handleEnviar}
            >
              <Grid container spacing={2}>
                {alvo.setor != 1 && (
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel>Setor</InputLabel>
                      <Select
                        multiple
                        value={setoresArr}
                        onChange={handleSetorChange}
                        label='Setor'
                        renderValue={(setor) => {
                          const retorno = [];
                          setor.forEach((item) => {
                            retorno.push(
                              setores.find((setorItem) => setorItem.id == item)
                                .nome
                            );
                          });

                          return retorno.join(', ');
                        }}
                      >
                        {structuredClone(setores).sort(filtrarPorNome).map((setor, index) => (
                          <MenuItem
                            key={`item-map-setor-${index}`}
                            value={setor.id}
                          >
                            <Checkbox
                              checked={setoresArr.indexOf(setor.id) > -1}
                            />
                            <ListItemText primary={setor.nome} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                <Grid item xs={12} sm={alvo.setor == 1 ? 12 : 6}>
                  <FormControl sx={{ width: '100%' }} variant='outlined'>
                    <InputLabel htmlFor='nome' required>
                      Nome completo
                    </InputLabel>
                    <OutlinedInput
                      id='nome'
                      name='nome'
                      type={'text'}
                      label='Nome completo *'
                      defaultValue={alvo.nome}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl sx={{ width: '100%' }} variant='outlined'>
                    <InputLabel htmlFor='nome' required>
                      Nome de usuário
                    </InputLabel>
                    <OutlinedInput
                      id='usuario'
                      name='usuario'
                      type={'text'}
                      label='Nome de usuário *'
                      defaultValue={alvo.usuario}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    id='email'
                    label='email'
                    name='email'
                    autoComplete='off'
                    type='email'
                    defaultValue={alvo.email}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    name='senha'
                    label='Senha'
                    type='password'
                    id='senha'
                    autoComplete='off'
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    name='confirmarSenha'
                    label='Confirme a senha'
                    type='password'
                    id='confirmarSenha'
                    autoComplete='off'
                  />
                </Grid>
              </Grid>
              <LoadingButton
                type='submit'
                fullWidth
                variant='contained'
                sx={{ mt: 3 }}
                loading={enviando}
              >
                {'Alterar usuário'}
              </LoadingButton>
              <Button
                type='button'
                fullWidth
                variant='outlined'
                sx={{ mt: 2, mb: 2 }}
                onClick={handleFechar}
                disabled={enviando}
              >
                {'Cancelar'}
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      )}
      {children}
    </Ctx.Provider>
  );
}

AlterarUsuarioProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useAlterarUsuario = () => {
  return useContext(Ctx);
};

import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';

export const Ctx = createContext();

/**
 * Hooks manager
 * @param {any} children
 * @return {children} Hooks.
 */
export function ClienteContatoProvider({ children }) {
  const [cliente, setCliente] = useState();

  /**
   */
  function handleFechar() {
    setCliente();
  }

  return (
    <Ctx.Provider value={[cliente, setCliente]}>
      {cliente && (
        <Dialog
          open={cliente !== undefined}
          onClose={handleFechar}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
          maxWidth={'md'}
          fullWidth={false}
        >
          <DialogTitle id='alert-dialog-title'>
            Contatos de {cliente.nomeFantasia}
          </DialogTitle>
          <DialogContent>
            <Box autoComplete='off' sx={{ mt: 3, overflow: 'hidden' }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <TableContainer
                    style={{
                      borderWidth: 1,
                      borderColor: 'rgba(0, 0, 0, 0.23)',
                      borderStyle: 'solid',
                      borderRadius: '4px',
                    }}
                  >
                    <Table aria-label='simple table'>
                      <TableHead>
                        <TableRow>
                          <TableCell align='center'>Nome</TableCell>
                          <TableCell align='center'>Função</TableCell>
                          <TableCell align='center'>Telefone</TableCell>
                          <TableCell align='center'>Email</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {cliente.contato1Nome || cliente.contato2Nome ? (
                          <>
                            {cliente.contato1Nome && (
                              <TableRow
                                sx={{
                                  '&:last-child td, &:last-child th': {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell component='th' scope='row'>
                                  {cliente.contato1Nome}
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                  {cliente.contato1Funcao}
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                  {cliente.contato1Telefone}
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                  {cliente.contato1Email}
                                </TableCell>
                              </TableRow>
                            )}
                            {cliente.contato2Nome && (
                              <TableRow
                                sx={{
                                  '&:last-child td, &:last-child th': {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell component='th' scope='row'>
                                  {cliente.contato2Nome}
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                  {cliente.contato2Funcao}
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                  {cliente.contato2Telefone}
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                  {cliente.contato2Email}
                                </TableCell>
                              </TableRow>
                            )}
                          </>
                        ) : (
                          <TableRow>
                            <TableCell component='th' scope='row' colSpan={4}>
                              Nenhum contato informado
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
              <Button
                type='button'
                fullWidth
                variant='outlined'
                sx={{ mt: 2, mb: 2 }}
                onClick={handleFechar}
              >
                {'Fechar'}
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      )}
      {children}
    </Ctx.Provider>
  );
}

ClienteContatoProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useClienteContato = () => {
  return useContext(Ctx);
};

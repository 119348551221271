import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import { Chip, TableCell } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useEquipamentos } from '../../Hooks/Sistema/Equipamentos';
import { useDiferencaEntreDatas } from '../../Uteis/Funcoes';

/**
 * @param {Date} data
 * @param {function} acao
 * @param {number} intervalo
 * @return {object}
 */
function Contador({ data, acao, intervalo }) {
  const [dataHoraAtual, setDataHoraAtual] = useState(new Date());

  const { completado, horas, minutos, segundos, timestamp } =
    useDiferencaEntreDatas(dataHoraAtual, data);

  const classeAtual =
    timestamp <= intervalo * 0.09 ? 'qt-prazo-hoje' : 'qt-prazo-dentro';

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDataHoraAtual(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    acao();
  }, [completado]);

  return (
    <TableCell align='center'>
      <Chip
        icon={completado ? <AccessAlarmsIcon color='#ffffff' /> : <></>}
        className={completado ? 'qt-prazo-atrasado' : classeAtual}
        label={`${horas}:${minutos}:${segundos}`}
      />
    </TableCell>
  );
}

Contador.propTypes = {
  data: PropTypes.instanceOf(Date).isRequired,
  acao: PropTypes.func.isRequired,
  intervalo: PropTypes.number.isRequired,
};

/**
 * @param {object} setor
 * @param {object} chamado
 * @param {object} acao
 * @return {object}
 */
function ChamadoPrazo({ setor, chamado, acao }) {
  const [equipamentos] = useEquipamentos();

  if (setor.id === chamado.setor) {
    if (chamado.tipo === 1) {
      if (chamado.status === 1 || chamado.status === 2) {
        return (
          <Contador data={new Date(chamado.posVenda.entregaEm)} acao={acao} />
        );
      } else if (chamado.status === 3) {
        return (
          <TableCell align='center' className='qt-prazo-pendente'>
            Pendente
          </TableCell>
        );
      } else if (chamado.status === 4) {
        return <TableCell align='center'>--</TableCell>;
      }
    } else {
      const movimentacao = new Date(chamado.pedido.movimentadoEm);

      const equipamento = equipamentos.find(
        (item) => item.id == chamado.pedido.equipamento
      );

      const prazoSetor = JSON.parse(equipamento.prazos).find(
        (item) => item.id == chamado.setor
      ).prazo;

      const entrega = new Date(
        movimentacao.getTime() + prazoSetor * 60 * 60 * 1000
      );

      return (
        <Contador
          data={entrega}
          acao={acao}
          intervalo={prazoSetor * 60 * 60 * 1000}
        />
      );
    }
  } else {
    return <TableCell align='center'>--</TableCell>;
  }
}

ChamadoPrazo.propTypes = {
  chamado: PropTypes.object.isRequired,
  setor: PropTypes.object.isRequired,
  acao: PropTypes.func.isRequired,
};

export default ChamadoPrazo;

import { Button, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import React from 'react';
import { AlterarUsuarioProvider } from '../../../../Hooks/Funcionalidades/Usuario/EditarUsuario';
import {
  NovoUsuarioProvider,
  useNovoUsuario,
} from '../../../../Hooks/Funcionalidades/Usuario/Novo';
import { useSetores } from '../../../../Hooks/Sistema/Setores';
import { useUsuarios } from '../../../../Hooks/Usuarios';
import Acoes from './Acoes/Acoes';
import { formatarDataHora } from '../../../../Uteis/Funcoes';

/**
 * @return {object}
 */
function ListaSkeleton() {
  return Array(5)
    .fill()
    .map((item, index) => (
      <TableRow key={`skeleton-usuario-item-${index}`}>
        <TableCell align='center'>
          <Skeleton variant='text' width={'5ch'} />
        </TableCell>
        <TableCell align='center'>
          <Skeleton variant='text' width={'5ch'} />
        </TableCell>
        <TableCell align='center'>
          <Skeleton variant='text' width={'7ch'} />
        </TableCell>
        <TableCell align='center'>
          <Skeleton variant='text' width={'12ch'} />
        </TableCell>
        <TableCell align='center'>
          <Skeleton variant='text' width={'8ch'} />
        </TableCell>
        <TableCell align='center'>
          <Skeleton
            variant='rounded'
            width={'102.36px'}
            height={'36.5px'}
            style={{ float: 'inline-end' }}
          />
        </TableCell>
      </TableRow>
    ));
}

/**
 * @param {array} usuarios
 * @return {object}
 */
function Lista({ usuarios }) {
  const [, setAberto] = useNovoUsuario();
  const [setores] = useSetores();

  /**
   * @param {string} setorLista
   * @return {string}
   */
  function retornarNomeSetor(setorLista) {
    const setoresRetornar = [];

    JSON.parse(setorLista).forEach((item) => {
      const setor = setores.find((itemSetor) => itemSetor.id == item);

      setoresRetornar.push(setor.nome);
    });

    return setoresRetornar.join(', ');
  }

  return (
    <React.Fragment>
      <Stack justifyContent='space-between' alignItems='center' direction='row'>
        <Typography component='h2' variant='h6' color='primary' gutterBottom>
          Usuários
        </Typography>
        {setores ? (
          <Button
            variant='outlined'
            onClick={() => {
              setAberto(true);
            }}
          >
            Novo usuário
          </Button>
        ) : (
          <Skeleton variant='rounded' width='136.61px' height='36.5px' />
        )}
      </Stack>
      <Table size='small' aria-label='caption table'>
        <TableHead>
          <TableRow>
            <TableCell align='center'>Id</TableCell>
            <TableCell align='center'>Setor</TableCell>
            <TableCell align='center'>Nome de usuario</TableCell>
            <TableCell align='center'>Nome</TableCell>
            <TableCell align='center'>Email</TableCell>
            <TableCell align='center'>Criado em</TableCell>
            <TableCell align='center'></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(setores && usuarios) ? (
            usuarios
              .filter((item) => item.id !== 1)
              .map((usuario, index) => (
                <TableRow key={`usuario-item-map-${index}`}>
                  <TableCell align='center'>{usuario.id}</TableCell>
                  <TableCell align='center'>
                    {setores ? (
                      JSON.parse(usuario.setor)[0] === 1 ? (
                        'Administração'
                      ) : (
                        retornarNomeSetor(usuario.setor)
                      )
                    ) : (
                      <Skeleton variant='text' width={'5ch'} />
                    )}
                  </TableCell>
                  <TableCell align='center'>{usuario.usuario}</TableCell>
                  <TableCell align='center'>{usuario.nome}</TableCell>
                  <TableCell align='center'>{usuario.email}</TableCell>
                  <TableCell align='center'>
                    {formatarDataHora(usuario.criadoEm)}
                  </TableCell>
                  <TableCell className='text-end'>
                    <Acoes usuario={usuario} />
                  </TableCell>
                </TableRow>
              ))
          ) : (
            <ListaSkeleton />
          )}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}

Lista.propTypes = {
  usuarios: PropTypes.array,
};

/**
 * @return {void} lista de clientes cadastrados.
 */
export default function Usuarios() {
  const [usuarios] = useUsuarios();

  return (
    <NovoUsuarioProvider>
      <AlterarUsuarioProvider>
        <Grid item xs={12} md={8} lg={9}>
          <Grid item xs={12}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
              <Lista usuarios={usuarios} />
            </Paper>
          </Grid>
        </Grid>
      </AlterarUsuarioProvider>
    </NovoUsuarioProvider>
  );
}

import { LoadingButton } from '@mui/lab';
import {
  Box,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { IMaskInput } from 'react-imask';
import { Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useApi } from '../../Api';
import { useClientes } from '../../Sistema/Clientes';

export const Ctx = createContext();

const CampoCpf = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask='000.000.000-00'
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

const CampoCnpj = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask='00.000.000/0000-00'
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

const CampoCep = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask='00000-000'
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

CampoCpf.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

CampoCnpj.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

CampoCep.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

/**
 * Hooks manager
 * @param {any} children
 * @return {children} Hooks.
 */
export function NovoClienteProvider({ children }) {
  const [aberto, setAberto] = useState(false);
  const [enviando, setEnviando] = useState(false);
  const [, setClientes] = useClientes();
  const [pessoa, setPessoa] = useState(1);
  const ApiController = useApi();
  const api = new ApiController();

  /**
   * @param {object} e
   */
  function handlePessoa(e) {
    setPessoa(e.target.value);
  }

  /**
   * @param {object} event eventos
   */
  function handleEnviar(event) {
    event.preventDefault();
    setEnviando(true);
    const data = new FormData(event.currentTarget);

    api
      .post('clientes/novo', data)
      .then((r) => {
        const resp = r;

        resp.dados = JSON.stringify(r.dados);

        setClientes((prev) => [...prev, resp]);
        setEnviando(false);
        handleFechar();
        toast.success('Cliente cadastrado com sucesso!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: 'colored',
          transition: Bounce,
        });
      })
      .catch((e) => {
        console.error(e);
        setEnviando(false);
      });
  }

  /**
   */
  function handleFechar() {
    setAberto(false);
  }

  useEffect(() => {}, []);

  return (
    <Ctx.Provider value={[aberto, setAberto]}>
      <Dialog
        open={aberto}
        onClose={handleFechar}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        maxWidth={'md'}
        fullWidth={false}
      >
        <DialogTitle id='alert-dialog-title'>{'Novo cliente'}</DialogTitle>
        <DialogContent>
          <Box
            component='form'
            autoComplete='off'
            sx={{ mt: 3, overflow: 'hidden' }}
            onSubmit={handleEnviar}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <Divider />
                <Typography variant='subtitle1' gutterBottom>
                  Cadastro
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <InputLabel>Pessoa</InputLabel>
                  <Select
                    label='Plano'
                    name='pessoa'
                    onChange={handlePessoa}
                    defaultValue={pessoa}
                  >
                    <MenuItem value={1}>Jurídica</MenuItem>
                    <MenuItem value={2}>Física</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {pessoa === 1 ? (
                <>
                  <Grid item xs={12} sm={3}>
                    <FormControl sx={{ width: '100%' }} variant='outlined'>
                      <InputLabel htmlFor='identificacao'>CNPJ *</InputLabel>
                      <OutlinedInput
                        name='identificacao'
                        inputComponent={CampoCnpj}
                        label='Cnpj *'
                        placeholder='12.345.678/0001-90'
                        required
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      id='nomeFantasia'
                      label='Nome fantasia'
                      name='nomeFantasia'
                      type='text'
                      autoComplete='off'
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      name='ie'
                      label='Inscrição estadual'
                      id='ie'
                      type='ie'
                      autoComplete='off'
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      id='razaoSocial'
                      label='Razão social'
                      name='razaoSocial'
                      type='text'
                      autoComplete='off'
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12} sm={3}>
                    <FormControl sx={{ width: '100%' }} variant='outlined'>
                      <InputLabel htmlFor='identificacao'>CPF *</InputLabel>
                      <OutlinedInput
                        name='identificacao'
                        inputComponent={CampoCpf}
                        label='Cnpj *'
                        placeholder='123.456.789-00'
                        required
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      id='nomeFantasia'
                      label='Nome Completo'
                      name='nomeFantasia'
                      type='text'
                      autoComplete='off'
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12} sm={12}>
                <FormControl fullWidth>
                  <InputLabel id='demo-simple-select-label'>Plano</InputLabel>
                  <Select label='Plano' name='usuario'>
                    <MenuItem value='free'>Free</MenuItem>
                    <MenuItem value='plus'>Plus</MenuItem>
                    <MenuItem value='master'>Master</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Divider />
                <Typography variant='subtitle1' gutterBottom>
                  Contato
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TableContainer
                  style={{
                    borderWidth: 1,
                    borderColor: 'rgba(0, 0, 0, 0.23)',
                    borderStyle: 'solid',
                    borderRadius: '4px',
                  }}
                >
                  <Table aria-label='simple table'>
                    <TableHead>
                      <TableRow>
                        <TableCell align='center'>Nome</TableCell>
                        <TableCell align='center'>Função</TableCell>
                        <TableCell align='center'>Telefone</TableCell>
                        <TableCell align='center'>Email</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow
                        sx={{
                          '&:last-child td, &:last-child th': {
                            border: 0,
                          },
                        }}
                      >
                        <TableCell component='th' scope='row'>
                          <TextField
                            id='outlined-size-small'
                            size='small'
                            name='contato1Nome'
                            required
                          />
                        </TableCell>
                        <TableCell component='th' scope='row'>
                          <TextField
                            id='outlined-size-small'
                            size='small'
                            name='contato1Funcao'
                            required
                          />
                        </TableCell>
                        <TableCell component='th' scope='row'>
                          <TextField
                            id='outlined-size-small'
                            size='small'
                            name='contato1Telefone'
                            required
                          />
                        </TableCell>
                        <TableCell component='th' scope='row'>
                          <TextField
                            id='outlined-size-small'
                            size='small'
                            name='contato1Email'
                            required
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          '&:last-child td, &:last-child th': {
                            border: 0,
                          },
                        }}
                      >
                        <TableCell component='th' scope='row'>
                          <TextField
                            id='outlined-size-small'
                            size='small'
                            name='contato2Nome'
                          />
                        </TableCell>
                        <TableCell component='th' scope='row'>
                          <TextField
                            id='outlined-size-small'
                            size='small'
                            name='contato2Funcao'
                          />
                        </TableCell>
                        <TableCell component='th' scope='row'>
                          <TextField
                            id='outlined-size-small'
                            size='small'
                            name='contato2Telefone'
                          />
                        </TableCell>
                        <TableCell component='th' scope='row'>
                          <TextField
                            id='outlined-size-small'
                            size='small'
                            name='contato2Email'
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Divider />
                <Typography variant='subtitle1' gutterBottom>
                  Endereço
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl sx={{ width: '100%' }} variant='outlined'>
                  <InputLabel htmlFor='cpf'>Cep *</InputLabel>
                  <OutlinedInput
                    name='cep'
                    id='cep'
                    inputComponent={CampoCep}
                    label='Cep *'
                    placeholder='12345-678'
                    required
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  name='logradouro'
                  label='Logradouro'
                  id='logradouro'
                  type='logradouro'
                  autoComplete='off'
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  fullWidth
                  name='numero'
                  label='Numero'
                  id='numero'
                  autoComplete='off'
                  type='number'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  name='complemento'
                  label='Complemento'
                  id='complemento'
                  autoComplete='off'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  name='bairro'
                  label='Bairro'
                  id='bairro'
                  autoComplete='off'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  name='cidade'
                  label='Cidade'
                  id='cidade'
                  autoComplete='off'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Estado *</InputLabel>
                  <Select label='Estado *' name='estado' required>
                    <MenuItem value={'AC'}>AC (Acre)</MenuItem>
                    <MenuItem value={'AL'}>AL (Alagoas)</MenuItem>
                    <MenuItem value={'AP'}>AP (Amapá)</MenuItem>
                    <MenuItem value={'AM'}>AM (Amazonas)</MenuItem>
                    <MenuItem value={'BA'}>BA (Bahia)</MenuItem>
                    <MenuItem value={'CE'}>CE (Ceará)</MenuItem>
                    <MenuItem value={'DF'}>DF (Distrito Federal)</MenuItem>
                    <MenuItem value={'ES'}>ES (Espírito Santo)</MenuItem>
                    <MenuItem value={'GO'}>GO (Goiás)</MenuItem>
                    <MenuItem value={'MA'}>MA (Maranhão)</MenuItem>
                    <MenuItem value={'MT'}>MT (Mato Grosso)</MenuItem>
                    <MenuItem value={'MS'}>MS (Mato Grosso do Sul)</MenuItem>
                    <MenuItem value={'MG'}>MG (Minas Gerais)</MenuItem>
                    <MenuItem value={'PA'}>PA (Pará)</MenuItem>
                    <MenuItem value={'PB'}>PB (Paraíba)</MenuItem>
                    <MenuItem value={'PR'}>PR (Paraná)</MenuItem>
                    <MenuItem value={'PE'}>PE (Pernambuco)</MenuItem>
                    <MenuItem value={'PI'}>PI (Piauí)</MenuItem>
                    <MenuItem value={'RJ'}>RJ (Rio de Janeiro)</MenuItem>
                    <MenuItem value={'RN'}>RN (Rio Grande do Norte)</MenuItem>
                    <MenuItem value={'RS'}>RS (Rio Grande do Sul)</MenuItem>
                    <MenuItem value={'RO'}>RO (Rondônia)</MenuItem>
                    <MenuItem value={'RR'}>RR (Roraima)</MenuItem>
                    <MenuItem value={'SC'}>SC (Santa Catarina)</MenuItem>
                    <MenuItem value={'SP'}>SP (São Paulo)</MenuItem>
                    <MenuItem value={'SE'}>SE (Sergipe)</MenuItem>
                    <MenuItem value={'TO'}>TO (Tocantins)</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <LoadingButton
              type='submit'
              fullWidth
              variant='contained'
              sx={{ mt: 3 }}
              loading={enviando}
            >
              {'Cadastrar cliente'}
            </LoadingButton>
            <Button
              type='button'
              fullWidth
              variant='outlined'
              sx={{ mt: 2, mb: 2 }}
              disabled={enviando}
              onClick={handleFechar}
            >
              {'Cancelar'}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      {children}
    </Ctx.Provider>
  );
}

NovoClienteProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useNovoCliente = () => {
  return useContext(Ctx);
};

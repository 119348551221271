import { Button, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import React from 'react';
import { AlterarSetorProvider } from '../../../../Hooks/Funcionalidades/Setor/Alterar';
import { ExcluirSetorProvider } from '../../../../Hooks/Funcionalidades/Setor/Excluir';
import {
  NovoSetorProvider,
  useNovoSetor,
} from '../../../../Hooks/Funcionalidades/Setor/Novo';
import { useFuncoes } from '../../../../Hooks/FuncoesGerais';
import { useSetores } from '../../../../Hooks/Sistema/Setores';
import { formatarDataHora } from '../../../../Uteis/Funcoes';
import Acoes from './Acoes/Acoes';
import { setoresCategoriasLista } from '../../../../Uteis/Parametros';

/**
 * @return {object}
 */
function ListaSkeleton() {
  return Array(5)
    .fill()
    .map((item, index) => (
      <TableRow key={`skeleton-usuario-item-${index}`}>
        <TableCell align='center'>
          <Skeleton variant='text' width={'5ch'} />
        </TableCell>
        <TableCell align='center'>
          <Skeleton variant='text' width={'7ch'} />
        </TableCell>
        <TableCell align='center'>
          <Skeleton variant='text' width={'8ch'} />
        </TableCell>
        <TableCell align='center'>
          <Skeleton
            variant='rounded'
            width={'102.36px'}
            height={'36.5px'}
            style={{ float: 'inline-end' }}
          />
        </TableCell>
      </TableRow>
    ));
}

/**
 * @param {array} setores
 * @param {func} tentarNovamente
 * @return {object}
 */
function Lista({ setores, tentarNovamente }) {
  const [, setAberto] = useNovoSetor();

  return (
    <React.Fragment>
      <Stack justifyContent='space-between' alignItems='center' direction='row'>
        <Typography component='h2' variant='h6' color='primary' gutterBottom>
          Setores
        </Typography>
        <Stack direction={'row'} gap={2}>
          <Button
            variant='outlined'
            onClick={() => {
              setAberto(true);
            }}
          >
            Novo setor
          </Button>
        </Stack>
      </Stack>
      <Table size='small' aria-label='caption table'>
        <TableHead>
          <TableRow>
            <TableCell align='center'>Id</TableCell>
            <TableCell align='center'>Categoria</TableCell>
            <TableCell align='center'>Nome</TableCell>
            <TableCell align='center'>Link</TableCell>
            <TableCell align='center'>Criado em</TableCell>
            <TableCell align='center'></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {setores ? (
            setores.map(({ id, categoria, nome, link, criadoEm }, index) => (
              <TableRow key={`usuario-item-map-${index}`}>
                <TableCell align='center'>{id}</TableCell>
                <TableCell align='center'>
                  {
                    setoresCategoriasLista.find((item) => item.id == categoria)
                      .valor
                  }
                </TableCell>
                <TableCell align='center'>{nome}</TableCell>
                <TableCell align='center'>{link}</TableCell>
                <TableCell align='center'>
                  {formatarDataHora(criadoEm)}
                </TableCell>
                <TableCell className='text-end'>
                  <Acoes id={id} />
                </TableCell>
              </TableRow>
            ))
          ) : setores === null ? (
            <TableRow>
              <TableCell
                className='text-center'
                colSpan={4}
                sx={{ height: '247.5px' }}
              >
                <Typography gutterBottom>
                  Houve um erro ao carregar a listagem!
                </Typography>
                <Button onClick={tentarNovamente}>Tentar novamente</Button>
              </TableCell>
            </TableRow>
          ) : (
            <ListaSkeleton />
          )}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}

Lista.propTypes = {
  setores: PropTypes.array,
  tentarNovamente: PropTypes.func.isRequired,
};

/**
 * @return {void} lista de clientes cadastrados.
 */
export default function Setores() {
  const [setores] = useSetores();
  const [, , setoresFetch] = useFuncoes();

  return (
    <NovoSetorProvider>
      <AlterarSetorProvider>
        <ExcluirSetorProvider>
          <Grid item xs={12} md={8} lg={9}>
            <Grid item xs={12}>
              <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <Lista setores={setores} tentarNovamente={setoresFetch} />
              </Paper>
            </Grid>
          </Grid>
        </ExcluirSetorProvider>
      </AlterarSetorProvider>
    </NovoSetorProvider>
  );
}

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { useApi } from '../../Hooks/Api';
import Logo from '../../Media/logo.png';
import Copyright from '../Copyright/Copyright';
import { LoadingButton } from '@mui/lab';
import { useErrorHandler } from '../../Hooks/ErrorHandler';
import { useAuth } from '../../Hooks/Auth';

/**
 *
 * @return {object}
 */
export default function Entrar() {
  const ApiController = useApi();
  const api = new ApiController();
  const [carregando, setCarregando] = useState(false);
  const [mostrarErro] = useErrorHandler();
  const [, setUsuario] = useAuth();

  const handleSubmit = (event) => {
    event.preventDefault();
    setCarregando(true);
    const dados = new FormData(event.currentTarget);
    api
      .post('/usuario/autenticar', dados, [400])
      .then((r) => {
        setCarregando(false);
        setUsuario(r.usuario);
      })
      .catch((e) => {
        if (e?.response?.status === 400) {
          mostrarErro([
            'Usuário ou senha incorretos',
            {
              conteudo: [
                'O nome de usuário ou senha informados estão incorretos',
              ],
              acoes: ['Ok'],
              funcoes: [() => {}],
            },
          ]);
        }
        setCarregando(false);
      });
  };

  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <img src={Logo} width={210} className='mb-2' />
        <Typography component='h1' variant='h5'>
          Entrar
        </Typography>
        <Box component='form' onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin='normal'
            required
            fullWidth
            id='usuario'
            label='Nome de usuário'
            name='usuario'
            autoComplete='on'
            autoFocus
          />
          <TextField
            margin='normal'
            required
            fullWidth
            name='senha'
            label='Senha'
            type='password'
            id='senha'
            autoComplete='current-password'
          />
          <LoadingButton
            type='submit'
            fullWidth
            variant='contained'
            loading={carregando}
            sx={{ mt: 3, mb: 2 }}
          >
            Entrar
          </LoadingButton>
          {/* <Grid container>
            <Grid item xs>
              <Link href='#' variant='body2'>
                Esqueceu a senha?
              </Link>
            </Grid>
          </Grid> */}
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}

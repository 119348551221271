// Constantes

// Correções

// Corrige a função structureClone para navegadores que não possuem suporte
if (typeof structuredClone !== 'function') {
  window.structuredClone = function (obj) {
    return JSON.parse(JSON.stringify(obj));
  };
}

// Funções gerais

/**
 * @param {string} cookie cookie a ser retornado
 * @return {string} Cookie
 *  */
export function retornarCookie(cookie) {
  try {
    const cookieBrowser = document.cookie.split(cookie + '=')[1].split(';')[0];
    return cookieBrowser;
  } catch (e) {
    return null;
  }
}

/**
 * @param {string} cookie cookie a ser removido
 * @return {void}
 *  */
export function removerCookie(cookie) {
  document.cookie = cookie + '=' + ';expires=Thu, 01 Jan 1970 00:00:01 GMT';
}

/**
 * @param {string} name Cookie
 * @return {string} cookie
 */
export function retornarCookieCodificado(name) {
  const value = '; ' + document.cookie;
  const parts = value.split('; ' + name + '=');
  if (parts.length == 2) {
    const vlu = parts.pop().split(';').shift();
    const decodeVlu = decodeURIComponent(vlu);
    const replaceVlu = decodeVlu.replace(/[+]/g, ' ');
    return JSON.parse(replaceVlu);
  } else return undefined;
}

/**
 * Criar uma string aleatória
 * @param {number} min número mínimo
 * @param {number} max número máximo
 * @return {string}
 */
export function criarNumeroShimmer(min, max) {
  const tamanhoAleatorio = Math.floor(Math.random() * (max - min + 1)) + min; // Gera um número aleatório entre 20 e 45
  return tamanhoAleatorio;
}

/**
 * @param {number} a
 * @param {number} b
 * @return {number}
 */
export function filtrarPorNomeFantasia(a, b) {
  const nomeA = a.nomeFantasia.toUpperCase();
  const nomeB = b.nomeFantasia.toUpperCase();

  if (nomeA < nomeB) {
    return -1;
  }
  if (nomeA > nomeB) {
    return 1;
  }
  return 0;
}

/**
 * @param {number} a
 * @param {number} b
 * @return {number}
 */
export function filtrarPorNome(a, b) {
  const nomeA = a.nome.toUpperCase();
  const nomeB = b.nome.toUpperCase();

  if (nomeA < nomeB) {
    return -1;
  }
  if (nomeA > nomeB) {
    return 1;
  }
  return 0;
}

/**
 * @param {number} a
 * @param {number} b
 * @return {number}
 */
export function filtrarPorEquipamento(a, b) {
  const nomeA = a.titulo.toUpperCase();
  const nomeB = b.titulo.toUpperCase();

  if (nomeA < nomeB) {
    return -1;
  }
  if (nomeA > nomeB) {
    return 1;
  }
  return 0;
}

/**
 * @param {number} numero
 * @return {string}
 */
function adicionarZero(numero) {
  // Adiciona um zero à esquerda se o número for menor que 10
  return numero < 10 ? '0' + numero : numero;
}

/**
 * @param {string} dataString
 * @return {string}
 */
export function formatarDataHora(dataString) {
  if (dataString) {
    // Cria um objeto Date com a string fornecida
    const data = new Date(dataString);

    // Extrai os componentes da data
    const dia = adicionarZero(data.getDate());
    const mes = adicionarZero(data.getMonth() + 1);
    const ano = data.getFullYear();
    const hora = adicionarZero(data.getHours());
    const minuto = adicionarZero(data.getMinutes());
    const segundo = adicionarZero(data.getSeconds());

    // Retorna a data formatada
    return (
      dia + '/' + mes + '/' + ano + ' ' + hora + ':' + minuto + ':' + segundo
    );
  } else return null;
}

/**
 * @param {string} dataString
 * @return {string}
 */
export function formatarData(dataString) {
  // Cria um objeto Date com a string fornecida
  const data = new Date(dataString);

  // Extrai os componentes da data
  const dia = adicionarZero(data.getDate());
  const mes = adicionarZero(data.getMonth() + 1);
  const ano = data.getFullYear();

  // Retorna a data formatada
  return dia + '/' + mes + '/' + ano;
}

/**
 *
 * @param {Date} data
 * @param {number} dias
 * @return {*}
 */
export function adicionarDias(data, dias) {
  const novaData = new Date(data);
  novaData.setDate(novaData.getDate() + Number(dias));
  return novaData;
}

/**
 * @param {number} momento
 * @return {number}
 */
export function momentoFormatado(momento) {
  return momento > 9 ? momento : '0' + momento;
}

/**
 * @param {string} str
 * @return {string}
 */
export function stringParaUrl(str) {
  str = str.toLowerCase();

  str = str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

  str = str.replace(/\s+/g, '-');

  str = str.replace(/[^\w\-]+/g, '');

  str = str.replace(/\-\-+/g, '-');

  str = str.replace(/^-+/, '').replace(/-+$/, '');

  return str;
}

/**
 * @param {Date} data1
 * @param {Date} data2
 * @return {object}
 */
export function useDiferencaEntreDatas(data1, data2) {
  let diff = Math.abs(data1 - data2);

  const timestamp = new Date(Math.abs(data1 - data2)).getTime();

  const dias = Math.floor(diff / (1000 * 60 * 60 * 24));
  diff -= dias * (1000 * 60 * 60 * 24);

  const horas = Math.floor(diff / (1000 * 60 * 60));
  diff -= horas * (1000 * 60 * 60);

  const minutos = Math.floor(diff / (1000 * 60));
  diff -= minutos * (1000 * 60);

  const segundos = Math.floor(diff / 1000);

  const diasFormatado = new Formatador(dias).doisDigitos();
  const horasFormatado = new Formatador(horas + dias * 24).doisDigitos();
  const minutosFormatado = new Formatador(minutos).doisDigitos();
  const segundosFormatado = new Formatador(segundos).doisDigitos();

  return {
    completado: data1 - data2 > 0,
    dias: diasFormatado,
    horas: horasFormatado,
    minutos: minutosFormatado,
    segundos: segundosFormatado,
    timestamp,
  };
}

/**
 * @param {number} setor
 * @return {string}
 */
export function retornarTipoDoSetor(setor) {
  const setores = { 1: 'Pós venda', 2: 'Produção' };

  return setores[setor];
}

// Classes

/**
 */
export class Formatador extends String {
  /**
   * @param {string} valor
   */
  constructor(valor) {
    super();
    this.valor = valor;
  }

  /**
   * @return {string}
   */
  doisDigitos() {
    return this.valor < 10 ? '0' + this.valor : this.valor.toString();
  }
}

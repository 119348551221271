/* eslint-disable react/prop-types */
import { LoadingButton } from '@mui/lab';
import {
  Backdrop,
  Box,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CaixaSelecao from '../../../../Componentes/Campo/CaixaSelecao';
import MultiplaEscolha from '../../../../Componentes/Campo/MultiplaEscolha';
import Texto from '../../../../Componentes/Campo/Texto';
import {
  filtrarPorNomeFantasia,
  formatarDataHora,
} from '../../../../Uteis/Funcoes';
import { useApi } from '../../../Api';
import { useAuth } from '../../../Auth';
import { useFuncoes } from '../../../FuncoesGerais';
import { useChamados } from '../../../Sistema/Chamados';
import { useClientes } from '../../../Sistema/Clientes';
import { usePerguntas } from '../../../Sistema/Perguntas';
import { useSetores } from '../../../Sistema/Setores';
import { useNovoCliente } from '../../Cliente/NovoCliente';
import { useEquipamentos } from '../../../Sistema/Equipamentos';

export const Ctx = createContext();

const url = 'chamados/pos-venda/novo';
const botaoCadastrar = 'Enviar';
const botaoCancelar = 'Cancelar';

/**
 * @param {object} props
 * @return {object}
 */
function ElementoPergunta(props) {
  const { tipo } = props;

  switch (tipo) {
    case 1:
      return <MultiplaEscolha {...props} />;
    case 2:
      return <CaixaSelecao {...props} />;
    case 3:
      return <Texto {...props} />;
  }
}

ElementoPergunta.propTypes = {
  props: PropTypes.object.isRequired,
};

/**
 * @return {node}
 */
function AtendimentoForm() {
  const [realizarAtendimento, setRealizarAtendimento] = useState(null);
  const [setores] = useSetores();

  /**
   * @param {object} e
   */
  function handleRealizarAtendimento(e) {
    setRealizarAtendimento(e.target.value);
  }

  return (
    <>
      <Grid item xs={12} sm={12}>
        <FormControl fullWidth>
          <InputLabel>Realizar atendimento?</InputLabel>
          <Select
            label='Realizar atendimento?'
            onChange={handleRealizarAtendimento}
            defaultValue={''}
            name='status'
          >
            <MenuItem value={2}>Sim</MenuItem>
            <MenuItem value={6}>Não</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      {realizarAtendimento !== null &&
        (realizarAtendimento === 2 ? (
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Transferir para</InputLabel>
              <Select
                label='Transferir para'
                name='setor'
                required
                defaultValue={''}
              >
                {setores &&
                  setores
                    .filter((item) => item.categoria === 3 && item.id !== 2)
                    .map((item, index) => (
                      <MenuItem
                        key={`item-map-setor-atendimento-${index}`}
                        value={item.id}
                      >
                        {item.nome}
                      </MenuItem>
                    ))}
              </Select>
            </FormControl>
          </Grid>
        ) : (
          <Grid item xs={12} sm={12}>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale={'pt-BR'}
            >
              <DemoContainer
                components={['DatePicker']}
                sx={{ paddingTop: 0, overflow: 'visible' }}
              >
                <DatePicker label='Retornar contato em' name='entregaEm' />
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
        ))}
    </>
  );
}

/**
 * Hooks manager
 * @param {any} children
 * @return {children} Hooks.
 */
export function NovoAtendimentoProvider({ children }) {
  const [alvo, setAtendimentoAlvo] = useState();
  const [enviando, setEnviando] = useState(false);
  const [clientes] = useClientes();
  const [, setChamados] = useChamados();
  const [, setAberto] = useNovoCliente();
  const [perguntas] = usePerguntas();
  const [, , , , , , , perguntasFetch] = useFuncoes();

  const [equipamentos] = useEquipamentos();

  const [usuario] = useAuth();

  const ApiController = useApi();
  const api = new ApiController();

  /**
   */
  function handleFechar() {
    setAtendimentoAlvo();
  }

  /**
   */
  function handleNovoCliente() {
    setAberto(true);
  }

  /**
   * @param {object} prev
   * @param {object} r
   * @return {object}
   */
  function alterarChamados(prev, r) {
    const chamadoEncontrado = prev.findIndex((item) => item.id === alvo.id);

    const prevChamado = structuredClone(prev);

    prevChamado[chamadoEncontrado] = r;

    return prevChamado;
  }

  /**
   * @param {object} event eventos
   */
  function handleEnviar(event) {
    event.preventDefault();
    setEnviando(true);

    const data = new FormData(event.currentTarget);

    if (alvo.id) {
      data.append('id', alvo.id);
    }

    api
      .post(url, data)
      .then((r) => {
        setChamados((prev) => alterarChamados(prev, r));

        toast.success('Atendimento criado com sucesso', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: 'colored',
          transition: Bounce,
        });

        setEnviando(false);
        handleFechar();
      })
      .catch((e) => {
        console.error(e);
        setEnviando(false);
      });
  }

  useEffect(() => {
    perguntasFetch();
  }, []);

  useEffect(() => {
    if (!alvo) {
      setEnviando(false);
    }
    console.log(alvo);
  }, [alvo]);

  return (
    <Ctx.Provider value={[alvo, setAtendimentoAlvo]}>
      {alvo &&
        (clientes ? (
          <Dialog
            open={alvo !== undefined}
            onClose={handleFechar}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
          >
            <DialogTitle id='alert-dialog-title'>
              Novo atendimento
              {alvo && alvo?.pedido?.pedido
                ? ' do pedido ' + alvo?.pedido?.pedido
                : ''}
            </DialogTitle>
            <DialogContent>
              <Box
                component='form'
                autoComplete='off'
                noValidate
                sx={{ mt: 3 }}
                onSubmit={handleEnviar}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <Divider sx={{ opacity: 1 }} />
                  </Grid>
                  <Grid item xs={12} sm={12} m={0}>
                    <Typography variant='h6'>
                      Orientação de como realizar o Pós-venda
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Typography variant='body1' gutterBottom>
                      Bom dia/boa tarde, tudo bem? Me chamo {usuario.nome}, sou
                      do setor Pós venda da Empresa Qualiterme.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Typography variant='body1' gutterBottom>
                      Vejo aqui no sistema que o sr{' '}
                      {clientes.find((item) => item.id === alvo.cliente)
                        ?.nomeFantasia ?? '(...)'}{' '}
                      adquiriu o equipamento{' '}
                      {equipamentos.find(
                        (item) => item.id === alvo.pedido?.equipamento
                      )?.titulo ?? '(...)'}{' '}
                      da Qualiterme em{' '}
                      {formatarDataHora(alvo?.criadoEm) ?? '(...)'}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Divider sx={{ opacity: 1 }} />
                  </Grid>
                  {alvo && !alvo.id && (
                    <>
                      <Grid item xs={12} sm={10}>
                        <FormControl fullWidth>
                          <InputLabel>Empresa *</InputLabel>
                          <Select
                            label='Empresa'
                            name='cliente'
                            required
                            defaultValue={''}
                          >
                            {structuredClone(clientes)
                              .sort(filtrarPorNomeFantasia)
                              .map(({ id, nomeFantasia }, index) => (
                                <MenuItem
                                  key={`item-map-nome-${index}`}
                                  value={id}
                                >
                                  {nomeFantasia}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={2}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Button variant='contained' onClick={handleNovoCliente}>
                          Novo
                        </Button>
                      </Grid>
                    </>
                  )}
                  {perguntas ? (
                    perguntas
                      .filter(
                        (item) =>
                          item.categoria ===
                          (alvo && alvo?.pedido?.pedido ? 1 : 2)
                      )
                      .map((props, index) => (
                        <Grid
                          key={`item-map-pergunta-${index}`}
                          item
                          xs={12}
                          sm={12}
                        >
                          <ElementoPergunta
                            key={`item-map-pergunta-${index}`}
                            {...props}
                          />
                        </Grid>
                      ))
                  ) : (
                    <p>Carregando...</p>
                  )}
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      multiline
                      rows={4}
                      name='observacao'
                      label='Anotações gerais sobre o atendimento'
                      id='observacao'
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Divider sx={{ opacity: 1 }} />
                  </Grid>
                  <AtendimentoForm />
                </Grid>
                <LoadingButton
                  type='submit'
                  fullWidth
                  variant='contained'
                  sx={{ mt: 3 }}
                  loading={enviando}
                >
                  {botaoCadastrar}
                </LoadingButton>
                <Button
                  type='button'
                  fullWidth
                  variant='outlined'
                  sx={{ mt: 2, mb: 2 }}
                  onClick={handleFechar}
                  disabled={enviando}
                >
                  {botaoCancelar}
                </Button>
              </Box>
            </DialogContent>
          </Dialog>
        ) : (
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={clientes === undefined && setAtendimentoAlvo}
            onClick={handleFechar}
          >
            <CircularProgress color='inherit' />
          </Backdrop>
        ))}
      {children}
    </Ctx.Provider>
  );
}

NovoAtendimentoProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useNovoAtendimento = () => {
  return useContext(Ctx);
};

import { LoadingButton } from '@mui/lab';
import {
  Backdrop,
  Box,
  CircularProgress,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useState } from 'react';
import { Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useApi } from '../../Api';
import { useEquipamentos } from '../../Sistema/Equipamentos';
import { useSetores } from '../../Sistema/Setores';

export const Ctx = createContext();

/**
 * Hooks manager
 * @param {any} children
 * @return {children} Hooks.
 */
export function NovoEquipamentoProvider({ children }) {
  const [aberto, setAberto] = useState(false);
  const [enviando, setEnviando] = useState(false);
  const [, setEquipamentos] = useEquipamentos();
  const [setores] = useSetores();
  const ApiController = useApi();
  const api = new ApiController();

  /**
   */
  function handleFechar() {
    setAberto(false);
  }

  /**
   * @param {object} event eventos
   */
  function handleEnviar(event) {
    event.preventDefault();
    setEnviando(true);
    const data = new FormData(event.currentTarget);

    api
      .post('equipamentos/novo', data)
      .then((r) => {
        setEquipamentos((prev) => [...prev, r]);

        toast.success('Equipamento cadastrado com sucesso!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: 'colored',
          transition: Bounce,
        });

        setEnviando(false);
        handleFechar();
      })
      .catch((e) => {
        console.error(e);
        setEnviando(false);
      });
  }

  return (
    <Ctx.Provider value={[aberto, setAberto]}>
      {setores ? (
        <Dialog
          open={aberto}
          onClose={handleFechar}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>
            {'Novo equipamento'}
          </DialogTitle>
          <DialogContent>
            <Box
              component='form'
              autoComplete='off'
              noValidate
              sx={{ mt: 3 }}
              onSubmit={handleEnviar}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    required
                    fullWidth
                    name='titulo'
                    label='Título'
                    id='titulo'
                    autoComplete='off'
                  />
                </Grid>
                <Grid item sx={{ width: '100%' }}>
                  <Divider />
                  <Typography variant='subtitle1' gutterBottom>
                    Prazos
                  </Typography>
                  <TableContainer
                    style={{
                      borderWidth: 1,
                      borderColor: 'rgba(0, 0, 0, 0.23)',
                      borderStyle: 'solid',
                      borderRadius: '4px',
                    }}
                  >
                    <Table aria-label='simple table'>
                      <TableHead>
                        <TableRow>
                          <TableCell align='center'>Setor</TableCell>
                          <TableCell align='right'>Prazo em horas</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {setores &&
                          setores.filter((item) => item.id != 14).map((setor, index) => (
                            <TableRow
                              key={`item-map-setor-prazo-${index}`}
                              sx={{
                                '&:last-child td, &:last-child th': {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell component='th' scope='row'>
                                {setor.nome}
                                <input
                                  type='text'
                                  name='setoresNome[]'
                                  defaultValue={setor.nome}
                                  hidden
                                />
                                <input
                                  type='text'
                                  name='setoresId[]'
                                  defaultValue={setor.id}
                                  hidden
                                />
                              </TableCell>
                              <TableCell align='right'>
                                <TextField
                                  id='outlined-size-small'
                                  size='small'
                                  name='setoresPrazo[]'
                                  sx={{ width: '12ch' }}
                                  required
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
              <LoadingButton
                type='submit'
                fullWidth
                variant='contained'
                sx={{ mt: 3 }}
                loading={enviando}
              >
                {'Cadastrar equipamento'}
              </LoadingButton>
              <Button
                type='button'
                fullWidth
                variant='outlined'
                sx={{ mt: 2, mb: 2 }}
                onClick={handleFechar}
                disabled={enviando}
              >
                {'Cancelar'}
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      ) : (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
          onClick={handleFechar}
        >
          <CircularProgress color='inherit' />
        </Backdrop>
      )}
      {children}
    </Ctx.Provider>
  );
}

NovoEquipamentoProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useNovoEquipamento = () => {
  return useContext(Ctx);
};

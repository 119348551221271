import HelpIcon from '@mui/icons-material/Help';
import { Button, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FluxoProducao } from '../../../../Hooks/Funcionalidades/Sistema/FluxoDeProducao';
import { useFuncoes } from '../../../../Hooks/FuncoesGerais';
import { useConfiguracoes } from '../../../../Hooks/Sistema/Configuracoes';
import { MensagensDeEmail } from '../../../../Hooks/Funcionalidades/Sistema/MensagensDeEmail';

/**
 * @param {node} tentarNovamente
 * @return {object}
 */
function Lista({ tentarNovamente }) {
  const [configuracoes] = useConfiguracoes();
  const [fluxoAberto, setFluxoAberto] = useState(false);
  const [mensagensAberto, setMensagensAberto] = useState(false);

  /**
   */
  function handleFluxo() {
    setFluxoAberto(true);
  }

  /**
   */
  function handleMensagens() {
    setMensagensAberto(true);
  }

  return (
    <React.Fragment>
      {fluxoAberto && <FluxoProducao setFluxoAberto={setFluxoAberto} />}
      {mensagensAberto && <MensagensDeEmail handleFechar={() => {setMensagensAberto(false)}} />}
      <Stack justifyContent='space-between' alignItems='center' direction='row'>
        <Typography component='h2' variant='h6' color='primary' gutterBottom>
          Configurações
        </Typography>
        {configuracoes === null && (
          <Button variant='outlined' onClick={tentarNovamente}>
            Tentar novamente
          </Button>
        )}
      </Stack>
      <Table size='small' aria-label='caption table'>
        <TableHead>
          <TableRow>
            <TableCell align='center'></TableCell>
            <TableCell align='center'></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align='center'>
              <Typography variant='subtitle1' noWrap>
                Fluxo de produção
                <Tooltip
                  title='Fluxo de setores que o equipamento seguirá na produção'
                  placement='top'
                >
                  <IconButton aria-label='duvida' edge='end'>
                    {<HelpIcon />}
                  </IconButton>
                </Tooltip>
              </Typography>
            </TableCell>
            <TableCell sx={{ textAlign: 'end' }}>
              {configuracoes && (
                <Button variant='contained' onClick={handleFluxo}>
                  Alterar
                </Button>
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align='center'>
              <Typography variant='subtitle1' noWrap>
                Mensagens de e-mail
                <Tooltip
                  title='Mensagens que serão enviadas para o email do cliente'
                  placement='top'
                >
                  <IconButton aria-label='duvida' edge='end'>
                    {<HelpIcon />}
                  </IconButton>
                </Tooltip>
              </Typography>
            </TableCell>
            <TableCell sx={{ textAlign: 'end' }}>
              {configuracoes && (
                <Button variant='contained' onClick={handleMensagens}>
                  Alterar
                </Button>
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </React.Fragment>
  );
}

Lista.propTypes = {
  tentarNovamente: PropTypes.func.isRequired,
};

/**
 * @return {void} lista de clientes cadastrados.
 */
export default function Configuracoes() {
  const [, , , , , configuracoesFetch] = useFuncoes();

  useEffect(() => {
    configuracoesFetch();
  }, []);

  return (
    <Grid item xs={12} md={8} lg={9}>
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
          <Lista tentarNovamente={configuracoesFetch} />
        </Paper>
      </Grid>
    </Grid>
  );
}

import { Button, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { ClienteContatoProvider } from '../../../Hooks/Funcionalidades/Cliente/ClienteContato';
import { EditarClienteProvider } from '../../../Hooks/Funcionalidades/Cliente/EditarCliente';
import { ExcluirClienteProvider } from '../../../Hooks/Funcionalidades/Cliente/ExcluirCliente';
import {
  NovoClienteProvider,
  useNovoCliente,
} from '../../../Hooks/Funcionalidades/Cliente/NovoCliente';
import { useFuncoes } from '../../../Hooks/FuncoesGerais';
import { useClientes } from '../../../Hooks/Sistema/Clientes';
import { formatarDataHora } from '../../../Uteis/Funcoes';
import Acoes from './Acoes/Acoes';

const tipoDePessoa = { 1: 'Pessoa Jurídica', 2: 'Pessoa Física' };

/**
 * @return {object}
 */
function ListaSkeleton() {
  return Array(5)
    .fill()
    .map((item, index) => (
      <TableRow key={`skeleton-usuario-item-${index}`}>
        <TableCell align='center'>
          <Skeleton variant='text' width={'5ch'} />
        </TableCell>
        <TableCell align='center'>
          <Skeleton variant='text' width={'5ch'} />
        </TableCell>
        <TableCell align='center'>
          <Skeleton variant='text' width={'7ch'} />
        </TableCell>
        <TableCell align='center'>
          <Skeleton variant='text' width={'12ch'} />
        </TableCell>
        <TableCell align='center'>
          <Skeleton variant='text' width={'18ch'} />
        </TableCell>
        <TableCell align='center'>
          <Skeleton variant='text' width={'15ch'} />
        </TableCell>
        <TableCell align='center'>
          <Skeleton variant='text' width={'8ch'} />
        </TableCell>
        <TableCell align='center'>
          <Skeleton
            variant='rounded'
            width={'102.36px'}
            height={'36.5px'}
            style={{ float: 'inline-end' }}
          />
        </TableCell>
      </TableRow>
    ));
}

/**
 * @param {array} lista
 * @return {object}
 */
function Lista({ lista }) {
  const [, setAberto] = useNovoCliente();

  /**
   */
  function handleAbrir() {
    setAberto(true);
  }

  return (
    <React.Fragment>
      <Stack justifyContent='space-between' alignItems='center' direction='row'>
        <Typography component='h2' variant='h6' color='primary' gutterBottom>
          Clientes
        </Typography>
        <Button variant='outlined' onClick={handleAbrir}>
          Novo cliente
        </Button>
      </Stack>
      <Table size='small' aria-label='caption table'>
        <TableHead>
          <TableRow>
            <TableCell align='center'>Id</TableCell>
            <TableCell align='center'>Pessoa</TableCell>
            <TableCell align='center'>Identificação</TableCell>
            <TableCell align='center'>Nome fantasia</TableCell>
            <TableCell align='center'>Plano</TableCell>
            <TableCell align='center'>Localização</TableCell>
            <TableCell align='center'>Data de cadastro</TableCell>
            <TableCell align='center'></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {lista ? (
            lista.map(
              (
                {
                  id,
                  nomeFantasia,
                  pessoa,
                  identificacao,
                  plano,
                  cidade,
                  estado,
                  criadoEm,
                },
                index
              ) => (
                <TableRow key={`item-map-cliente-${index}`}>
                  <TableCell align='center'>{id}</TableCell>
                  <TableCell align='center'>{tipoDePessoa[pessoa]}</TableCell>
                  <TableCell align='center'>{identificacao}</TableCell>
                  <TableCell align='center'>{nomeFantasia}</TableCell>
                  <TableCell align='center'>{plano}</TableCell>
                  <TableCell align='center'>
                    {cidade} / {estado}
                  </TableCell>
                  <TableCell align='center'>{formatarDataHora(criadoEm)}</TableCell>
                  <TableCell className='text-end'>
                    <Acoes id={id} />
                  </TableCell>
                </TableRow>
              )
            )
          ) : (
            <ListaSkeleton />
          )}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}

Lista.propTypes = {
  lista: PropTypes.array,
};

/**
 * @return {void} lista de clientes cadastrados.
 */
export default function Clientes() {
  const [clientes] = useClientes();
  const [, , , clientesFetch] = useFuncoes();

  useEffect(() => {
    clientesFetch();
  }, []);

  return (
    <NovoClienteProvider>
      <ClienteContatoProvider>
        <EditarClienteProvider>
          <ExcluirClienteProvider>
            <Grid item xs={12} md={8} lg={9}>
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  <Lista lista={clientes} />
                </Paper>
              </Grid>
            </Grid>
          </ExcluirClienteProvider>
        </EditarClienteProvider>
      </ClienteContatoProvider>
    </NovoClienteProvider>
  );
}

/* eslint-disable no-unused-vars */
import { Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * @param {array} lista
 * @param {object} setor
 * @return {object}
 */
function Lista({ lista, setor }) {
  return (
    <>
      <Stack justifyContent='space-between' alignItems='center' direction='row'>
        <Typography component='h2' variant='h6' color='primary' gutterBottom>
          <Skeleton
            variant='text'
            sx={{ fontSize: '1.25rem' }}
            width={'10ch'}
          />
        </Typography>
      </Stack>
      <Table size='small' aria-label='caption table'>
        <TableHead>
          <TableRow>
            <TableCell align='center'>
              <Skeleton
                sx={{ margin: 'auto', color: 'transparent' }}
                variant='text'
              >
                Pedido
              </Skeleton>
            </TableCell>
            <TableCell align='center'>
              <Skeleton
                sx={{ margin: 'auto', color: 'transparent' }}
                variant='text'
              >
                Status
              </Skeleton>
            </TableCell>
            <TableCell align='center'>
              <Skeleton
                sx={{ margin: 'auto', color: 'transparent' }}
                variant='text'
              >
                Ação
              </Skeleton>
            </TableCell>
            <TableCell align='center'>
              <Skeleton
                sx={{ margin: 'auto', color: 'transparent' }}
                variant='text'
              >
                Cliente
              </Skeleton>
            </TableCell>
            <TableCell align='center'>
              <Skeleton
                sx={{ margin: 'auto', color: 'transparent' }}
                variant='text'
              >
                Equipamento
              </Skeleton>
            </TableCell>
            <TableCell align='center'>
              <Skeleton
                sx={{ margin: 'auto', color: 'transparent' }}
                variant='text'
              >
                Prazo
              </Skeleton>
            </TableCell>
            <TableCell align='center'>
              <Skeleton
                variant='rounded'
                width={'190.36px'}
                height={'36.5px'}
                sx={{ margin: 'auto', float: 'inline-end', opacity: 0 }}
              />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Array(5)
            .fill()
            .map((item, index) => (
              <TableRow key={`skeleton-usuario-item-${index}`}>
                <TableCell align='center'>
                  <Skeleton
                    sx={{ margin: 'auto' }}
                    variant='text'
                    width={'5ch'}
                  />
                </TableCell>
                <TableCell align='center'>
                  <Skeleton
                    sx={{ margin: 'auto' }}
                    variant='text'
                    width={'5ch'}
                  />
                </TableCell>
                <TableCell align='center'>
                  <Skeleton
                    sx={{ margin: 'auto' }}
                    variant='text'
                    width={'7ch'}
                  />
                </TableCell>
                <TableCell align='center'>
                  <Skeleton
                    sx={{ margin: 'auto' }}
                    variant='text'
                    width={'12ch'}
                  />
                </TableCell>
                <TableCell align='center'>
                  <Skeleton
                    sx={{ margin: 'auto' }}
                    variant='text'
                    width={'8ch'}
                  />
                </TableCell>
                <TableCell align='center'>
                  <Skeleton
                    sx={{ margin: 'auto' }}
                    variant='text'
                    width={'8ch'}
                  />
                </TableCell>
                <TableCell align='center'>
                  <Skeleton
                    variant='rounded'
                    width={'190.36px'}
                    height={'36.5px'}
                    sx={{ opacity: 0 }}
                  />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </>
  );
}

Lista.propTypes = {
  lista: PropTypes.array,
  setor: PropTypes.object,
};

/**
 * @return {void} lista de clientes cadastrados.
 */
export default function SetorSkeleton() {
  return (
    <Grid item xs={12} md={8} lg={9}>
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
          <Lista />
        </Paper>
      </Grid>
    </Grid>
  );
}

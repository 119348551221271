/* eslint-disable no-unused-vars */
import { LoadingButton } from '@mui/lab';
import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  Input,
  Stack,
  Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import React, { createContext, useEffect, useState } from 'react';
import { Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useApi } from '../../Api';
import { useConfiguracoes } from '../../Sistema/Configuracoes';

const url = 'admin/sistema/mensagens';
const titulo = 'Mensagens de e-mail';
const botaoCadastrarMensagem = 'Salvar';
const botaoCancelarMensagem = 'Cancelar';

const mensagensTipo = {
  1: 'Pedido criado',
  2: 'Pedido transferido',
  3: 'Pedido finalizado',
};

export const Ctx = createContext();

/**
 * Hooks manager
 * @param {any} children
 * @param {node} handleFechar
 * @return {children} Hooks.
 */
export function MensagensDeEmail({ children, handleFechar }) {
  const [enviando, setEnviando] = useState(false);
  const [configuracoes, setConfiguracoes] = useConfiguracoes();
  const [mensagens] = useState(
    JSON.parse(configuracoes?.find((item) => item.id == 2).dados ?? '[]')
  );
  const ApiController = useApi();
  const api = new ApiController();

  /**
   * @param {object} event eventos
   */
  function handleEnviar(event) {
    event.preventDefault();
    setEnviando(true);
    const data = new FormData(event.currentTarget);

    api
      .post(url, data)
      .then((r) => {
        const prevConfiguracoes = structuredClone(configuracoes);

        prevConfiguracoes[1] = r;

        setConfiguracoes(prevConfiguracoes);

        toast.success('Fluxo alterado com sucesso', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: 'colored',
          transition: Bounce,
        });

        setEnviando(false);
        handleFechar();
      })
      .catch((e) => {
        console.error(e);
        setEnviando(false);
      });
  }

  return (
    <Dialog
      open={true}
      onClose={handleFechar}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      fullWidth
      maxWidth={'md'}
    >
      <DialogTitle id='alert-dialog-title'>{titulo}</DialogTitle>
      <DialogContent>
        <Box
          component='form'
          autoComplete='off'
          noValidate
          onSubmit={handleEnviar}
        >
          {mensagens.map((item, index) => (
            <Accordion key={`item-map-mensagem-${index}`} disableGutters>
              <AccordionSummary aria-controls='panel1bh-content'>
                <Typography sx={{ width: '33%', flexShrink: 0 }}>
                  {mensagensTipo[item.id]}
                </Typography>
                <input hidden name='tipos[]' value={item.id} />
                <Input
                  name={'valores[]'}
                  fullWidth
                  placeholder='Placeholder'
                  defaultValue={item.valor}
                />
              </AccordionSummary>
            </Accordion>
          ))}
          <Stack direction={'row'} justifyContent={'end'}>
            <Stack direction={'row'} sx={{ mt: 2 }} gap={2} width={'50%'}>
              <Button
                type='button'
                fullWidth
                variant='outlined'
                onClick={handleFechar}
                disabled={enviando}
              >
                {botaoCancelarMensagem}
              </Button>
              <LoadingButton
                type='submit'
                fullWidth
                variant='contained'
                loading={enviando}
              >
                {botaoCadastrarMensagem}
              </LoadingButton>
            </Stack>
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

MensagensDeEmail.propTypes = {
  handleFechar: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

import PropTypes from 'prop-types';
import React, { createContext, useContext, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';

export const Ctx = createContext();

/**
 * Hooks manager
 * @param {any} children
 * @return {children} Hooks.
 */
export function ClientesProvider({ children }) {
  const [clientes, setClientes] = useState();

  return (
    <Ctx.Provider value={[clientes, setClientes]}>{children}</Ctx.Provider>
  );
}

ClientesProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useClientes = () => {
  return useContext(Ctx);
};

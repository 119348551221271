import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

/**
 * @param {string} id
 * @param {string} pergunta
 * @param {string} alternativas
 * @param {string} respostas
 * @return {object}
 */
function CaixaSelecao({ id, pergunta, alternativas, respostas }) {
  const respostaItem = respostas?.find(
    (item) => Number(item.pergunta) === Number(id)
  );

  const [resposta, setResposta] = useState(
    JSON.parse(respostaItem?.resposta ?? '[]')
  );

  /**
   * @param {object} e
   */
  function handleResposta(e) {
    const valor = JSON.parse(e.target.value);
    const prevResposta = structuredClone(resposta);

    if (resposta[valor[0]] !== valor[1] && !resposta.includes(valor[1])) {
      prevResposta.splice(valor[0], 0, valor[1]);
    } else {
      const index = resposta.findIndex((item) => item === valor[1]);
      prevResposta.splice(index, 1);
    }

    setResposta(prevResposta);
  }

  return (
    <FormControl variant='standard'>
      <FormLabel>{pergunta}</FormLabel>
      <input name='perguntas[]' hidden value={id} />
      <input name='respostas[]' hidden value={JSON.stringify(resposta)} />
      <FormGroup name='' onChange={handleResposta}>
        {JSON.parse(alternativas).map((item, index) => (
          <FormControlLabel
            key={`item-map-alternativa-${index}`}
            control={<Checkbox checked={resposta.includes(item)} />}
            label={item}
            value={JSON.stringify([index, item])}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
}

CaixaSelecao.propTypes = {
  id: PropTypes.string.isRequired,
  pergunta: PropTypes.string.isRequired,
  alternativas: PropTypes.string,
  respostas: PropTypes.string,
};

export default CaixaSelecao;

import React from 'react';
import PropTypes from 'prop-types';
import { Chip, TableCell } from '@mui/material';

const statusDoChamado = {
  1: { titulo: 'Aguardando retorno', classe: 'qt-prazo-retornar' },
  2: { titulo: 'Em andamento', classe: 'qt-prazo-aguardando' },
  3: { titulo: 'Em atendimento', classe: 'qt-prazo-pendente' },
  4: { titulo: 'Aguardando contato', classe: 'qt-prazo-finalizado' },
  5: { titulo: 'Excluido', classe: 'qt-prazo-excluido' },
  6: { titulo: 'Aguardando retorno', classe: 'qt-prazo-finalizado' },
};

/**
 * @param {string} status
 * @param {boolean} atrasado
 * @return {object}
 */
function Status({ status, atrasado }) {
  return (
    <TableCell align='center'>
      {atrasado ? (
        <Chip className={'qt-prazo-atrasado'} label={'Setor não retornou'} />
      ) : (
        <Chip
          className={statusDoChamado[status].classe}
          label={statusDoChamado[status].titulo}
        />
      )}
    </TableCell>
  );
}

Status.propTypes = {
  status: PropTypes.number.isRequired,
  atrasado: PropTypes.bool.isRequired,
};

export default Status;

import { ThemeProvider } from '@emotion/react';
import { createTheme } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { paramTemaCorPrimaria, paramTemaCorSecundaria } from '../Uteis/Parametros';
import { ptBR } from '@mui/x-date-pickers/locales';

const tema = createTheme({
  palette: {
    primary: {
      main: paramTemaCorPrimaria,
    },
    secondary: {
      main: paramTemaCorSecundaria
    },
    ptBR
  },
});

/**
 *
 * @param {children} children
 * @return {object}
 */
function TemaProvider({ children }) {
  return <ThemeProvider theme={tema}>{children}</ThemeProvider>;
}

TemaProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TemaProvider;

import { Button, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useApi } from '../../../../Hooks/Api';
import { MovimentacoesProvider } from '../../../../Hooks/Funcionalidades/Historico/Movimentacoes';
import { useFuncoes } from '../../../../Hooks/FuncoesGerais';
import { useClientes } from '../../../../Hooks/Sistema/Clientes';
import { retornarTipoDoSetor } from '../../../../Uteis/Funcoes';
import Status from '../../../Chamado/Status';
import Acoes from './Acoes/Acoes';

/**
 * @return {object}
 */
function ListaSkeleton() {
  return Array(5)
    .fill()
    .map((item, index) => (
      <TableRow key={`skeleton-usuario-item-${index}`}>
        <TableCell align='center'>
          <Skeleton variant='text' width={'5ch'} />
        </TableCell>
        <TableCell align='center'>
          <Skeleton variant='text' width={'5ch'} />
        </TableCell>
        <TableCell align='center'>
          <Skeleton variant='text' width={'7ch'} />
        </TableCell>
        <TableCell align='center'>
          <Skeleton variant='text' width={'12ch'} />
        </TableCell>
      </TableRow>
    ));
}

/**
 * @param {array} lista
 * @param {function} tentarNovamente
 * @return {object}
 */
function Lista({ lista, tentarNovamente }) {
  const [clientes] = useClientes();
  const [pesquisa, setPesquisa] = useState();

  /**
   * @param {object} e
   */
  function handlePesquisa(e) {
    if (e.target.value) {
      setPesquisa(e.target.value);
    } else {
      setPesquisa();
    }
  }

  return (
    <React.Fragment>
      <Stack justifyContent='space-between' alignItems='center' direction='row'>
        <Typography component='h2' variant='h6' color='primary' gutterBottom>
          Histórico
        </Typography>
      </Stack>
      <Table size='small' aria-label='caption table'>
        <TableHead>
          <TableRow>
            <TableCell align='center' className='qt-celula-pesquisavel'>
              <input
                onChange={handlePesquisa}
                type='text'
                placeholder='Pedido'
              />
            </TableCell>
            <TableCell align='center'>Status</TableCell>
            <TableCell align='center'>Ação</TableCell>
            <TableCell align='center'>Cliente</TableCell>
            <TableCell align='center'></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {clientes && lista ? (
            lista
              .filter((item) => {
                if (pesquisa) {
                  if (item?.pedido?.pedido) {
                    return String(item.pedido.pedido).includes(
                      String(pesquisa)
                    );
                  } else {
                    return String(item.id).includes(String(pesquisa));
                  }
                } else {
                  return true;
                }
              })
              .map((chamado, index) => (
                <TableRow key={`item-map-equipamento-${index}`}>
                  <TableCell align='center'>
                    {chamado?.pedido ? chamado?.pedido?.pedido : chamado.id}
                  </TableCell>
                  <Status status={chamado.status} />
                  <TableCell align='center'>
                    {retornarTipoDoSetor(chamado.tipo)}
                  </TableCell>
                  <TableCell align='center'>
                    {
                      clientes.find((item) => item.id == chamado.cliente)
                        .nomeFantasia
                    }
                  </TableCell>
                  <TableCell align='right'>
                    <Acoes chamado={chamado} />
                  </TableCell>
                </TableRow>
              ))
          ) : lista === null ? (
            <TableRow>
              <TableCell
                className='text-center'
                colSpan={6}
                sx={{ height: '247.5px' }}
              >
                <Typography gutterBottom>
                  Houve um erro ao carregar a listagem!
                </Typography>
                <Button onClick={tentarNovamente}>Tentar novamente</Button>
              </TableCell>
            </TableRow>
          ) : (
            <ListaSkeleton />
          )}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}

Lista.propTypes = {
  lista: PropTypes.array,
  tentarNovamente: PropTypes.func.isRequired,
};

/**
 * @return {void} lista de clientes cadastrados.
 */
export default function Historico() {
  const [historico, setHistorico] = useState();
  const [, , , clientesFetch] = useFuncoes();
  const ApiController = useApi();
  const api = new ApiController();

  /**
   */
  function historicoFetch() {
    if (!historico) {
      if (historico === null) {
        setHistorico(undefined);
      }

      api
        .post('admin/historico/lista')
        .then((r) => {
          setHistorico(r);
        })
        .catch((e) => {
          setHistorico(null);
          console.error(e);
        });
    }
  }

  useEffect(() => {
    historicoFetch();
    clientesFetch();
  }, []);

  return (
    <MovimentacoesProvider>
      <Grid item xs={12} md={8} lg={9}>
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <Lista lista={historico} tentarNovamente={historicoFetch} />
          </Paper>
        </Grid>
      </Grid>
    </MovimentacoesProvider>
  );
}

// Tema
export const paramTemaCorPrimaria = '#1c8793';
export const paramTemaCorSecundaria = '#7c7c7c';

// Urls
export const paramApiUrl = 'https://api.qualiterme.com';
export const paramSiteUrl = 'https://painel.qualiterme.com';

// Cookies
export const paramCookieAutenticacao = 'qtAuth';
export const paramCookieMaster = 'qtMaster';

// SessionStorage
export const paramStUsuarios = 'QtUsuarios';
export const paramStSetores = 'QtSetores';

// Interno

export const setoresCategoriasLista = [
  { id: 1, valor: 'Pós venda' },
  { id: 2, valor: 'Produção' },
  { id: 3, valor: 'Tercerizado' },
  { id: 4, valor: 'Auditoria' },
];

export const perguntasCategoriasLista = { 1: 'Pós Venda', 2: 'Prospecção' };

export const perguntasLista = {
  1: 'Múltipla escolha',
  2: 'Caixa de seleção',
  3: 'Resposta',
};

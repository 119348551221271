/* eslint-disable no-unused-vars */
import { Button, Skeleton, TableContainer, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useApi } from '../../../../Hooks/Api';
import { useAuth } from '../../../../Hooks/Auth';
import {
  MovimentacoesProvider,
  useVisualizarMovimentacoes,
} from '../../../../Hooks/Funcionalidades/Historico/Movimentacoes';
import { useFuncoes } from '../../../../Hooks/FuncoesGerais';
import { useChamados } from '../../../../Hooks/Sistema/Chamados';
import { useEquipamentos } from '../../../../Hooks/Sistema/Equipamentos';
import { useSetores } from '../../../../Hooks/Sistema/Setores';
import { formatarData } from '../../../../Uteis/Funcoes';
import ChamadoPrazo from '../../../Chamado/Prazo';

/**
 * @param {number} length
 * @return {object}
 */
function ListaSkeleton({ length }) {
  return Array(10)
    .fill()
    .map((item, index) => (
      <TableRow key={`skeleton-usuario-item-${index}`}>
        {Array(length + 2)
          .fill()
          .map((item, ix) => (
            <TableCell
              key={`skeleton-row-item-${ix}`}
              align='center'
              sx={{ textAlign: 'center' }}
            >
              <Skeleton variant='text' width={'5ch'} sx={{ margin: 'auto' }} />
            </TableCell>
          ))}
      </TableRow>
    ));
}

let elemento = 7;

/**
 * @param {object} setor
 * @return {object}
 */
function Lista() {
  const [setMovimentacaoAlvo] = useVisualizarMovimentacoes();
  const [usuario] = useAuth();
  const [equipamentos] = useEquipamentos();
  const [, equipamentosFetch, , clientesFetch] = useFuncoes();
  const [setores] = useSetores();
  const [chamados, setChamados] = useChamados();
  const [isIdle, setIsIdle] = useState(false);
  const intervalIdRef = useRef(null);
  const [pesquisa, setPesquisa] = useState();

  /**
   * @param {object} e
   */
  function handlePesquisa(e) {
    if (e.target.value) {
      setPesquisa(e.target.value);
    } else {
      setPesquisa();
    }
  }

  const headerHeight = document.getElementById('qt-header')?.clientHeight;

  const handleOnIdle = () => {
    setIsIdle(true);
  };

  const handleOnActive = () => {
    setIsIdle(false);
  };

  const handleOnAction = () => {
    setIsIdle(false);
  };

  useIdleTimer({
    timeout: 5000, // Tempo em ms até considerar o usuário inativo
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
  });

  useEffect(() => {
    const elementoClasse = document.getElementsByClassName('qt-item-inicial');

    if (isIdle) {
      intervalIdRef.current = setInterval(() => {
        if (elementoClasse[elemento]) {
          elementoClasse[elemento].scrollIntoView({
            block: 'end',
            behavior: 'smooth',
          });
          if (elemento + 1 === elementoClasse.length) {
            console.log('res');
            elemento = 7;
          } else {
            console.log('seq');
            elemento += 1;
          }
        }
      }, 5000);
    } else {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
        intervalIdRef.current = null;
      }
    }

    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }
    };
  }, [isIdle]);
  /**
   *  @param {string} index
   */
  function atrasarChamado(index) {
    const prevChamados = structuredClone(chamados);

    prevChamados[index].atrasado = true;

    setChamados(prevChamados);
  }

  /**
   * @param {object} ch
   */
  function handleMovimentacao(ch) {
    setMovimentacaoAlvo(ch);
  }

  useEffect(() => {
    clientesFetch();
    equipamentosFetch();
  }, []);

  return (
    <>
      {JSON.parse(usuario.setor)[0] !== 1 && (
        <Typography
          component='h2'
          variant='h6'
          color='primary'
          gutterBottom
          margin={'auto'}
        >
          Todos os pedidos
        </Typography>
      )}
      <TableContainer sx={{ height: window.innerHeight - headerHeight - 32 }}>
        <Table stickyHeader size='small' aria-label='caption table'>
          <TableHead>
            <TableRow>
              <TableCell align='center' className='qt-celula-pesquisavel'>
                <input
                  onChange={handlePesquisa}
                  type='text'
                  placeholder='Pedido'
                />
              </TableCell>
              {setores &&
                setores
                  .filter(
                    (item) => Number(item.categoria) === 2 && item.id !== 14
                  )
                  .map((setor, index) => (
                    <TableCell align='center' key={`item-map-setor-${index}`}>
                      {setor.nome}
                    </TableCell>
                  ))}
              <TableCell align='center'>Previsão</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {setores &&
              (chamados && equipamentos ? (
                chamados
                  .filter(
                    (item) =>
                      setores.find((setor) => setor.id === item.setor)
                        .categoria === 2 &&
                      item.status !== 4 &&
                      item.excluido != 1
                  )
                  .filter((item) => {
                    if (pesquisa) {
                      if (item?.pedido?.pedido) {
                        return String(item.pedido.pedido).includes(
                          String(pesquisa)
                        );
                      } else {
                        return String(item.id).includes(String(pesquisa));
                      }
                    } else {
                      return true;
                    }
                  })
                  .map((chamado, index) => {
                    const movimentacao = new Date(chamado.pedido.movimentadoEm);
                    if (chamado.status == 3) {
                      const prazoSetor = equipamentos.find(
                        (item) => item.id === chamado.pedido.equipamento
                      );

                      const entrega = new Date(
                        movimentacao.getTime() + prazoSetor * 60 * 60 * 1000
                      );

                      if (entrega - new Date() <= 0) {
                        atrasarChamado(index);
                      }
                    }

                    return (
                      <TableRow
                        key={`item-map-chamado-${index}`}
                        className='qt-item-inicial'
                      >
                        <TableCell align='center'>
                          <Button
                            onClick={() => {
                              handleMovimentacao(chamado);
                            }}
                          >
                            {chamado.tipo === 1
                              ? chamado.id
                              : chamado.pedido.pedido}
                          </Button>
                        </TableCell>
                        {setores
                          .filter(
                            (item) =>
                              Number(item.categoria) === 2 && item.id !== 14
                          )
                          .map((setor, setorIndex) => (
                            <ChamadoPrazo
                              key={`item-map-setor-${setorIndex}`}
                              setor={setor}
                              chamado={chamado}
                              acao={() => {
                                atrasarChamado(index);
                              }}
                            />
                          ))}
                        <TableCell align='center'>
                          {formatarData(chamado.entregaEm)}
                        </TableCell>
                      </TableRow>
                    );
                  })
              ) : (
                <ListaSkeleton
                  length={
                    setores.filter(
                      (item) => Number(item.categoria) === 2 && item.id !== 14
                    ).length
                  }
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

Lista.propTypes = {
  setor: PropTypes.object,
};

/**
 * @return {void} lista de clientes cadastrados.
 */
export default function Inicial() {
  const [, setChamados] = useChamados();
  const ApiController = useApi();
  const api = new ApiController();

  useEffect(() => {
    api
      .post('chamados/lista')
      .then((r) => {
        setChamados(r);
      })
      .catch((e) => {});
  }, []);

  return (
    <MovimentacoesProvider>
      <Grid item xs={12} md={8}>
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <Lista />
          </Paper>
        </Grid>
      </Grid>
    </MovimentacoesProvider>
  );
}

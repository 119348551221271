import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContentText } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useState } from 'react';
import { Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useApi } from '../../Api';
import { useSetores } from '../../Sistema/Setores';

export const Ctx = createContext();

/**
 * Hooks manager
 * @param {any} children
 * @return {children} Hooks.
 */
export function ExcluirSetorProvider({ children }) {
  const [setorExcluir, setSetorExcluir] = useState();
  const [enviando, setEnviando] = useState(false);
  const [setores, setSetores] = useSetores();
  const ApiController = useApi();
  const api = new ApiController();

  /**
   */
  function handleFechar() {
    setSetorExcluir();
  }

  /**
   * @param {object} event eventos
   */
  function handleEnviar(event) {
    event.preventDefault();
    setEnviando(true);
    const data = { id: setorExcluir.id };

    api
      .post('admin/setores/excluir', data)
      .then((r) => {
        const setorEncontrado = setores.findIndex(
          (item) => Number(item.id) === Number(setorExcluir.id)
        );

        const prevSetores = structuredClone(setores);

        prevSetores.splice(setorEncontrado, 1);

        setSetores(prevSetores);

        toast.success('Setor excluido com sucesso!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: 'colored',
          transition: Bounce,
        });

        setEnviando(false);
        handleFechar();
      })
      .catch((e) => {
        console.error(e);
        setEnviando(false);
      });
  }

  return (
    <Ctx.Provider value={[setorExcluir, setSetorExcluir]}>
      {setorExcluir && (
        <Dialog
          open={setorExcluir !== undefined}
          onClose={handleFechar}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
          fullWidth={false}
        >
          <DialogTitle id='alert-dialog-title'>Excluir setor</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Você tem certeza que deseja excluir o setor {setorExcluir.nome}?
              Esta ação é irreversível.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              type='button'
              variant='outlined'
              disabled={enviando}
              onClick={handleFechar}
            >
              {'Cancelar'}
            </Button>
            <LoadingButton
              type='submit'
              variant='contained'
              loading={enviando}
              onClick={handleEnviar}
            >
              {'Excluir'}
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}
      {children}
    </Ctx.Provider>
  );
}

ExcluirSetorProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useExcluirSetor = () => {
  return useContext(Ctx);
};

import PropTypes from 'prop-types';
import React, { createContext } from 'react';

export const Ctx = createContext();

/**
 * Provider para a Autenticação
 * @param {any} children componente children
 * @return {children} Autenticação.
 */
export function SocketManagerProvider({ children }) {
  return <Ctx.Provider value={null}>{children}</Ctx.Provider>;
}

SocketManagerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

import { LoadingButton } from '@mui/lab';
import {
  Backdrop,
  Box,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useState } from 'react';
import { Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  filtrarPorEquipamento,
  filtrarPorNomeFantasia,
} from '../../../../Uteis/Funcoes';
import { useApi } from '../../../Api';
import { useAuth } from '../../../Auth';
import { useChamados } from '../../../Sistema/Chamados';
import { useClientes } from '../../../Sistema/Clientes';
import { useEquipamentos } from '../../../Sistema/Equipamentos';
import { useUsuarios } from '../../../Usuarios';

export const Ctx = createContext();

const url = 'chamados/novo';
const titulo = 'Novo chamado de VENDA';
const botaoCadastrar = 'Criar chamado';
const botaoCancelar = 'Cancelar';

/**
 * @param {object} setor
 * @return {node}
 */
function SelectTipo({ setor }) {
  const chamados = JSON.parse(setor.chamados);

  if (chamados.length > 1) {
    return (
      <FormControl fullWidth>
        <InputLabel id='demo-simple-select-label'>Tipo de chamado *</InputLabel>
        <Select label='Tipo de chamado' name='tipo' required>
          <MenuItem value={0}>Venda</MenuItem>
          <MenuItem value={1}>Manutenção</MenuItem>
        </Select>
      </FormControl>
    );
  } else if (chamados[0] === 'Venda') {
    return (
      <FormControl fullWidth>
        <InputLabel id='demo-simple-select-label'>Tipo de chamado *</InputLabel>
        <Select
          label='Tipo de chamado'
          name='tipo'
          defaultValue={0}
          required
          disabled
        >
          <MenuItem value={0}>Venda</MenuItem>
        </Select>
        <input hidden name='tipo' defaultValue={'0'} />
      </FormControl>
    );
  } else {
    return (
      <FormControl fullWidth>
        <InputLabel id='demo-simple-select-label'>Tipo de chamado *</InputLabel>
        <Select
          label='Tipo de chamado'
          name='tipo'
          defaultValue={1}
          required
          disabled
        >
          <MenuItem value={1}>Manutenção</MenuItem>
        </Select>
        <input hidden name='tipo' defaultValue={'1'} />
      </FormControl>
    );
  }
}

SelectTipo.propTypes = {
  setor: PropTypes.object.isRequired,
};

/**
 * Hooks manager
 * @param {any} children
 * @return {children} Hooks.
 */
export function NovoChamadoProvider({ children }) {
  const [setorAlvo, setSetorAlvo] = useState();
  const [enviando, setEnviando] = useState(false);
  const [clientes] = useClientes();
  const [equipamentos] = useEquipamentos();
  const [, setChamados] = useChamados();
  const [usuario] = useAuth();
  const [usuarios] = useUsuarios();
  const ApiController = useApi();
  const api = new ApiController();

  /**
   */
  function handleFechar() {
    setSetorAlvo();
  }

  /**
   * @param {object} event eventos
   */
  function handleEnviar(event) {
    event.preventDefault();
    setEnviando(true);
    const data = new FormData(event.currentTarget);

    api
      .post(url, data)
      .then((r) => {
        setChamados((prev) => [...prev, r]);

        toast.success('Chamado criado com sucesso', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: 'colored',
          transition: Bounce,
        });

        setEnviando(false);
        handleFechar();
      })
      .catch((e) => {
        console.error(e);
        setEnviando(false);
      });
  }

  return (
    <Ctx.Provider value={[setorAlvo, setSetorAlvo]}>
      {setorAlvo &&
        (clientes && equipamentos ? (
          <Dialog
            open={setorAlvo !== undefined}
            onClose={handleFechar}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
          >
            <DialogTitle id='alert-dialog-title'>{titulo}</DialogTitle>
            <DialogContent>
              <Box
                component='form'
                autoComplete='off'
                noValidate
                sx={{ mt: 3 }}
                onSubmit={handleEnviar}
              >
                <Grid container spacing={2}>
                  {JSON.parse(usuario.setor)[0] === 1 && (
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth sx={{ width: '268px' }}>
                        <InputLabel id='demo-simple-select-label'>
                          Usuário *
                        </InputLabel>
                        <Select label='Usuário *' name='usuario' required>
                          {usuarios
                            .filter(
                              (item) =>
                                Number(item.setor) === Number(setorAlvo.id)
                            )
                            .map(({ id, nome }, index) => (
                              <MenuItem
                                key={`item-map-nome-${index}`}
                                value={id}
                              >
                                {nome}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}

                  <Grid item xs={12} sm={6}>
                    <SelectTipo setor={setorAlvo} />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth sx={{ width: '268px' }}>
                      <InputLabel id='demo-simple-select-label'>
                        Cliente *
                      </InputLabel>
                      <Select label='Cliente' name='cliente' required>
                        {structuredClone(clientes)
                          .sort(filtrarPorNomeFantasia)
                          .map(({ id, nomeFantasia }, index) => (
                            <MenuItem key={`item-map-nome-${index}`} value={id}>
                              {nomeFantasia}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth sx={{ width: '268px' }}>
                      <InputLabel id='demo-simple-select-label'>
                        Equipamento *
                      </InputLabel>
                      <Select label='Equipamento' name='equipamento' required>
                        {structuredClone(equipamentos)
                          .sort(filtrarPorEquipamento)
                          .map(({ id, titulo }, index) => (
                            <MenuItem key={`item-map-nome-${index}`} value={id}>
                              {titulo}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      multiline
                      rows={4}
                      name='observacao'
                      label='Observação'
                      id='observacao'
                    />
                  </Grid>
                </Grid>
                <LoadingButton
                  type='submit'
                  fullWidth
                  variant='contained'
                  sx={{ mt: 3 }}
                  loading={enviando}
                >
                  {botaoCadastrar}
                </LoadingButton>
                <Button
                  type='button'
                  fullWidth
                  variant='outlined'
                  sx={{ mt: 2, mb: 2 }}
                  onClick={handleFechar}
                  disabled={enviando}
                >
                  {botaoCancelar}
                </Button>
              </Box>
            </DialogContent>
          </Dialog>
        ) : (
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={clientes === undefined && setSetorAlvo}
            onClick={handleFechar}
          >
            <CircularProgress color='inherit' />
          </Backdrop>
        ))}
      {children}
    </Ctx.Provider>
  );
}

NovoChamadoProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useNovoChamado = () => {
  return useContext(Ctx);
};
